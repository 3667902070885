import React from "react";
import PropTypes from "prop-types";
import checkdigit from 'checkdigit';
import { Map as iMap } from "immutable";

import { I18n } from "@aws-amplify/core";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";

import NumberFormatField from "../Utils/NumberFormatField";
import AutocompleteInput from "../Utils/AutocompleteInput";

const SelectAsync = ({
  value,
  onChange,
  name,
  label,
  options,
  isFetching,
  i18n
}) => (
    <FormControl required margin="normal" fullWidth autoFocus>
      <InputLabel htmlFor={`select-${name}`}>{I18n.get(label)}</InputLabel>
      <Select
        native
        value={value}
        onChange={onChange}
        disabled={isFetching}
        inputProps={{
          name,
          id: `select-${name}`
        }}
      >
        <option value="" />
        {options.valueSeq().map(n => (
          <option key={n.get("id")} value={n.get("id")}>
            {i18n ? I18n.get(n.get("name")) : n.get("name")}
          </option>
        ))}
      </Select>
    </FormControl>
  );

SelectAsync.defaultProps = {
  i18n: false
};

SelectAsync.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(iMap).isRequired,
  isFetching: PropTypes.bool.isRequired,
  i18n: PropTypes.bool
};

class PickOrganizer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newName: "",
      newBank: "",
      sf: props.currentSfId,
      org: "",
      branch: "",
      error: null
    };
  }

  componentDidMount() {
    const { fetchOrganizations, currentSfId } = this.props;
    if (currentSfId !== "") {
      fetchOrganizations(currentSfId);
    }

  }

  selectLearningAssociation = e => {
    const { fetchOrganizations } = this.props;
    const sf = e.target.value;
    this.setState({ sf, org: "", branch: "" });
    fetchOrganizations(sf);
  };

  selectOrganization = e => {
    const { fetchOrganizers } = this.props;
    const org = e.target.value;
    this.setState({ org, branch: "" });
    fetchOrganizers(org);
  };

  handleForm = (name, value) => {
    this.setState({ [name]: value });
  };


  onSubmit = closer => {
    const { createNewOrganizer } = this.props;
    const { newBank, newName, org } = this.state;
    const { setError } = this;

    const values = {
      organizationId: org,
      name: newName,
      bankAccount: newBank
    }

    if (newBank && !checkdigit.mod11.isValid(newBank.replace(/\D/g, "").padEnd(11))) {
      setError("is not valid")
      return;
    }

    createNewOrganizer(values, closer, setError)

  }

  setError = (error) => {
    this.setState({ error })
  }



  render() {
    const { newName, newBank, sf, org, branch, error } = this.state;
    const {
      closer,
      learningAssociations,
      organizations,
      organizers,
      open
    } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        onClose={closer}
        aria-labelledby="org-picker-dialog-title"
      >
        <form
          onSubmit={e => {
            e.preventDefault()
            branch === 'NEW' ? this.onSubmit(closer) : closer(branch)
          }}
        >
          <DialogTitle id="org-picker-dialog-title">
            {I18n.get("Pick organizer")}
          </DialogTitle>
          <DialogContent>
            <SelectAsync
              label="Learning association"
              value={sf}
              onChange={this.selectLearningAssociation}
              name="sf"
              isFetching={false}
              options={learningAssociations}
              i18n
            />
            <Collapse in={Boolean(sf)} unmountOnExit>
              <SelectAsync
                label="Organization"
                value={org}
                onChange={this.selectOrganization}
                name="org"
                isFetching={false}
                options={organizations.filter(o => o.get("sfId") === sf)}
              />
              <Collapse in={Boolean(org)} unmountOnExit>
                <AutocompleteInput
                  label={I18n.get("Organizer")}
                  margin="normal"
                  required
                  autoFocus
                  items={organizers
                    .filter(o => o.get("organization") === org)
                    .valueSeq()}
                  creator={input => ({
                    id: "NEW",
                    display: String(I18n.get('Create "{0}"')).replace(
                      "{0}",
                      input
                    ),
                    name: input
                  })}
                  onChange={e => this.handleForm("branch", e.target.value)}
                  onSelectNew={val => this.handleForm("newName", val)}
                  value={branch}
                  itemToString={(item, display = false) =>
                    item ? (display && item.display) || item.name : ""
                  }
                  itemToValue={item => (item ? item.id : null)}
                />
                <Collapse in={branch === "NEW"} unmountOnExit>
                  <TextField
                    label={I18n.get("Bank account")}
                    margin="normal"
                    required
                    name="bankAccount"
                    autoFocus
                    error={error ? true : false}
                    id="bankAccount"
                    value={newBank}
                    onChange={e => this.handleForm("newBank", e.target.value)}
                    InputProps={{ inputComponent: NumberFormatField }}
                  />
                </Collapse>
              </Collapse>
            </Collapse>
            {error ? <Typography style={{ color: 'red' }}>{I18n.get(error)}</Typography> : null}
          </DialogContent>
          <DialogActions>
            <Button onClick={closer} color="primary">
              {I18n.get("Cancel")}
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={!(branch === "NEW" ? newName && newBank : branch)}
            >
              {I18n.get("Select")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

PickOrganizer.defaultProps = {
  open: false,
  currentSfId: ""
};

PickOrganizer.propTypes = {
  open: PropTypes.bool,
  closer: PropTypes.func.isRequired,
  currentSfId: PropTypes.string,
  organizations: PropTypes.instanceOf(iMap).isRequired,
  organizers: PropTypes.instanceOf(iMap).isRequired,
  learningAssociations: PropTypes.instanceOf(iMap).isRequired,
  fetchOrganizations: PropTypes.func.isRequired,
  fetchOrganizers: PropTypes.func.isRequired,
  createNewOrganizer: PropTypes.func.isRequired
};

export default PickOrganizer;
