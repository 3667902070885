import React, { Component } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import { connect } from "react-redux";

import { I18n } from "@aws-amplify/core";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";

import { signOut } from "../../operations/app";
import { fetchOrganizers } from "../../actions/organizers";
import AwareListItem from "../Utils/AwareListItem";
import OrganizersList from "./OrganizersList";
import { currentOrganizer, OrganizerPropType } from "../../reducers/organizers";

const mapStateToProps = state => ({
  user: state.getIn(["app", "user"]),
  currentOrg: currentOrganizer(state)
});

const mapDispatchToProps = dispatch => ({
  handleSignOut: () => dispatch(signOut),
  fetchOwnOrganizers: () => dispatch(fetchOrganizers("OWN"))
});

class Current extends Component {
  state = { open: false };

  componentDidMount() {
    const { fetchOwnOrganizers } = this.props;
    fetchOwnOrganizers();
  }

  toggleMenu = () => {
    this.setState(state => ({ open: !state.open }));
  };

  render() {
    const { handleSignOut, user, currentOrg, children } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        <List>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              secondaryTypographyProps={{ noWrap: true }}
              primary={currentOrg.name}
              secondary={user.getIn(
                ["attributes", "name"],
                user.getIn(["attributes", "email"])
              )}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={this.toggleMenu}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <OrganizersList onSetOrg={() => this.toggleMenu()} />
        </Collapse>
        <Divider />
        <Fade in={!open} unmountOnExit>
          <div>
            {children}
            <Divider />
            <List>
              <ListItem button onClick={handleSignOut}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={I18n.get("Sign out")} />
              </ListItem>
              <AwareListItem
                to="/profil"
                Icon={SettingsIcon}
                primary="My profile"
              />
              <ListItem>
                <Typography variant="caption" color="textSecondary">
                  {I18n.get("Signed in as {0}").replace(
                    "{0}",
                    user.getIn(["attributes", "email"])
                  )}
                </Typography>
              </ListItem>
            </List>
          </div>
        </Fade>
      </React.Fragment>
    );
  }
}

Current.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
  fetchOwnOrganizers: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(Immutable.Map).isRequired,
  currentOrg: OrganizerPropType.isRequired,
  children: PropTypes.node.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Current);
