import React from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturnedOutlined";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import AlarmIcon from "@material-ui/icons/AlarmOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutlined";

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    paddingBottom: 0
  },
  pendingPaper: {
    color: theme.palette.pending.dark,
    backgroundColor: theme.palette.pending.light
  },
  errorPaper: {
    color: theme.palette.pending.dark,
    backgroundColor: theme.palette.error.light
  },
  stalePaper: {
    color: theme.palette.pending.dark,
    backgroundColor: theme.palette.stale.light
  },
  successPaper: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light
  }
});

const statusClass = {
  CALLED_OFF: "stale",
  RETURNED: "pending",
  REGISTERED: "success",
  REFUSED: "error",
  WAITING_LIST: "stale",
  GRANTED: "success",
  REPORT_RETURNED: "pending",
  REPORTED: "pending",
  AWARDED: "success",
  COMPLETED: "success"
};

const icon = {
  CALLED_OFF: CancelIcon,
  RETURNED: AssignmentReturnedIcon,
  REGISTERED: AssignmentTurnedInIcon,
  REFUSED: CancelIcon,
  WAITING_LIST: AlarmIcon,
  GRANTED: CheckCircleIcon,
  REPORT_RETURNED: AssignmentReturnedIcon,
  REPORTED: AssignmentTurnedInIcon,
  AWARDED: CheckCircleIcon,
  COMPLETED: CheckCircleIcon
};

const StatusIcon = ({ status, ...other }) => {
  const MyStatusIcon = icon[status];
  return MyStatusIcon ? <MyStatusIcon {...other} /> : null;
};

const ShowStatus = ({ status, number, classes }) => (
  <div className={classes.root}>
    <Paper elevation={0} className={classes[`${statusClass[status]}Paper`]}>
      <List>
        <ListItem>
          <ListItemIcon>
            <StatusIcon status={status} fontSize="large" />
          </ListItemIcon>
          <ListItemText
            primary={I18n.get(`${status}.full`)}
            primaryTypographyProps={{
              variant: "h6"
            }}
            secondary={
              number
                ? `${I18n.get("Course number")} ${number
                    .match(/.{1,2}/g)
                    .join(" ")}`
                : null
            }
          />
        </ListItem>
      </List>
    </Paper>
  </div>
);

ShowStatus.defaultProps = {
  number: undefined
};

ShowStatus.propTypes = {
  status: PropTypes.string.isRequired,
  number: PropTypes.string,
  classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(ShowStatus);
