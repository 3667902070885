import React from "react";
import { List } from "immutable";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

const changeSet = raw => {
  if (List.isList(raw)) {
    return (
      <span>
        <del>{String(raw.first())}</del> <ins>{String(raw.last())}</ins>
      </span>
    );
  }
  return String(raw);
};

const unpackChanges = auditedChanges => (
  <span className="changeSet">
    {auditedChanges.keySeq().map(field => (
      <React.Fragment key={field}>
        <code> {field}</code>: {changeSet(auditedChanges.get(field))}
      </React.Fragment>
    ))}
  </span>
);

const Audit = audit => (
  <ListItem key={audit.get("version")} alignItems="flex-start">
    <ListItemAvatar>
      <Avatar>{audit.get("version")}</Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={`${audit.get("user")} ${audit.get("action")} ${audit.get(
        "createdAt"
      )}`}
      secondary={
        audit.get("comment") || unpackChanges(audit.get("auditedChanges"))
      }
    />
  </ListItem>
);

export default Audit;
