import React from "react";
import Downshift from "downshift";
import matchSorter from "match-sorter";

import { I18n } from "@aws-amplify/core";
import MuiTextField from "@material-ui/core/TextField";
import { asField } from "informed";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const AutocompleteField = asField(
  ({
    fieldState,
    fieldApi,
    field,
    items,
    creator,
    onChange,
    onBlur,
    initialValue,
    forwardedRef,
    helperText,
    onSelectNew,
    validate,
    icon,
    recentlyUsed,
    ...props
  }) => {
    const { value, error } = fieldState;
    const { setValue, setTouched } = fieldApi;

    return (
      <Downshift
        onChange={e => {
          setValue(itemToValue(field, e));
          onChange && onChange(e);
        }}
        onInputValueChange={(inputValue, ds) => {
          const exactMatch = matchSorter(items, inputValue, {
            keys: [itemToString],
            threshold: matchSorter.rankings.EQUAL
          })[0];

          if (exactMatch && ds.selectedItem !== exactMatch) {
            ds.selectItem(exactMatch);
          }
          onSelectNew && onSelectNew(inputValue);
        }}
        itemToString={itemToString}
        ref={forwardedRef}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue,
          highlightedIndex
        }) => (
          <div>
            <MuiTextField
              {...props}
              {...getInputProps()}
              fullWidth
              variant="outlined"
              error={!!error}
              helperText={error || helperText}
              onBlur={e => {
                setTouched();
                onBlur && onBlur(e);
              }}
            />
            {isOpen && (
              <Paper square>
                <List>
                  {appendIfCreate(
                    matchSorter(items, inputValue, {
                      keys: [itemToString]
                    }).slice(0, 6),
                    inputValue,
                    creator
                  ).map((item, index) => {
                    const itemValue = itemToValue(field, item);
                    return (
                      <ListItem
                        button
                        {...getItemProps({ key: itemValue, index, item })}
                        style={{
                          backgroundColor:
                            highlightedIndex === index
                              ? "rgba(0,0,0,0.12)"
                              : null
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="subtitle1"
                              component="span"
                              style={{
                                fontWeight: value === itemValue ? "bold" : null
                              }}
                            >
                              {itemToString(item)}
                            </Typography>
                          }
                          secondary={
                            <Typography color="textSecondary">
                              {itemToSecondary(field, item)}
                            </Typography>
                          }
                          disableTypography
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Paper>
            )}
            {!isOpen && recentlyUsed.size !== 0 && !value && (
              <div className="RECENTLYUSED_WRAPPER">
                <List>
                  {recentlyUsed
                    .filter(item => !inputValue || item.includes(inputValue))
                    .map((item, index) => {
                      const itemValue = itemToValue(field, item);
                      return (
                        <div
                          key={itemValue}
                          style={{ paddingTop: 10, paddingLeft: 10 }}
                        >
                          <Chip
                            {...getItemProps({ key: item, index, item })}
                            avatar={<Avatar>{icon}</Avatar>}
                            label={itemToString(item)}
                            variant="outlined"
                            clickable
                          />
                        </div>
                      );
                    })
                    .valueSeq()}
                </List>
              </div>
            )}
          </div>
        )}
      </Downshift>
    );
  }
);

const itemToString = item => (item ? item.get("name") : "");
const itemToValue = (field, item) =>
  item ? item.get(field === "municipalityId" ? "number" : "id") : null;
const itemToSecondary = (field, item) => {
  if (!item) return "";
  if (field === "curriculumId") {
    const hours = [
      ...new Set(String(item.get("hoursMin")), String(item.get("hoursMax")))
    ];
    return `${hours.join(" - ")} ${I18n.get("hours")}`;
  }
  if (field === "municipalityId") {
    return item.countyName;
  }
  return "";
};

const appendIfCreate = (items, inputValue, createNew) =>
  inputValue && inputValue.trim() && createNew && items.length < 5
    ? [...items, createNew(inputValue.trim())]
    : items;

export default AutocompleteField;
