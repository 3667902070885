import API, { graphqlOperation } from "@aws-amplify/api";
import { push } from "connected-react-router";
import { I18n } from "@aws-amplify/core";
import { fromJS } from "immutable";
import * as schema from "./schema";
import {
  GetCourse,
  UpdateCourse,
  Query,
  GrantUserAccessToCourse,
  RevokeUserAccessToCourse
} from "../api/courses";
import { modalShow, snackbarShow, snackbarError } from "./app";
import createFetchAction from "./shared";

import { selectors as courses } from "../reducers/courses";

import { CourseTypes, CoursesTypes } from "../types";

export const fetchCourse = id =>
  createFetchAction(courses, CoursesTypes, schema.courseData, id, GetCourse, {
    id
  });

export const invalidateCourse = id => dispatch =>
  dispatch({
    type: CoursesTypes.INVALIDATE,
    id
  });

export const removeCourseOwner = (courseId, itemId) => dispatch =>
  API.graphql(
    graphqlOperation(RevokeUserAccessToCourse, { courseId, ownerId: itemId })
  )
    .then(() => {
      dispatch({
        type: CourseTypes.ACCESS_REVOKED,
        itemId,
        id: courseId
      });
    })
    .catch(snackbarError(dispatch));

export const addNewCourseOwner = (courseId, email, success) => dispatch =>
  API.graphql(graphqlOperation(GrantUserAccessToCourse, { courseId, email }))
    .then(({ data }) => {
      dispatch({
        type: CourseTypes.ACCESS_GRANTED,
        item: fromJS(data.grantUserAccessToCourse),
        id: courseId
      });
      success && success();
    })
    .catch(snackbarError(dispatch));

export const updateCourse = (id, values, success, errorHandler) => dispatch => {
  dispatch({
    type: CourseTypes.UPDATE_REQUEST,
    id
  });

  const newValues = {
    ...values,
    id
  };

  return API.graphql(
    graphqlOperation(UpdateCourse(Object.keys(values)), newValues)
  )
    .then(response => {
      const { errors } = response;

      if (errors && errors.length) {
        // TODO: BETTER ERROR HANDLING
        dispatch({
          type: CourseTypes.UPDATE_FAILURE,
          id
        });
        dispatch(errorHandler("error", errors[0].message));
        return;
      }

      dispatch({
        type: CourseTypes.UPDATE_SUCCESS,
        id,
        updated: response.data.updateCourse
      });
      success && success();
    })
    .catch(({ errors }) => {
      // TODO: BETTER ERROR HANDLING
      if (errors && errors.length && errorHandler(typeof Object)) {
        dispatch({
          type: CourseTypes.UPDATE_FAILURE,
          id
        });
        dispatch(errorHandler("error", errors[0].message));
      }
    });
  // TODO: HANDLE FAILURE
};

export const updateCollection = (
  courseId,
  collection,
  success
) => dispatch => values => {
  const collectionQuery = Query[collection];
  return API.graphql(
    graphqlOperation(collectionQuery.update, { ...values, courseId })
  ).then(
    response => {
      const namespace = Object.keys(response.data)[0];
      const { errors } = response;

      if (errors && errors.length) {
        // TODO: BETTER ERROR HANDLING
        window.alert(errors);
        return;
      }

      const item = response.data[namespace];

      dispatch({
        type: CourseTypes.COLLECTION_UPDATE_SUCCESS,
        collection: collectionQuery.collection || collection,
        item: fromJS(item),
        id: courseId
      });

      success && success();
    }
    // TODO: HANDLE FAILURE
  );
};

const handleCollectionRemove = (id, collection, itemId) => dispatch => {
  const collectionQuery = Query[collection];
  return API.graphql(
    graphqlOperation(collectionQuery.delete, {
      id: itemId,
      courseId: id
    })
  ).then(
    response => {
      const { errors } = response;

      if (errors && errors.length) {
        // TODO: BETTER ERROR HANDLING
        window.alert(errors);
        return;
      }
      dispatch({
        type: CourseTypes.COLLECTION_REMOVE_SUCCESS,
        collection: collectionQuery.collection || collection,
        itemId,
        id
      });
    }
    // TODO: HANDLE FAILURE
  );
};

export const confirmCollectionRemove = (
  id,
  collection,
  itemId,
  name
) => dispatch => {
  dispatch(
    modalShow("delete", name, () =>
      dispatch(handleCollectionRemove(id, collection, itemId))
    )
  );
};

export const confirmCancellation = (id, name) => dispatch => {
  dispatch(
    modalShow("callOff", name, () =>
      dispatch(
        updateCourse(
          id, // courseId
          { action: "cancel" }, // values
          () =>
            dispatch(
              snackbarShow(push("/"))(
                "success",
                I18n.get("The course is cancelled")
              )
            ), // Sucesshandler
          snackbarShow(null) // errorHandler
        )
      )
    )
  );
};

export const updateCourseName = (id, values, payload) => dispatch =>
  dispatch(
    updateCourse(
      id, // courseId,
      values, // updateValues
      () =>
        dispatch(
          snackbarShow(payload)(
            "success",
            I18n.get("Name changed successfully")
          )
        ), // successhandler
      snackbarShow(payload) // errorHandler
    )
  );

export const sendReport = (id, values, payload) => dispatch =>
  dispatch(
    updateCourse(
      id,
      values,
      () =>
        dispatch(
          snackbarShow(payload)("success", I18n.get("The report is sent"))
        ),
      snackbarShow()
    )
  );
