import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const guessFormat = {
  bankAccount: "#### ## #####",
  birthYear: "####",
  zip: "####",
  phone_number: "+47 ########"
};

const NumberFormatField = ({ format = "", inputRef, id, ...props }) => (
  <NumberFormat
    format={format || guessFormat[id]}
    getInputRef={inputRef}
    id={id}
    {...props}
  />
);

NumberFormatField.defaultProps = {
  format: undefined
};

NumberFormatField.propTypes = {
  format: PropTypes.string,
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default NumberFormatField;
