import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from '@aws-amplify/core';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';


export const VerticalStepperButtons = ({
    activeStep,
    handleBack,
    handleNext,
    sections,
    handleClose,
    canFinish,
    formApi
}) => (
        <Grid container justify="space-between">
            <Grid item>
                <Button
                    disabled={activeStep === 0}
                    color="primary"
                    onClick={handleBack}
                >
                    {I18n.get('Tilbake')}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    color="primary"
                    onClick={handleClose}
                >
                    {I18n.get('Cancel')}
                </Button>
                {activeStep === sections.length - 1 ?
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={canFinish}
                        style={{ marginLeft: 16 }}
                    >
                        {I18n.get('Finish')}
                    </Button> :
                    <Button
                        type="submit"
                        color="primary"
                        style={{ marginLeft: 16 }}
                        onClick={() => handleNext(formApi)}
                    >
                        {I18n.get('Next')}
                    </Button>}
            </Grid>
        </Grid>
    )


VerticalStepperButtons.propTypes = {
    activeStep: PropTypes.number.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleNext: PropTypes.func.isRequired,
    sections: PropTypes.array.isRequired,
    canFinish: PropTypes.bool.isRequired,
    formState: PropTypes.shape({})
}