import React from "react";
import PropTypes from "prop-types";
import { Map as iMap } from "immutable";
import { Link } from "react-router-dom";
import moment from "moment";

import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";

import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";

import ListViewItem from "./ListViewItem";
import Subheader from "../Utils/Subheader";
import AppBar from "../AppBar";

const styles = theme => ({
  whiteList: {
    backgroundColor: theme.palette.background.paper,
    padding: 0
  },
  flexNormal: {
    alignSelf: "normal"
  }
});

const returnedStatuses = ["RETURNED", "REPORT_RETURNED"];
const activeStatuses = ["REGISTERED", "GRANTED"];
const pendingStatuses = ["WAITING_LIST", "REPORTED", "AWARDED"];
const calledOffStatuses = ["CALLED_OFF", "REFUSED"];

const coursesFilter = filter => {
  switch (filter) {
    case "ALL_RETURNED":
      return item => returnedStatuses.indexOf(item.get("status")) !== -1;
    case "ALL_CURRENT":
      return item =>
        activeStatuses.indexOf(item.get("status")) !== -1 &&
        moment(item.get("startedOn")).isSameOrBefore();
    case "ALL_PLANNED":
      return item =>
        activeStatuses.indexOf(item.get("status")) !== -1 &&
        !moment(item.get("startedOn")).isSameOrBefore();
    case "ALL_PENDING":
      return item => pendingStatuses.indexOf(item.get("status")) !== -1;
    case "ALL_COMPLETED":
      return item => item.get("status") === "COMPLETED";
    case "ALL_CALLED_OFF":
      return item => calledOffStatuses.indexOf(item.get("status")) !== -1;
    default:
      return false;
  }
};

const FilteredCourses = ({ filter, courses }) => {
  const filtered = courses.filter(coursesFilter(filter));
  return (
    !filtered.isEmpty() && (
      <React.Fragment>
        <Subheader style={{ background: "#f3f3f3" }} title={filter} />
        {filtered
          .sortBy(course => course.startedOn)
          .valueSeq()
          .map(n => (
            <ListViewItem course={n} key={n.get("id")} />
          ))}
      </React.Fragment>
    )
  );
};

const ListView = ({
  courses,
  isArchive,
  isFetching,
  invalidateCourses,
  classes
}) => (
  <React.Fragment>
    <AppBar
      title={I18n.get(isArchive ? "Archive" : "My courses")}
      actions={[
        <IconButton
          key="add"
          aria-label={I18n.get("New course")}
          component={Link}
          to="/soknad"
          color="inherit"
        >
          <AddIcon />
        </IconButton>,
        <IconButton
          key="refresh"
          aria-label={I18n.get("Refresh")}
          onClick={invalidateCourses}
          color="inherit"
        >
          <RefreshIcon />
        </IconButton>
      ]}
    />
    <List className={classes.whiteList}>
      {courses.isEmpty() ? (
        <div style={{ paddingTop: 92, minHeight: 200, textAlign: "center" }}>
          {!isFetching && I18n.get("You have no courses")}
        </div>
      ) : (
        (isArchive
          ? ["ALL_COMPLETED", "ALL_CALLED_OFF"]
          : ["ALL_RETURNED", "ALL_CURRENT", "ALL_PLANNED", "ALL_PENDING"]
        ).map(filter => (
          <FilteredCourses key={filter} filter={filter} courses={courses} />
        ))
      )}
    </List>
  </React.Fragment>
);

ListView.defaultProps = {
  isArchive: false,
  isFetching: false
};

ListView.propTypes = {
  courses: PropTypes.instanceOf(iMap).isRequired,
  isArchive: PropTypes.bool,
  isFetching: PropTypes.bool,
  invalidateCourses: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(ListView);
