import Auth from "@aws-amplify/auth";

export default {
  API: {
    graphql_endpoint:
      process.env.REACT_APP_GRAPHQL_ENDPOINT ||
      "https://minikas-stage.herokuapp.com/graphql",
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: `Bearer token=${session.getAccessToken().getJwtToken()}`
      };
    }
  },
  Auth: {
    region: "eu-west-1",
    userPoolId:
      process.env.REACT_APP_COGNITO_USER_POOL_ID || "eu-west-1_wc2N3wikB",
    userPoolWebClientId:
      process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID ||
      "71i5rv303t5h5j5169ccs5etk4"
  }
};
