import React from "react";
import PropTypes from "prop-types";
import { Map as iMap, Set } from "immutable";
import moment from "moment";
import { asField, useFieldState } from "informed";

import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

const styles = {
  contentRow: { width: "100%", paddingLeft: 0 }
};

const toggleArray = (set, value) =>
  set.has(value) ? set.remove(value) : set.add(value);

const SelectAllHeader = ({
  onSelectAll,
  numSelected,
  rowCount,
  contentRowClass
}) => (
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={numSelected > 0 && numSelected === rowCount}
          onChange={onSelectAll}
        />
      </TableCell>
      <TableCell className={contentRowClass}>
        {I18n.get("Participants")} ({numSelected}/{rowCount})
      </TableCell>
    </TableRow>
  </TableHead>
);

SelectAllHeader.propTypes = {
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  contentRowClass: PropTypes.string.isRequired
};

const AttendFieldMounter = ({ startedAt, participants, ...props }) => {
  const { value: formStartedAt } = useFieldState("startedAt");
  const isFuture = moment
    .parseZone(startedAt || formStartedAt)
    .isAfter(moment(), "day");

  if (!(startedAt || formStartedAt) || isFuture || participants.isEmpty()) {
    return null;
  }

  return (
    <AttendField field="attendants" participants={participants} {...props} />
  );
};

AttendFieldMounter.defaultProps = { startedAt: undefined };
AttendFieldMounter.propTypes = {
  startedAt: PropTypes.string,
  participants: PropTypes.instanceOf(iMap).isRequired
};

const AttendField = asField(
  ({ fieldState, fieldApi, participants, classes }) => {
    const { value } = fieldState;
    const { setValue } = fieldApi;

    const attendants = Set.isSet(value) ? value : Set(value);

    const toggleAll = () =>
      setValue(
        attendants.size !== participants.size
          ? Set.fromKeys(participants)
          : Set()
      );
    const toggle = id => setValue(toggleArray(attendants, id));

    return (
      <Table>
        <SelectAllHeader
          numSelected={attendants.size}
          onSelectAll={toggleAll}
          rowCount={participants.size}
          contentRowClass={classes.contentRow}
        />
        <TableBody>
          {participants
            .sortBy(participant => participant.name)
            .valueSeq()
            .map(p => {
              const {id} = p;
              const isSelected = attendants.has(id);

              return (
                <TableRow
                  hover
                  onClick={() => toggle(id)}
                  role="checkbox"
                  aria-checked={isSelected}
                  key={id}
                  tabIndex={-1}
                  selected={isSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isSelected} />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.contentRow}
                  >
                    <Typography variant="subtitle1">{p.name}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  }
);

AttendField.propTypes = {
  participants: PropTypes.instanceOf(iMap).isRequired,
  classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(AttendFieldMounter);
