import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { I18n } from "@aws-amplify/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = theme => ({
  success: {
    backgroundColor: theme.palette.success.main
  },
  error: {
    backgroundColor: theme.palette.error.main
  },
  info: {
    backgroundColor: theme.palette.stale.main
  },
  warning: {
    backgroundColor: theme.palette.pending.main
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 11
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

const MySnackbarContent = ({
  className,
  classes,
  message,
  onClose,
  variant,
  ...other
}) => {
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {I18n.get(message)}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

MySnackbarContent.defaultProps = {
  message: "",
  className: "snackbar"
};

MySnackbarContent.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

const MySnackbar = ({
  open,
  autoHideDuration,
  anchorOrigin,
  variant,
  message,
  handleClose,
  commit,
  payload,
  ...other
}) => {
  useEffect(() => {
    if (!payload) {
      return;
    }
    commit(payload);
  }, [payload, commit]);

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <MySnackbarContentWrapper
        variant={variant}
        message={message}
        onClose={handleClose}
        {...other}
      />
    </Snackbar>
  );
};

MySnackbar.defaultProps = {
  message: "",
  payload: () => undefined,
  autoHideDuration: 6000,
  variant: "info",
  open: false,
  anchorOrigin: { horizontal: "right", vertical: "top" }
};

MySnackbar.propTypes = {
  message: PropTypes.string,
  commit: PropTypes.func.isRequired,
  payload: PropTypes.func,
  open: PropTypes.bool,
  autoHideDuration: PropTypes.number,
  anchorOrigin: PropTypes.shape({}),
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]),
  handleClose: PropTypes.func.isRequired
};

export default MySnackbar;
