import React from "react";
import PropTypes from "prop-types";
import { I18n } from "@aws-amplify/core";
import ListSubheader from "@material-ui/core/ListSubheader";

const Subheader = ({ title, children, ...props }) => (
  <ListSubheader {...props}>{children || I18n.get(title)}</ListSubheader>
);

Subheader.defaultProps = {
  children: null,
  title: null
};

Subheader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default Subheader;
