import API, { graphqlOperation } from "@aws-amplify/api";
import { normalize } from "normalizr";
import { getOwnOrganizers } from "../reducers/app";

const createFetchAction = (
  selectors,
  types,
  schema,
  id,
  query,
  variables = {}
) => (dispatch, getState) => {
  const state = getState();

  // Don't fetch if we're already fetching, or if state is valid.
  // didInvalidate blocks the opportunity to request new curriculums and municipalities
  // in a dynamic manner, the state will never stay valid on these occasions.
  // Only check for isFetching on these

  // const { FETCH_REQUEST: type } = types;
  // const invalidate = type.includes('CURRICULUMS') || type.includes('MUNICIPAILITES') ? false : !selectors.didInvalidate(state, id);

  if (selectors.isFetching(state, id) || !selectors.didInvalidate(state, id)) {
    return Promise.resolve();
  }

  const ids = id === "OWN" ? getOwnOrganizers(state).toJS() : undefined;

  if (id === undefined || (Array.isArray(ids) && !ids.length)) {
    return Promise.resolve();
  }

  dispatch({
    type: types.FETCH_REQUEST,
    id
  });

  return API.graphql(graphqlOperation(query, { ...variables, ids })).then(
    response => {
      const fetchedAt = new Date().getTime();
      dispatch({
        type: types.FETCH_SUCCESS,
        payload: normalize(response, schema).entities,
        id,
        fetchedAt
      });
    },
    error => {
      console.log(error);
      dispatch({
        type: types.FETCH_FAILURE,
        message: error.message || "Something went wrong" || error,
        id
      });
    }
  );
};

export default createFetchAction;
