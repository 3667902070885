import React from "react";
import PropTypes from "prop-types";
import { Map as iMap, Record } from "immutable";
import moment from "moment";

import { I18n } from "@aws-amplify/core";

import Dialog from "../../Dialog";
import AttendBody from "./AttendBody";
import { Session, Participant } from "../../../types";

const AttendDialog = ({ item, handleClose, handleSubmit, participants }) => {
  const submit = values =>
    handleSubmit("sessions", handleClose)({
      id: item.get("id"),
      ...values
    });

  return (
    <Dialog
      open={Boolean(item)}
      handleClose={handleClose}
      handleSubmit={submit}
      initialValues={item}
      title={`${I18n.get("Attendance")} ${
        item ? moment.parseZone(item.get("startedAt")).format("LLLL") : ""
      }`}
    >
      <AttendBody
        startedAt={item.get("startedAt")}
        participants={participants}
      />
    </Dialog>
  );
};

AttendDialog.defaultProps = {
  item: Record
};

AttendDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  participants: PropTypes.instanceOf(iMap).isRequired,
  item: PropTypes.oneOfType([
    PropTypes.instanceOf(Session),
    PropTypes.instanceOf(Participant)
  ])
};

export default AttendDialog;
