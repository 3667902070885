import { connect } from 'react-redux';

import ModalView from './ModalView';
import { modalHide } from '../../actions/app';

const mapStateToProps = state => ({
    payload: state.getIn(['app', 'modal', 'payload']),
    mood:    state.getIn(['app', 'modal', 'mood']),
    item:    state.getIn(['app', 'modal', 'item']),
});

const mapDispatchToProps = dispatch => ({
    dismiss: () => dispatch(modalHide),
    commit:  (payload) => {
        dispatch(payload);
        dispatch(modalHide);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalView);