import { connect } from "react-redux";

import { selectors as learningAssociations } from "../../reducers/learningAssociations";
import { selectors as organizations } from "../../reducers/organizations";
import { selectors as organizers } from "../../reducers/organizers";
import { currentSfId, getOwnOrganizers } from "../../reducers/app";
import { createNewOrganizer, fetchOrganizations, fetchOrganizers } from "../../actions/organizers";
import PickOrganizerView from "./PickOrganizerView";

const mapStateToProps = state => ({
  currentSfId: currentSfId(state),
  ownOrganizers: getOwnOrganizers(state),
  learningAssociations: learningAssociations.all(state),
  organizations: organizations.all(state),
  organizers: organizers.all(state)
});

const mapDispatchToProps = dispatch => ({
  createNewOrganizer: (values, closer, setError) => dispatch(createNewOrganizer(values, closer, setError)),
  fetchOrganizations: id => dispatch(fetchOrganizations(id)),
  fetchOrganizers: id => dispatch(fetchOrganizers(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickOrganizerView);
