import React from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";

export const twoCols = (col1, col2) => (
    <Grid container spacing={16}>
        <Grid item xs={6}>
            {col1}
        </Grid>
        <Grid item xs={6}>
            {col2}
        </Grid>
    </Grid>
);

twoCols.propTypes = {
    col1: PropTypes.element.isRequired,
    col2: PropTypes.element.isRequired
}




