import { fromJS, Map } from "immutable";
import { FetchOnly } from "../types";

export const createFetchState = (type, initialState = FetchOnly({})) => (
  state = initialState,
  action
) => {
  switch (action.type) {
    case type.FETCH_REQUEST:
      return fetchRequestState(state);
    case type.FETCH_SUCCESS:
      return fetchSuccessState(state);
    case type.FETCH_FAILURE:
      return fetchErrorState(state, action.message);
    case type.INVALIDATE:
      return invalidateState(state);
    default:
      return state;
  }
};

export const fetchRequestState = state =>
  state.merge({
    isFetching: true,
    errorMessage: undefined,
    didInvalidate: false
  });

export const invalidateState = state => state.set("didInvalidate", true);

export const fetchSuccessState = (state, fetchedAt = 0) =>
  state.merge({
    isFetching: false,
    fetchedAt
  });

export const mergePayloadToState = (
  state,
  Record,
  items = {},
  fetchedAt = 0,
  extra = {}
) =>
  state.merge(
    Map(fromJS(items))
      .map(item => new Record(item))
      .map(o => o.merge({ fetchedAt, ...extra }))
  );

export const fetchErrorState = (state, error) =>
  state.merge({
    isFetching: false,
    errorMessage: error
  });

export const selectors = collection => ({
  isFetching: (state, id) =>
    Boolean(state.getIn([collection, id, "isFetching"], false)),
  didInvalidate: (state, id) =>
    Boolean(state.getIn([collection, id, "didInvalidate"], true)),
  errorMessage: (state, id) =>
    state.getIn([collection, id, "errorMessage"], undefined),
  all: state => state.get(collection),
  find: (state, id) => state.getIn([collection, id])
});
