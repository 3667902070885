import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Map } from "immutable";

import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import PersonIcon from "@material-ui/icons/Person";
import SchoolIcon from "@material-ui/icons/School";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import TodayIcon from "@material-ui/icons/Today";
import CommentIcon from "@material-ui/icons/Comment";
import { Course, Municipality, Curriculum } from "../../types";

import Subheader from "../Utils/Subheader";
import Audit from "./Audit";
import CourseStatus from "./CourseStatus";
import ContactItem from "./ContactItem";
import OrganizerItem from "./OrganizerItem";
import ShowItem from "../Utils/ShowItem";
import curriculumPresenter from "../../presenters/curriculumPresenter";
import dateJoiner from "../../presenters/dateJoiner";

const Details = ({ course, children, curriculum, municipality }) => (
  <React.Fragment>
    <CourseStatus status={course.status} number={course.number} />
    <List disablePadding subheader={<Subheader title="Course information" />}>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <ShowItem
            Icon={SchoolIcon}
            label="Curriculum"
            value={curriculumPresenter(curriculum)}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ShowItem
            Icon={LocationOnIcon}
            label="Location"
            value={[municipality.name, municipality.countyName].join(", ")}
          />
        </Grid>
        {course.get("curriculumCompletion") &&
          Map(course.get("curriculumCompletion"))
            .entrySeq()
            .map(
              ([key, value]) =>
                value && (
                  <Grid key={key} item sm={6} xs={12}>
                    <ShowItem Icon={CommentIcon} label={key} value={value} />
                  </Grid>
                )
            )}
        <Grid item sm={6} xs={12}>
          <ShowItem
            Icon={TodayIcon}
            label="Duration"
            value={dateJoiner(
              moment(course.startedOn).format("LL"),
              moment(course.endedOn).format("LL")
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ShowItem
            Icon={PersonIcon}
            label="Teacher"
            value={
              (course.get("teachers").size &&
                course
                  .get("teachers")
                  .valueSeq()
                  .map(t => t.get("name"))
                  .join(", ")) ||
              "—"
            }
          />
        </Grid>
      </Grid>
    </List>
    <Divider variant="middle" />
    <Grid container>
      <Grid item sm={6} xs={12}>
        <OrganizerItem id={course.get("organizer")} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <ContactItem
          person={course.get("person")}
          phone={course.get("phone")}
          email={course.get("email")}
        />
      </Grid>
    </Grid>
    {children}
    <Divider variant="middle" />
    <List subheader={<Subheader title="Version history" />}>
      {course
        .get("audits")
        .reverse()
        .map(Audit)}
    </List>
  </React.Fragment>
);

Details.defaultProps = {
  children: null
};

Details.propTypes = {
  course: PropTypes.instanceOf(Course).isRequired,
  municipality: PropTypes.instanceOf(Municipality).isRequired,
  curriculum: PropTypes.instanceOf(Curriculum).isRequired,
  children: PropTypes.node
};

export default Details;
