import React, { Component } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";

import { I18n } from "@aws-amplify/core";

import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";

import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Mail";



import ShowItem from "../Utils/ShowItem";
import BlockUi from "../Utils/BlockUi";
import NumberFormatField from "../Utils/NumberFormatField";
import { cleanAttrValue } from "../Utils/valueHandlers";
import ConfirmEmail from "../Auth/ConfirmEmail";



class ProfileFieldsList extends Component {
  state = {
    editing: null,
    showEmailVerif: false,
    codeStatus: null,
    error: null
  };

  setEditing = name => {
    this.setState({ editing: name });
  };

  handleClose = () => {
    this.setState({ showEmailVerif: false })
  }

  handleSuccess = (bool, msg) => {
    this.setState({ showEmailVerif: bool, codeStatus: msg })
  }

  handleFailed = error => {
    this.setState({ error })
    setTimeout(() => this.setState({ error: null }), 4000);
  }

  editHandler = name => values => {
    if (values === false)
      return () => {
        this.setEditing(null);
      };

    const { updateUser, user } = this.props;
    const { editing } = this.state;
    const { handleSuccess, handleFailed } = this;

    if (editing === name) {
      const field = Object.keys(values)[0];
      if (field) {
        const value = cleanAttrValue(name, values[field], true);
        if (value !== user.getIn(['attributes', name])) {
          updateUser({
            [name]: value
          },
            handleSuccess,
            handleFailed
          )
        }
      }

      this.setEditing(null);

    } else {
      this.setEditing(name);
    }

    return true;
  };

  render() {
    const { user, emailVerified } = this.props;
    const { editing, showEmailVerif, codeStatus, error } = this.state;

    return (
      <BlockUi blocking={false}>
        <List>
          {[
            {
              Icon: PersonIcon,
              label: "Name",
              name: "name"
            },
            {
              Icon: PhoneIcon,
              label: "Phone",
              name: "phone_number",
              type: "tel",
              InputProps: { inputComponent: NumberFormatField }
            },
            {
              Icon: EmailIcon,
              label: "Email",
              name: "email",
              type: "email"
            }
          ].map(attr => (
            <ShowItem
              key={attr.name}
              id={attr.name}
              value={user.getIn(["attributes", attr.name], "")}
              onEdit={this.editHandler(attr.name)}
              editing={editing === attr.name}
              {...attr}
            />
          ))}
        </List>
        <Typography style={{ color: 'red', textAlign: 'center' }}>{error ? I18n.get(error) : ""}</Typography>
        {emailVerified ? null :
          <ConfirmEmail
            handleClose={this.handleClose}
            open={showEmailVerif}
            codeStatus={codeStatus}
          />}
      </BlockUi>

    );
  }
}

ProfileFieldsList.propTypes = {
  user: PropTypes.instanceOf(Immutable.Map).isRequired,
  refreshUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default ProfileFieldsList;
