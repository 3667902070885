import { connect } from "react-redux";

import { createNewCourse } from "../../actions/courseForm";
import { fetchCurriculums } from "../../actions/curriculums";
import { fetchMunicipalities } from "../../actions/municipalities";
import { currentOrganizerId, currentSfId } from "../../reducers/app";
import {
  selectors as curriculums,
  getCurriculum
} from "../../reducers/curriculums";
import { selectors as municipalities } from "../../reducers/municipalities";
import { getRecentlyUsedField } from "../../reducers/courses";
import withCourses from "../Fetch/withCourses";

import CreateForm from "./CreateForm";

const mapStateToProps = state => ({
  orgId: currentOrganizerId(state),
  sfId: currentSfId(state),
  loading: state.getIn(["courseForm", "loading"]),
  errors: state.getIn(["courseForm", "errors"]),
  remoteError: state.getIn(["courseForm", "remoteError"]),
  values: state.getIn(["courseForm", "values"]),
  municipalities: municipalities
    .all(state)
    .filter((_, key) => !key.includes("$")),
  curriculums: curriculums.all(state).filter((_, key) => !key.includes("$")),
  getCurriculum: id => getCurriculum(state, id),
  recentlyUsedCurriculums: getRecentlyUsedField(state, "curriculum"),
  recentlyUsedMunicipalities: getRecentlyUsedField(state, "municipality")
});

const mapDispatchToProps = dispatch => ({
  fetchCurriculums: (search, id, subString) =>
    dispatch(fetchCurriculums(search, id, subString)),
  fetchMunicipalities: (name, subString) =>
    dispatch(fetchMunicipalities(name, subString)),
  createCourse: values => dispatch(createNewCourse(values))
});

export default withCourses(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateForm)
);
