import PropTypes from "prop-types";
import { Map } from "immutable";
import {
  OrganizersTypes,
  Organizer,
  OrganizationsTypes,
  CoursesTypes
} from "../types";
import { getOwnOrganizers, currentOrganizerId } from "./app";
import * as shared from "./shared";

export const OrganizerPropType = PropTypes.instanceOf(Organizer);

const organizers = (state = Map(), action) => {
  switch (action.type) {
    case OrganizersTypes.FETCH_REQUEST:
    case OrganizersTypes.FETCH_SUCCESS:
    case OrganizersTypes.FETCH_FAILURE:
    case OrganizersTypes.ADD_ORGANIZER:
    case OrganizersTypes.INVALIDATE:
      return state.set(
        String(action.id),
        organizer(state.get(String(action.id)), action)
      );
    case CoursesTypes.FETCH_SUCCESS:
    case OrganizationsTypes.FETCH_SUCCESS:
      return shared.mergePayloadToState(
        state,
        Organizer,
        action.payload.organizers,
        action.fetchedAt
      );
    default:
      return state;
  }
};

const organizer = (state = Organizer(), action) => {
  switch (action.type) {
    case OrganizersTypes.FETCH_REQUEST:
      return shared.fetchRequestState(state);
    case OrganizersTypes.FETCH_SUCCESS:
      return shared.fetchSuccessState(state);
    case OrganizersTypes.FETCH_FAILURE:
      return shared.fetchErrorState(state, action.message);
    case OrganizersTypes.INVALIDATE:
      return shared.invalidateState(state);
    case OrganizersTypes.ADD_ORGANIZER:
      return new Organizer(action.organizer)
    default:
      return state;
  }
};

export default organizers;

export const ownedOrganizers = state =>
  Map(
    getOwnOrganizers(state).map(id => [
      id,
      Organizer(state.getIn(["organizers", id], { id }))
    ])
  );
export const currentOrganizer = state => {
  const id = currentOrganizerId(state);
  return Organizer(state.getIn(["organizers", id], { id }));
};

export const selectors = shared.selectors("organizers");
