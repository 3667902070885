export const cleanAttrValue = (attr, value, out = false) => {
  if (value) {
    if (attr === "phone_number" && out) {
      const clean = value.replace(/^\+47/, "").replace(/\D/, "");
      if (clean.length === 8) {
        return `+47${clean}`;
      }
      return null;
    }
    if (attr === "phone_number") {
      return value.replace(/^\+47/, "").replace(/\D/, "");
    }
  }
  return value;
};

export default null;
