import React from "react";
import PropTypes from "prop-types";
import { Map, OrderedMap } from "immutable";

import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "./ListItem";
import { sumHours } from "../../Report/courseReport";

const styles = theme => ({
  whiteList: {
    backgroundColor: theme.palette.background.paper,
    padding: 0
  }
});

const CollectionList = ({
  classes,
  collection,
  items,
  sessions,
  removeFromCollection,
  setCollection,
  editable
}) => {
  const hours = sumHours(sessions);
  return (
    <List
      className={classes.whiteList}
      subheader={
        <ListSubheader>
          {items.size} {I18n.get(collection)}
        </ListSubheader>
      }
    >
      {items.valueSeq().map(item => (
        <ListItem
          collection={collection}
          item={item}
          key={item.get("id")}
          sessions={sessions}
          hours={hours}
          editable={editable}
          handleEdit={setCollection}
          handleRemove={removeFromCollection}
        />
      ))}
    </List>
  );
};

CollectionList.propTypes = {
  classes: PropTypes.shape({ whiteList: PropTypes.string.isRequired })
    .isRequired,
  collection: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  sessions: PropTypes.instanceOf(Map).isRequired,
  items: PropTypes.instanceOf(OrderedMap).isRequired,
  removeFromCollection: PropTypes.func.isRequired,
  setCollection: PropTypes.func.isRequired
};

export default withStyles(styles)(CollectionList);
