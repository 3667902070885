import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";

import { I18n } from "@aws-amplify/core";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import {
  fetchUserState,
  toggleUserOrganizer,
  setUserOrganizer
} from "../../operations/app";
import { ownedOrganizers } from "../../reducers/organizers";
import { currentOrganizerId } from "../../reducers/app";
import PickOrganizer from "./PickOrganizer";
import BlockUi from "../Utils/BlockUi";
import { sfFromId } from "../../learningAssociations";

const mapStateToProps = state => ({
  userOrganizers: ownedOrganizers(state),
  currentOrgId: currentOrganizerId(state)
});

const mapDispatchToProps = dispatch => ({
  refreshUser: () => dispatch(fetchUserState),
  setOrg: id => dispatch(setUserOrganizer(id)),
  toggleOrg: (orgId, add) => dispatch(toggleUserOrganizer(orgId, add))
});

class OrganizersList extends Component {
  state = {
    picker: false
  };

  setOrg(id) {
    const { setOrg, onSetOrg } = this.props;
    setOrg(id);
    onSetOrg && onSetOrg();

  }

  handlePicker = show => (result = undefined) => {
    const { toggleOrg } = this.props;
    this.setState({ picker: show });
    if (typeof result === "string" && result !== 'NEW') {
      toggleOrg(result, true);
    }
  };

  render() {
    const { userOrganizers, editor, toggleOrg, currentOrgId } = this.props;
    const { picker } = this.state;

    return (
      <BlockUi blocking={false}>
        <List>
          {!userOrganizers.isEmpty()
            ? userOrganizers.valueSeq().map(org => {
              const isCurrent = currentOrgId === org.id;
              return (
                <ListItem
                  key={org.id}
                  button={!isCurrent}
                  onClick={() => (isCurrent ? null : this.setOrg(org.id))}
                >
                  <ListItemIcon>
                    {isCurrent ? (
                      <RadioButtonCheckedIcon />
                    ) : (
                        <RadioButtonUncheckedIcon />
                      )}
                  </ListItemIcon>

                  <ListItemText
                    primary={org.name || I18n.get("Unknown organizer")}
                    secondary={I18n.get((sfFromId(org.id) || {}).name)}
                  />
                  {editor ? (
                    <ListItemSecondaryAction>
                      <Tooltip
                        title={I18n.get("Remove")}
                        aria-label={I18n.get("Remove")}
                        placement="left"
                      >
                        <IconButton onClick={() => toggleOrg(org.id, false)}>
                          <RemoveCircleIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  ) : null}
                </ListItem>
              );
            })
            : null}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={this.handlePicker(true)}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={I18n.get("Add organizer")} />
          </ListItem>
        </List>
        <PickOrganizer open={picker} closer={this.handlePicker(false)} />
      </BlockUi>
    );
  }
}

OrganizersList.defaultProps = {
  editor: false,
  currentOrgId: "",
  onSetOrg: undefined
};

OrganizersList.propTypes = {
  userOrganizers: PropTypes.instanceOf(Map).isRequired,
  currentOrgId: PropTypes.string,
  toggleOrg: PropTypes.func.isRequired,
  setOrg: PropTypes.func.isRequired,
  editor: PropTypes.bool,
  onSetOrg: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizersList);
