import { connect } from "react-redux";

import { Course, Municipality, Curriculum } from "../../types";
import { selectors } from "../../reducers/courses";
import { getMunicipality } from "../../reducers/municipalities";
import { getCurriculum } from "../../reducers/curriculums";
import {
  fetchCourse,
  confirmCollectionRemove,
  confirmCancellation,
  updateCollection,
  sendReport,
  updateCourseName
} from "../../actions/course";
import { getParticipants } from "../../reducers/participants";
import { getSessions } from "../../reducers/sessions";

import makeFetch from "./makeFetch";

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  const course = Course(selectors.find(state, id));

  const curriculum = new Curriculum(
    getCurriculum(state, String(course.curriculum), "")
  );
  const municipality = new Municipality(
    getMunicipality(state, String(course.municipality), "")
  );

  return {
    isFetching: selectors.isFetching(state, id),
    didInvalidate: selectors.didInvalidate(state, id),
    errorMessage: selectors.errorMessage(state, id),
    course,
    curriculum,
    municipality,
    editable: course.editable,
    allowHoursWithoutTeacher: curriculum.allowedHoursWithoutTeacher,
    collection: {
      participants: getParticipants(state, course.participants),
      sessions: getSessions(state, course.sessions)
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { course } = ownProps;
  const { id } = ownProps.match.params;

  return {
    fetchData: () => dispatch(fetchCourse(id)),
    removeFromCollection: (collection, item, name) =>
      dispatch(confirmCollectionRemove(id, collection, item, name)),
    cancelCourse: () =>
      dispatch(confirmCancellation(id, course && course.get("name"))),
    sendReport: (values, success) => dispatch(sendReport(id, values, success)),
    updateCourseName: (values, success) =>
      dispatch(updateCourseName(id, values, success)),
    updateCollection: (collection, success) =>
      dispatch(updateCollection(id, collection, success))
  };
};

const withCourse = WrapComponent => {
  const Wrapped = makeFetch(WrapComponent);
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Wrapped);
};

export default withCourse;
