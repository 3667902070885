import React, {Fragment} from "react";

import { I18n } from '@aws-amplify/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from '@material-ui/core/Button';
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import {
    styles,
    isFullScreen,
    Transition,
    DialogHeader
} from '../Dialog/Dialog';
import Form from '../Form/Form';
import FormField from '../Form/FormField';

const NameChangeDialog = ({
    open,
    item,
    handleClose,
    handleSubmit,
    classes,
    width
}) => {
    const fullScreen = isFullScreen(width);

    return (
        <Dialog
            open={open}
            PaperProps={{ square: true }}
            fullScreen={fullScreen}
            TransitionComponent={Transition}
            aria-labelledby="dialog-title"
            onClose={handleClose}
        >
            {open && (
                <Form
                    initialValues={item}
                    onSubmit={values => handleSubmit(values, handleClose)}
                >
                    <Fragment>
                    <DialogHeader
                        id="dialog-title"
                        fullScreen={fullScreen}
                        classes={classes}
                        handleClose={handleClose}
                    >
                        {I18n.get('Change name')}
                    </DialogHeader>
                    <DialogContent
                        style={fullScreen ? { paddingTop: 64 } : {}}
                        className={classes.dialogContent}
                    >
                        <FormField field="name" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            {I18n.get("Cancel")}
                        </Button>
                        <Button type="submit" color="primary">
                            {I18n.get("Save")}
                        </Button>}
                    </DialogActions>
                    </Fragment>
                </Form>
            )}
        </Dialog>
    
        );
    }
    
    
export default withStyles(styles)(withWidth()(NameChangeDialog));