import React from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const passwordAdornments = (visible, setVisible) => ({
    InputProps: {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility" onClick={() => setVisible(!visible)}>
                    {visible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </InputAdornment>
        ),
    }
});

export default passwordAdornments;