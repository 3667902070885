import React from "react";
import PropTypes from "prop-types";
import { List } from 'immutable';
import { connect } from "react-redux";
import { Scope } from "informed";
import FormField from "../Form/FormField";

export const CurriculumCompletionForm = ({ incompleteFields, keepState}) => {
  if (!incompleteFields) {
    return null;
  }
  return (
    <Scope scope="curriculumCompletion">
      {incompleteFields.map(field => (
        <FormField key={field} field={field} required multiline keepState={keepState}/>
      ))}
    </Scope>
  );
};

CurriculumCompletionForm.defaultProps = {
  incompleteFields: undefined,
};

CurriculumCompletionForm.propTypes = {
  incompleteFields: PropTypes.instanceOf(List)
};

const mapStateToProps = (state, ownProps) => ({
  incompleteFields: state.getIn([
    "curriculums",
    ownProps.curriculumId,
    "incompleteFields"
  ])
});

export default connect(mapStateToProps)(CurriculumCompletionForm);
