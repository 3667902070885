import React from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";
import TimeLineIcon from "@material-ui/icons/Timeline";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import PublicIcon from "@material-ui/icons/Public";
import ListItemCheckbox from "../Utils/ListItemCheckbox";
import FormField from "../Form/FormField";
import FacilitationForm from "../Course/FacilitationForm";
import FacilitationGender from "./FacilitationGender";
import IncomeForm from "./IncomeForm";
import { CurriculumCompletionForm } from "../Course/CurriculumCompletionForm";
import ShowItem from "../Utils/ShowItem";
import { CourseReport } from "../../types";

const participantsDisplay = (males, females) =>
  `${males + females} (${males} ${I18n.get("males")}, ${females} ${I18n.get(
    "females"
  )})`;

const hoursDisplay = (hours, hoursWithoutTeacher) =>
  hoursWithoutTeacher
    ? `${hours} (${hours - hoursWithoutTeacher} ${I18n.get(
        "with a teacher"
      )}, ${hoursWithoutTeacher} ${I18n.get("without a teacher")})`
    : `${hours}`;

const First = ({ courseReport }) => (
  <React.Fragment>
    <ShowItem
      Icon={TimeLineIcon}
      label="Duration"
      value={`${courseReport.get("startDate")} - ${courseReport.get(
        "endDate"
      )}`}
    />
    <ShowItem
      Icon={AccessTimeIcon}
      label="field.hours"
      value={hoursDisplay(
        courseReport.get("hours"),
        courseReport.get("hoursWithoutTeacher")
      )}
    />
    <ShowItem
      Icon={AccessibilityNewIcon}
      label="Finished participants"
      value={participantsDisplay(
        courseReport.get("completedMales"),
        courseReport.get("completedFemales")
      )}
    />
    <ShowItem
      Icon={PublicIcon}
      label="Sessions"
      value={String(courseReport.get("sessions"))}
    />
    {courseReport.hourDeviation && (
      <FormField multiline field="hoursExplanation" keepState />
    )}
    {courseReport.participantDeviation && (
      <FormField multiline field="participantsExplanation" keepState />
    )}
  </React.Fragment>
);

First.propTypes = {
  courseReport: PropTypes.instanceOf(CourseReport).isRequired
};

const RepertoireSection = ({ courseReport }) => (
  <React.Fragment>
    <CurriculumCompletionForm
      incompleteFields={courseReport.incompleteFields}
      keepState
    />
  </React.Fragment>
);

RepertoireSection.propTypes = {
  courseReport: PropTypes.instanceOf(CourseReport).isRequired
};

const FacilitationSection = ({ courseReport, ...props }) => (
  <React.Fragment>
    <FacilitationForm
      keepState
      disableSticky
      curriculum={courseReport.curriculum}
    >
      <FormField field="facilitationExpenses" keepState />
      <FacilitationGender keepState />
    </FacilitationForm>
  </React.Fragment>
);

FacilitationSection.propTypes = {
  courseReport: PropTypes.instanceOf(CourseReport).isRequired
};

const Third = () => (
  <React.Fragment>
    <IncomeForm keepState />
    <FormField keepState field="hoursVoluntaryWork" />
  </React.Fragment>
);

const Fourth = ({ correct, diploma, toggleCheckState }) => (
  <React.Fragment>
    <FormField keepState field="comment" />
    <ListItemCheckbox
      label={`${I18n.get("field.confirmDiploma")} *`}
      helperText={I18n.get("helpertext.confirmDiploma")}
      checked={diploma}
      onChange={() => toggleCheckState("diploma")}
    />
    <ListItemCheckbox
      label={`${I18n.get("field.confirmReport")} *`}
      helperText={I18n.get("Elektronisk signatur")}
      checked={correct}
      onChange={() => toggleCheckState("correct")}
    />
  </React.Fragment>
);

Fourth.propTypes = {
  correct: PropTypes.bool.isRequired,
  diploma: PropTypes.bool.isRequired,
  toggleCheckState: PropTypes.func.isRequired
};

const sections = ({ courseReport, ...props }) => {
  const repertoireSection = {
    title: I18n.get("Repertoire"),
    component: <RepertoireSection courseReport={courseReport} />
  };

  const facilitationSection = {
    title: I18n.get("Measures and facilitation"),
    component: <FacilitationSection courseReport={courseReport} {...props} />
  };

  const defaultSections = [
    {
      title: I18n.get("Course summary"),
      component: <First courseReport={courseReport} {...props} />
    },
    {
      title: I18n.get("Economy"),
      component: <Third />
    },
    {
      title: I18n.get("Confirmation and submission"),
      component: <Fourth {...props} />
    }
  ];

  if (courseReport.curriculum.get("availableFacilitationMeasures")) {
    defaultSections.splice(1, 0, facilitationSection);
  }

  if (courseReport.incompleteFields) {
    defaultSections.splice(1, 0, repertoireSection);
  }

  return defaultSections;
};

export default sections;
