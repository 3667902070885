import Auth from "@aws-amplify/auth";

import { push } from 'connected-react-router';

import {
  handleAuthState,
  handleAuthInput,
  handleDrawer,
  signedIn,
  authFailed,
  authSubmit,
  signedOut,
  userUpdateSuccess,
  passwordResetRequest,
  passwordResetSuccess,
  passwordChangeRequest,
  passwordChangeSuccess,
  cleanAuthUI
} from "../actions/app";

import { getOwnOrganizers, getUserAttribute, currentOrganizerId, getUserLocation } from "../reducers/app";
export { handleAuthInput, handleAuthState, handleDrawer, cleanAuthUI };

export const fetchUserState = dispatch => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      if (user) {
        Auth.currentUserInfo()
          .then(userInfo => dispatch(signedIn(userInfo)))
          .catch(err => dispatch(authFailed(err)));
      } else {
        dispatch(handleAuthState("signIn"));
      }
    })
    .catch(() => dispatch(handleAuthState("signIn")));
};


export const toggleUserOrganizer = (orgId, add) => (dispatch, getState) => {
  const orgs = getOwnOrganizers(getState());
  const index = orgs.indexOf(orgId);
  const activeOrganizer = currentOrganizerId(getState());
  if ((add && index >= 0) || (!add && index < 0)) {
    return;
  }

  const newOrgs = add ? orgs.unshift(orgId) : orgs.splice(index, 1);

  if (!newOrgs.contains(activeOrganizer)) {
    dispatch(setUserOrganizer(newOrgs[0] || ''));
  }

  dispatch(updateUserAttributes({ "custom:organizers": newOrgs.join(";") }));
};

export const setUserOrganizer = orgId => dispatch => {
  dispatch(updateUserAttributes({ "custom:organizer": orgId }, null, null, true))

}


export const updateUserAttributes = (newAttributes, handleSuccess, handleFailed, redirect) => (dispatch, getState) => {
  Auth.currentAuthenticatedUser().then(user => {

    if (newAttributes.email) {
      newAttributes = {
        ...newAttributes,
        email_verified: false
      }
    }

    Auth.updateUserAttributes(user, newAttributes)
      .then(() => {
        dispatch(userUpdateSuccess(newAttributes))
        if (handleSuccess) {
          const { email } = newAttributes;
          handleSuccess(email ? true : false, email ? "Code is sendt to: " + email : null)
        }
        if (redirect) {
          const pathname = getUserLocation(getState())
          if (pathname.includes('/kurs/')) {
            dispatch(push('/'))
          }
        }
      })
      .catch(err => {
        if (handleFailed) {
          err.message ? handleFailed(err.message) : handleFailed(err);
        }
      });
  });
};

export const verifyUserAttr = (attr, setCodeStatus) => (dispatch, getState) => {
  setCodeStatus('Sending...')
  Auth.currentAuthenticatedUser().then(user => {
    Auth.verifyUserAttribute(user, attr)
      .then(() => {
        const email = getUserAttribute(getState(), "email")
        setCodeStatus('Code is sendt to: ' + email)
      })
      .catch(err => {
        err.message ? setCodeStatus(err.message) : setCodeStatus(err)
      })
  })
}

export const verifyUserAttrSubmit = (code, attr, setError) => dispatch => {
  Auth.currentAuthenticatedUser().then(user => {
    Auth.verifyUserAttributeSubmit(user, attr, code)
      .then(() => {
        dispatch(userUpdateSuccess({ email_verified: true }))
      })
      .catch(err => {
        err.message ? setError(err.message) : setError(err)
      })
  })
}

export const signUp = (
  name = "",
  email = "",
  password = "",
  phone_number = ""
) => dispatch => {
  dispatch(authSubmit);
  Auth.signUp({ username: email, password, attributes: { name, phone_number } })
    .then(() => dispatch(handleAuthState("confirmSignUp")))
    .catch(err => dispatch(authFailed(err)));
};

export const authenticate = (email = "", password = "") => dispatch => {
  dispatch(authSubmit);
  Auth.signIn(email, password)
    .then(() => {
      Auth.currentUserInfo()
        .then(user => {
          dispatch(signedIn(user))
        })
        .catch(err => dispatch(authFailed(err)));
    })
    .catch(err => {
      if (err.code === "UserNotConfirmedException") {
        dispatch(handleAuthState("confirmSignUp"));
      } else {
        dispatch(handleAuthState("signIn"));
        dispatch(authFailed(err));
      }
    });
};

export const confirmSignUp = (
  email = "",
  password = "",
  code = ""
) => dispatch => {
  dispatch(authSubmit);
  Auth.confirmSignUp(email, code)
    .then(() => dispatch(authenticate(email, password)))
    .catch(err => dispatch(authFailed(err)));
};

export const signOut = dispatch => {
  Auth.signOut().then(() => dispatch(signedOut));
};

export const resetPassword = (
  email = "",
  password = "",
  code = "",
  sent = null
) => dispatch => {
  dispatch(authSubmit);
  if (sent) {
    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        dispatch(passwordResetSuccess);
        dispatch(authenticate(email, password));
      })
      .catch(err => dispatch(authFailed(err)));
  } else {
    Auth.forgotPassword(email)
      .then(data =>
        dispatch(passwordResetRequest(data.CodeDeliveryDetails.Destination))
      )
      .catch(err => dispatch(authFailed(err)));
  }
};

export const changePassword = ({ oldPassword, newPassword }, success, errorHandler) => dispatch => {
  dispatch(passwordChangeRequest)
  Auth.currentAuthenticatedUser().then(user => {
    return Auth.changePassword(user, oldPassword, newPassword)
  })
    .then(() => {
      dispatch(passwordChangeSuccess)
      success && success();
    })
    .catch((err) => {
      err.message && errorHandler ? errorHandler(err.message) : errorHandler(err);
    });
} 
