import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import FormField from "../Form/FormField";
import { twoCols } from "../Utils/GridHandlers";

export const HoursField = ({ allowHoursWithoutTeacher, twoColumns }) => {
  if (!allowHoursWithoutTeacher) {
    return <FormField field="hours" />;
  }
  if (twoColumns) {
    return twoCols(
      <FormField
        field="hours"
        allowHoursWithoutTeacher={allowHoursWithoutTeacher}
      />,
      <FormField field="hoursWithoutTeacher" />
    );
  }
  return (
    <Fragment>
      <FormField
        field="hours"
        allowHoursWithoutTeacher={allowHoursWithoutTeacher}
      />
      <FormField field="hoursWithoutTeacher" />
    </Fragment>
  );
};

HoursField.defaultProps = {
  allowHoursWithoutTeacher: false,
  twoColumns: false
};

HoursField.propTypes = {
  allowHoursWithoutTeacher: PropTypes.bool,
  twoColumns: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
  allowHoursWithoutTeacher: state.getIn([
    "curriculums",
    ownProps.curriculumId,
    "allowHoursWithoutTeacher"
  ])
});

export default connect(mapStateToProps)(HoursField);
