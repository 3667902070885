import { Map as iMap } from "immutable";
import {
  SUBMIT_COURSE_FORM,
  SUBMIT_COURSE_FORM_FAIL,
  SUBMIT_COURSE_FORM_SUCCESS
} from "../actions/courseForm";
import { AppTypes } from "../types";
import createReducer from "./createReducer";

const initialState = iMap({
  errors: iMap({}),
  values: iMap({})
});

const courseForm = createReducer(initialState, {
  [AppTypes.SIGNED_IN]: (state, action) =>
    state.mergeIn(["values"], {
      person: action.user.attributes.name,
      email: action.user.attributes.email,
      phone: action.user.attributes.phone_number
    }),
  [SUBMIT_COURSE_FORM]: state =>
    state.merge({
      loading: true,
      remoteError: null
    }),
  [SUBMIT_COURSE_FORM_FAIL]: (state, action) =>
    state.merge({
      loading: false,
      [action.remoteError ? "remoteError" : "errors"]:
        action.remoteError || action.errors
    }),
  [SUBMIT_COURSE_FORM_SUCCESS]: state => state.set("loading", false)
});

export default courseForm;
