const subtractArrayEnds = (array1, array2) => {
  let compare = -2;

  while (compare < 0) {
    if (String(array1.slice(compare)) === String(array2.slice(compare)))
      return compare;
    compare += 1;
  }

  return undefined;
};

export default (str1, str2, connector = "–", separator = " ") => {
  if (str1 === str2) {
    return str1;
  }
  const p1 = str1.split(separator);
  const p2 = str2.split(separator);

  return [
    p1
      .slice(0, subtractArrayEnds(p1, p2))
      .filter(String)
      .join(separator),
    p2.join(separator)
  ].join(connector);
};
