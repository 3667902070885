import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import { I18n } from "@aws-amplify/core";
import { withStyles } from "@material-ui/core/styles";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";

import { asField } from "informed";
import { InlineDatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";

const styles = theme => ({
    formControl: {
        marginLeft: theme.spacing.unit * 2,
        minWidth: 120
    }
});

const hours = [
    0,
    0.5,
    1,
    1.5,
    2,
    2.5,
    3,
    3.5,
    4,
    4.5,
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    8.5,
    9,
    9.5,
    10,
    10.5,
    11,
    11.5,
    12,
    12.5,
    13,
    13.5,
    14,
    14.5,
    15,
    15.5,
    16,
    16.5,
    17,
    17.5,
    18,
    18.5,
    19,
    19.5,
    20,
    20.5,
    21,
    21.5,
    22,
    22.5,
    23,
    23.5
];

const doubleDigit = number => String(Math.floor(number)).padStart(2, "0");
const hourDisplay = hour =>
    [doubleDigit(hour), doubleDigit((hour % 1) * 60)].join(":");
const selectValue = value => value.hours() + value.minutes() / 60;
const parseDate = date => moment.parseZone(date).utc();

const DateField = asField(
    ({ fieldState, fieldApi, field, classes, ...props }) => {
        const { value, error } = fieldState;
        const { setValue, setTouched } = fieldApi;
        const {
            onChange,
            onBlur,
            forwardedRef,
            helperText,
            variant,
            showTimeSelect,
            label,
            required
        } = props;

        const [state, setState] = useState({
            hour: 0,
            minute: 0,
            labelWidth: 0
        });

        useEffect(() => {
            const val = parseDate(value);
            setState({
                hour: value ? val.hours() : 12,
                minute: value ? val.minute() : 0,
                labelWidth: ReactDOM.findDOMNode(forwardedRef.current).offsetWidth
            });
        }, [value, forwardedRef]);

        const { hour: h, minute: m } = state;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container direction="row">
                    <Grid item xs={showTimeSelect ? 8 : 12}>
                        <InlineDatePicker
                            field={field}
                            label={label}
                            required={required}
                            fullWidth
                            ref={forwardedRef}
                            error={!!error}
                            helperText={error || helperText}
                            variant={!!variant && variant.length > 0 ? variant : "outlined"}
                            format="Do MMM YYYY"
                            value={value ? parseDate(value) : null}
                            onChange={val => {
                                setValue(
                                    parseDate(val)
                                        .hour(h)
                                        .minute(m)
                                );
                                onChange && onChange(val);
                            }}
                            onBlur={e => {
                                setTouched();
                                onBlur && onBlur(e);
                            }}
                        />
                    </Grid>
                    {showTimeSelect ? (
                        <Grid item xs={2}>
                            <Fragment>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel
                                        ref={forwardedRef}
                                        htmlFor="outlined-time-native-simple"
                                    >
                                        {I18n.get("klokkeslett")}
                                    </InputLabel>
                                    <NativeSelect
                                        value={selectValue(
                                            parseDate(
                                                value ||
                                                parseDate()
                                                    .hour(h)
                                                    .minute(m)
                                            )
                                        )}
                                        onChange={e => {
                                            const targetValue = parseFloat(e.target.value);
                                            const hour = Math.floor(targetValue);
                                            const minute = (targetValue % 1) * 60;
                                            const newDate = parseDate(value);
                                            setValue(newDate.hour(hour).minute(minute));
                                            onChange && onChange(e);
                                        }}
                                        input={
                                            <OutlinedInput
                                                name="time"
                                                id="outlined-time-native-simple"
                                                labelWidth={state.labelWidth}
                                            />
                                        }
                                    >
                                        {hours.map(hour => (
                                            <option key={hour} value={hour}>
                                                {hourDisplay(hour)}
                                            </option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </Fragment>
                        </Grid>
                    ) : null}
                </Grid>
            </MuiPickersUtilsProvider>
        );
    }
);

DateField.defaultProps = {
    showTimeSelect: false
};

DateField.propTypes = {
    showTimeSelect: PropTypes.bool,
    classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(DateField);
