import { connect } from 'react-redux';

import SnackbarView from './SnackbarView';
import { snackbarHide } from '../../actions/app';

const mapStateToProps = state => ({
    payload: state.getIn(['app', 'snackbar', 'payload']),
    variant: state.getIn(['app', 'snackbar', 'variant']),
    message: state.getIn(['app', 'snackbar', 'message']),
    open: state.getIn(['app', 'snackbar', 'open'])
});

const mapDispatchToProps = dispatch => ({
    handleClose: () => dispatch(snackbarHide),
    commit: (payload) => dispatch(payload)
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarView);