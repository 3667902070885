import { fromJS } from "immutable";
import { push } from "connected-react-router";

import API, { graphqlOperation } from "@aws-amplify/api";

import { CreateCourse } from "../api/courses";
import { CourseTypes } from "../types";
import { currentOrganizerId } from "../reducers/app";

export const SUBMIT_COURSE_FORM = "SUBMIT_COURSE_FORM";
export const SUBMIT_COURSE_FORM_SUCCESS = "SUBMIT_COURSE_FORM_SUCCESS";
export const SUBMIT_COURSE_FORM_FAIL = "SUBMIT_COURSE_FORM_FAIL";

const submitForm = {
  type: SUBMIT_COURSE_FORM
};

const submitFormFail = (errors, remote = false) => ({
  type: SUBMIT_COURSE_FORM_FAIL,
  [remote ? "remoteError" : "errors"]: errors
});

const submitFormSuccess = course => ({
  type: CourseTypes.ADD_COURSE,
  course: fromJS(course)
});

export const createNewCourse = values => (dispatch, getState) => {
  const state = getState();
  const organizerId = currentOrganizerId(state);

  dispatch(submitForm);

  API.graphql(graphqlOperation(CreateCourse, { ...values, organizerId }))
    .then(response => {
      const { errors } = response;
      if (errors && errors.length) {
        dispatch(submitFormFail(errors, true));
      } else {
        dispatch({ type: SUBMIT_COURSE_FORM_SUCCESS });
        const course = response.data.createCourse;
        dispatch(submitFormSuccess(course));
        dispatch(push(`/kurs/${course.id}`));
      }
    })
    .catch(err => dispatch(submitFormFail(err, true)));
};
