import { connect } from "react-redux";

import { updateUserAttributes, fetchUserState, changePassword } from "../../operations/app";
import { getUserAttribute } from '../../reducers/app';

import ProfileView from "./ProfileView";

const mapStateToProps = state => ({
  user: state.getIn(["app", "user"]),
  emailVerified: getUserAttribute(state, 'email_verified')
});

const mapDispatchToProps = dispatch => ({
  refreshUser: () => dispatch(fetchUserState),
  updateUser: (newAttributes, handleSuccess, handleFailed) => dispatch(updateUserAttributes(newAttributes, handleSuccess, handleFailed)),
  changePassword: (values, success, errorHandler) => dispatch(changePassword(values, success, errorHandler))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileView);