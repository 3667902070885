import { Map } from "immutable";

import * as shared from "./shared";
import {
  LearningAssociationTypes,
  OrganizationsTypes,
  CoursesTypes,
  Organization
} from "../types";

const organizations = (state = Map(), action) => {
  switch (action.type) {
    case OrganizationsTypes.FETCH_REQUEST:
    case OrganizationsTypes.FETCH_SUCCESS:
    case OrganizationsTypes.FETCH_FAILURE:
    case OrganizationsTypes.INVALIDATE:
      return shared
        .mergePayloadToState(
          state,
          Organization,
          (action.payload || {}).organizations,
          action.fetchedAt
        )
        .set(
          String(action.id),
          organization(state.get(String(action.id)), action)
        );
    case LearningAssociationTypes.FETCH_SUCCESS:
      return shared.mergePayloadToState(
        state,
        Organization,
        action.payload.organizations,
        action.fetchedAt,
        { sfId: action.id }
      );
    case CoursesTypes.FETCH_SUCCESS:
      return shared.mergePayloadToState(
        state,
        Organization,
        action.payload.organizations,
        action.fetchedAt
      );
    default:
      return state;
  }
};

const organization = (state = Organization(), action) => {
  switch (action.type) {
    case OrganizationsTypes.FETCH_REQUEST:
      return shared.fetchRequestState(state);
    case OrganizationsTypes.FETCH_SUCCESS:
      return shared.fetchSuccessState(state);
    case OrganizationsTypes.FETCH_FAILURE:
      return shared.fetchErrorState(state, action.message);
    case OrganizationsTypes.INVALIDATE:
      return shared.invalidateState(state);
    default:
      return state;
  }
};

export default organizations;
export const selectors = shared.selectors("organizations");
