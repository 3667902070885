import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import Immutable from "immutable";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import {
  ConnectedRouter,
  routerMiddleware
} from "connected-react-router/immutable";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import Amplify from "@aws-amplify/core";

import createRootReducer from "./reducers/rootReducer";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import config from "./config";

Amplify.configure(config);

const history = createBrowserHistory();
const initialState = Immutable.Map();

const loggerMiddleware = createLogger();

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(history),
  initialState,
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware,
      loggerMiddleware
    )
  )
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
