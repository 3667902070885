import * as schema from "./schema";
import { GetOrganizerOwnCourses } from "../api/courses";
import createFetchAction from "./shared";

import { currentOrganizerId } from "../reducers/app";
import { selectors as organizers } from "../reducers/organizers";
import { OrganizersTypes, CourseTypes } from "../types";

export const fetchCourses = (dispatch, getState) => {
  const id = currentOrganizerId(getState());
  if (!id) { return }
  dispatch(fetchCoursesFor(id));
};

export const fetchCoursesFor = id =>
  createFetchAction(
    organizers,
    OrganizersTypes,
    schema.organizers,
    id,
    GetOrganizerOwnCourses,
    { id }
  );


export const clearCourses = () => ({
  type: CourseTypes.COLLECTION_REMOVE_ALL
})

export const invalidateCourses = (dispatch, getState) => {
  const id = currentOrganizerId(getState());
  dispatch({
    type: OrganizersTypes.INVALIDATE,
    id
  });
};
