import React from "react";
import PropTypes from "prop-types";

import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = theme => ({
  checkbox: {
    alignSelf: "flex-start",
    marginRight: theme.spacing.unit * 2
  }
});

const ListItemCheckbox = ({
  label,
  helperText,
  onChange,
  checked,
  classes
}) => (
  <ListItem>
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            color="primary"
            className={classes.checkbox}
          />
        }
        label={
          <div>
            <Typography variant="subtitle1">{label}</Typography>
            {helperText && (
              <Typography variant="caption" color="textSecondary">
                {helperText}
              </Typography>
            )}
          </div>
        }
      />
    </div>
  </ListItem>
);

ListItemCheckbox.defaultProps = {
  helperText: null,
  checked: false
};

ListItemCheckbox.propTypes = {
  label: PropTypes.node.isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  classes: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(ListItemCheckbox);
