import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";

import ReportDialog from "./ReportDialog";
import { Course, Curriculum } from "../../types";
import Popover from "../Utils/Popover";
import getCourseReport from "../Report/courseReport";

const ReportButton = ({
  course,
  open,
  toggleReport,
  sendReport,
  collection,
  curriculum
}) => {
  const courseReport = getCourseReport(course, collection, curriculum);
  const disabled = courseReport.errorMessage !== undefined;

  return (
    <Fragment>
      <Popover
        disabled={disabled}
        message={I18n.get(courseReport.errorMessage)}
      >
        <IconButton
          aria-label={I18n.get("Send Report")}
          color="inherit"
          onClick={toggleReport}
          disabled={disabled}
        >
          <SendIcon />
        </IconButton>
      </Popover>
      {!disabled && (
        <ReportDialog
          sendReport={sendReport}
          courseReport={courseReport}
          open={open}
          handleClose={toggleReport}
        />
      )}
    </Fragment>
  );
};

ReportButton.defaultProps = {
  collection: undefined
};

ReportButton.propTypes = {
  course: PropTypes.instanceOf(Course).isRequired,
  curriculum: PropTypes.instanceOf(Curriculum).isRequired,
  sendReport: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  collection: PropTypes.shape({}),
  toggleReport: PropTypes.func.isRequired
};

export default ReportButton;
