import React, { useState } from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";
import InputAdornment from '@material-ui/core/InputAdornment'
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import FormField from '../Form/FormField';
import Snackbar from '../Utils/Snackbar';
import Form from '../Form/Form';

const passwordProps = (
    passwordMasks,
    toggleFieldMask,
    field
) => ({
    type: passwordMasks[field] ? 'text' : 'password',
    InputProps: {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton aria-label="Toggle password visibility" onClick={() => toggleFieldMask(field)}>
                    {passwordMasks[field] ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </InputAdornment>
        ),
    },
    variant: 'standard',
    validateOnBlur: false,
    field
});

const ChangePassword = ({
    emailVerified,
    classes,
    changePassword
}) => {

    const [passwordMasks, setPasswordMasks] = useState({ oldPassword: false, newPassword: false, confirmNewPassword: false });
    const toggleFieldMask = field => setPasswordMasks({ ...passwordMasks, [field]: !passwordMasks[field] })

    const [formApi, setFormApi] = useState(null);

    const [feedBackState, setFeedBackState] = useState({
        error: null,
        success: null,
        errorOpen: false,
        successOpen: false
    })

    const errorHandler = error => setFeedBackState({ ...feedBackState, error, errorOpen: true })
    const successHandler = () => {
        setFeedBackState({
            ...feedBackState,
            success: I18n.get('Password changed successfully!'),
            successOpen: true
        })
        formApi.reset();
    }
    const onClose = () => setFeedBackState({ ...feedBackState, errorOpen: false, successOpen: false })

    const { error, success, errorOpen, successOpen } = feedBackState;

    return (
        <React.Fragment>
            <Card className={classes.cardSpace}>
                <CardContent>
                    <Snackbar
                        message={error}
                        open={errorOpen}
                        handleClose={onClose}
                        variant={'error'}
                    />
                    <Snackbar
                        message={success}
                        open={successOpen}
                        handleClose={onClose}
                        variant={'success'}
                    />
                    <Form
                        onSubmit={values => changePassword(values, successHandler, errorHandler)}
                        getApi={setFormApi}
                        noValidate
                    >
                        <React.Fragment>
                            <Typography variant="h5" component="h2">
                                {I18n.get("Change password")}
                            </Typography>
                            <div>
                                <FormField
                                    className={classes.passwordField}
                                    label={I18n.get('Current password')}
                                    helperText={I18n.get('Your current password')}
                                    {...passwordProps(passwordMasks, toggleFieldMask, 'oldPassword', classes)}
                                />
                            </div>
                            <div>
                                <FormField
                                    className={classes.passwordField}
                                    label={I18n.get('New password')}
                                    helperText={I18n.get('At least 8 characters. Capital letters, numbers and small letters')}
                                    {...passwordProps(passwordMasks, toggleFieldMask, 'newPassword', classes)}
                                />
                            </div>
                            <div>
                                <FormField
                                    className={classes.passwordField}
                                    label={I18n.get('Confirm new password')}
                                    helperText={I18n.get('At least 8 characters. Capital letters, numbers and small letters')}
                                    {...passwordProps(passwordMasks, toggleFieldMask, 'confirmNewPassword', classes)}
                                />
                            </div>
                            <div>
                                <Button
                                    disabled={!emailVerified}
                                    style={{ marginTop: 11, marginLeft: 11 }}
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                >
                                    {I18n.get('Change password')}
                                </Button>
                            </div>
                        </React.Fragment>
                    </Form>
                </CardContent>
            </Card>
        </React.Fragment>
    )
};


ChangePassword.propTypes = {
    emailVerified: PropTypes.bool.isRequired,
    classes: PropTypes.shape({}).isRequired,
    changePassword: PropTypes.func.isRequired

}

export default ChangePassword;
