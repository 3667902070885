import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";
import EventIcon from "@material-ui/icons/Event";
import ArchiveIcon from "@material-ui/icons/Archive";

import { getDrawer } from "../reducers/app";
import { handleDrawer } from "../actions/app";

import CurrentUser from "./User/Current";
import AwareListItem from "./Utils/AwareListItem";
import Snackbar from './Snackbar';

const drawerWidth = 300;

const styles = theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1
  }
});

const ResponsiveDrawer = ({
  classes,
  theme,
  drawerOpen,
  drawerHandler,
  children
}) => {
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <CurrentUser>
        <List>
          <AwareListItem
            Icon={EventIcon}
            primary="My courses"
            to="/kurs"
            exact
          />
          <AwareListItem Icon={ArchiveIcon} primary="Archive" to="/arkiv" />
        </List>
        <Divider />
        <List>
          <AwareListItem Icon={AddIcon} primary="New course" to="/soknad" />
        </List>
      </CurrentUser>
    </div>
  );

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        {/* The implementation can be swap with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={drawerOpen}
            onClose={drawerHandler(false)}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Snackbar />
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

ResponsiveDrawer.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  theme: PropTypes.shape({}).isRequired,
  drawerHandler: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

const mapStateToProps = state => ({
  drawerOpen: getDrawer(state)
});

const mapDispatchToProps = dispatch => ({
  drawerHandler: open => () => dispatch(handleDrawer(open))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ResponsiveDrawer));
