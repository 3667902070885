import Immutable from "immutable";
import checkdigit from "checkdigit";
import moment from "moment";
import { AppTypes } from "../types";

export const handleDrawer = open => ({
  type: AppTypes.HANDLE_DRAWER,
  open
});

export const handleDialog = dialog => ({
  type: AppTypes.HANDLE_DIALOG,
  dialog
});

export const handleAuthInput = event => ({
  type: AppTypes.HANDLE_AUTH_INPUT,
  name: event.target.name,
  value: event.target.value
});

export const handleAuthState = newState => ({
  type: AppTypes.HANDLE_AUTH_STATE,
  authState: newState
});

export const signedIn = user => ({
  type: AppTypes.SIGNED_IN,
  user
});

export const signedOut = {
  type: AppTypes.SIGNED_OUT
};
export const authSubmit = {
  type: AppTypes.AUTH_SUBMIT
};

export const cleanAuthUI = {
  type: AppTypes.CLEAN_AUTH_UI
};

export const passwordResetRequest = destination => ({
  type: AppTypes.REQUESTED_PASSWORD_REMINDER,
  destination
});

export const passwordResetSuccess = {
  type: AppTypes.PASSWORD_RESET_SUCCESS
};

export const passwordChangeRequest = {
  type: AppTypes.PASSWORD_CHANGE_REQUEST
};

export const passwordChangeSuccess = {
  type: AppTypes.PASSWORD_CHANGE_SUCCESS
};

export const authFailed = err => ({
  type: AppTypes.AUTH_FAILED,
  error: err
});

export const modalShow = (mood, item, payload) => dispatch =>
  dispatch({
    type: AppTypes.MODAL_SHOW,
    mood,
    item,
    payload
  });

export const modalHide = {
  type: AppTypes.MODAL_HIDE
};

export const userUpdateSuccess = attributes => ({
  type: AppTypes.USER_UPDATE_SUCCESS,
  attributes
});

export const snackbarShow = payload => (variant, message) => dispatch =>
  dispatch({
    type: AppTypes.SNACKBAR_SHOW,
    payload,
    variant,
    message
  });

export const snackbarError = dispatch => ({ errors = [] }) =>
  dispatch({
    type: AppTypes.SNACKBAR_SHOW,
    variant: "error",
    message: String(errors[0].message || "Something went wrong")
  });

export const snackbarHide = {
  type: AppTypes.SNACKBAR_HIDE
};

// Shared input casting
export const castInput = (field = {}, value = "") => {
  if (value) {
    switch (field.type) {
      case "number":
        return Number(value);
      case "datetime-local":
        return moment.parseZone(value).format(moment.HTML5_FMT.DATETIME_LOCAL);
      default:
        return value;
    }
  }
  return "";
};

// Shared validation function
export const validate = (field, name, value = "") => {
  const valued = typeof value === "string" ? value.trim() : value;
  if ((field || {}).required && !valued) {
    return "må oppgis.";
  }
  if (
    name === "bankaccount" &&
    !checkdigit.mod11.isValid(value.replace(/\D/g, "").padEnd(11))
  ) {
    return "er ikke et gyldig kontonummer.";
  }
  return null;
};

export const validateAll = (fields, values) =>
  Immutable.Map(
    Object.keys(fields)
      .map(name => {
        const error = validate(fields[name], name, values.get(name, ""));
        return error ? [name, error] : null;
      })
      .filter(n => n)
  );
