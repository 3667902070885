import { connect } from "react-redux";
import React, { useState } from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";
import { withStyles } from "@material-ui/styles";
import withWidth from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";

import { addNewCourseOwner } from "../../actions/course";
import FormField from "../Form/FormField";
import CourseOwners from "./CourseOwners";
import Form from "../Form/Form";
import {
  Transition,
  isFullScreen,
  DialogHeader,
  styles
} from "../Dialog/Dialog";

const OwnersDialog = ({
  open,
  handleClose,
  addNewOwner,
  courseId,
  classes,
  width
}) => {
  const [formApi, setFormApi] = useState(null);
  const success = () => formApi.reset();
  const fullScreen = isFullScreen(width);

  return (
    <Dialog
      open={open}
      PaperProps={{ square: true }}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      aria-labelledby="dialog-title"
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
    >
      {open && (
        <Form
          onSubmit={({ email }) => addNewOwner(String(email), success)}
          getApi={setFormApi}
          noValidate
        >
          <React.Fragment>
            <DialogHeader
              id="dialog-title"
              fullScreen={fullScreen}
              classes={classes}
              handleClose={handleClose}
            >
              {I18n.get("Course owners")}
            </DialogHeader>
            <DialogContent
              style={fullScreen ? { paddingTop: 64 } : {}}
              className={classes.dialogContent}
            >
              <List>
                <CourseOwners courseId={courseId} />
              </List>
              <FormField
                field="email"
                helperText={I18n.get("Add a new course owner")}
              >
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  margin="10"
                  style={{
                    marginTop: 10,
                    marginLeft: 10
                  }}
                >
                  {I18n.get("Add")}
                </Button>
              </FormField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {I18n.get("Close")}
              </Button>
            </DialogActions>
          </React.Fragment>
        </Form>
      )}
    </Dialog>
  );
};

OwnersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addNewOwner: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  courseId: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  addNewOwner: (email, success) =>
    dispatch(addNewCourseOwner(ownProps.courseId, email, success))
});

export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(withWidth()(OwnersDialog)));
