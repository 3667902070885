export default `query {
            curriculums {
                name
                id
                hoursMin
                hoursMax
                availableFacilitationMeasures
                incompleteFields
                allowHoursWithoutTeacher
                sfId
            }
        }`;

export const GetCurriculums = `query ($search: String!, $sf: ID!){
    curriculums(search: $search, sf: $sf){
        name
        id
        hoursMin
        hoursMax
        availableFacilitationMeasures
        allowHoursWithoutTeacher
        incompleteFields
        sfId
    }
}`;
