import React from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import BlockUi from "../Utils/BlockUi";

const EmailVerifDialog = ({
    children,
    title,
    open,
    firstButton,
    onSubmit,
    secondaryButton,
    extraButton,
    disabled,
    codeStatus,
    error
}) => (
        <Dialog open={open} style={{ margin: 10 }}>
            <Paper style={{ maxWidth: 300, width: 300, padding: 10 }}>
                <BlockUi blocking={false}>
                    <form onSubmit={onSubmit} style={{ padding: 24 }}>
                        <Grid spacing={24} container direction="column">
                            <Grid item>
                                <Typography variant="h5" gutterBottom>
                                    {I18n.get(title)}
                                </Typography>
                            </Grid>
                            {children}
                            {error && (
                                <Grid item>
                                    <Typography color="error">
                                        {I18n.get(error)}
                                    </Typography>
                                </Grid>
                            )}
                            <Typography style={{ marginTop: '10px', textAlign: 'center' }}>{I18n.get(codeStatus)}</Typography>
                            <Grid item container justify="center" direction="row" alignItems="center">
                                <Button disabled={disabled}
                                    type="button"
                                    color="primary"
                                    onClick={firstButton[1]}
                                >
                                    {I18n.get('Send new code')}
                                </Button>
                            </Grid>
                            <Grid item container spacing={24}>
                                {secondaryButton && (
                                    <Grid item xs>
                                        <Button
                                            type="button"
                                            color="primary"
                                            onClick={secondaryButton[1]}
                                        >
                                            {I18n.get(secondaryButton[0])}
                                        </Button>
                                    </Grid>
                                )}
                                {extraButton && (
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={extraButton[1]}
                                        >
                                            {I18n.get(extraButton[0])}
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </BlockUi>
            </Paper>
        </Dialog>
    );


EmailVerifDialog.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    open: PropTypes.bool,
    firstButton: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
    secondaryButton: PropTypes.array.isRequired,
    extraButton: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.string
}


export default EmailVerifDialog;