import { I18n } from "@aws-amplify/core";
import { List } from "immutable";

const validateGender = (value, threshold) => {
  return value > threshold
    ? I18n.get("Facilitation number too large")
    : undefined;
};

const validateExpenses = (expenses, facilitationMeasures) => {
  if (List.isList(facilitationMeasures)) {
    facilitationMeasures.toArray();
  }

  return !expenses &&
    Array.isArray(facilitationMeasures) &&
    facilitationMeasures.length > 0
    ? I18n.get("is required")
    : undefined;
};

export default ({ completedMales, completedFemales }) => values => {
  return {
    facilitationMales: validateGender(values.facilitationMales, completedMales),
    facilitationFemales: validateGender(
      values.facilitationFemales,
      completedFemales
    ),
    facilitationExpenses: validateExpenses(
      values.facilitationExpenses,
      values.facilitationMeasures
    )
  };
};
