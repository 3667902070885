import { Map } from "immutable";

import * as shared from "./shared";
import { CoursesTypes, Session, CourseTypes, SessionTypes } from "../types";

const ss = "sessions";

const sessions = (state = Map(), action) => {
  switch (action.type) {
    case SessionTypes.ADD_SUCCESS:
    case SessionTypes.ADD_FAILURE:
    case SessionTypes.ADD_REQUEST:
    case SessionTypes.REMOVE_SUCCESS:
    case SessionTypes.REMOVE_FAILURE:
    case SessionTypes.REMOVE_REQUEST:
    case SessionTypes.UPDATE_SUCCESS:
    case SessionTypes.UPDATE_FAILURE:
    case SessionTypes.UPDATE_REQUEST:
      // Cases for future development, currently not in use
      return state.set(
        String(action.id),
        session(state.get(String(action.id)), action)
      );
    case CourseTypes.COLLECTION_UPDATE_SUCCESS:
      if (action.collection === ss) {
        return state.setIn([action.item.get("id")], new Session(action.item));
      }
      return state;
    case CourseTypes.COLLECTION_REMOVE_SUCCESS:
      if (action.collection === ss) {
        return state.deleteIn([action.itemId]);
      }
      if (action.collection === "participants") {
        return state.map(session =>
          session.set(
            "attendants",
            session
              .get("attendants")
              .filter(attendant => attendant !== action.itemId)
          )
        );
      }
      return state;
    case CoursesTypes.FETCH_SUCCESS:
      return shared.mergePayloadToState(
        state,
        Session,
        action.payload.sessions,
        action.fetchedAt
      );
    default:
      return state;
  }
};

const session = (state = Session(), action) => {
  switch (action.type) {
    case SessionTypes.ADD_SUCCESS:
    case SessionTypes.ADD_FAILURE:
    case SessionTypes.ADD_REQUEST:
    case SessionTypes.REMOVE_SUCCESS:
    case SessionTypes.REMOVE_FAILURE:
    case SessionTypes.REMOVE_REQUEST:
    case SessionTypes.UPDATE_SUCCESS:
    case SessionTypes.UPDATE_FAILURE:
    case SessionTypes.UPDATE_REQUEST:
      return state;
    default:
      return state;
  }
};

export default sessions;
export const selectors = shared.selectors(ss);

export const getSessions = (state, ids) =>
  selectors.all(state).filter(s => ids.find(id => s.get("id") === id));
