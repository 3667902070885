export const GetOrganizations = `query ($sf: ID!){
  organizations(sf: $sf) {
    id
    name
  }
}`;

export const GetOrganizers = `query ($organizationId: ID!){
  organizers(organizationId: $organizationId) {
    id
    name
    bankAccount
    organization {
      id
      name
    }
  }
}`;

export const GetOwnOrganizers = `query ($ids: [ID!]!) {
  organizers(ids: $ids) {
    id
    name
    bankAccount
    organization {
      id 
      name
    }
  }
}`;

export const CreateOrganizer = `mutation CreateOrganizer(
  $organizationId: ID!
  $name: String!
  $bankAccount: String!
) {
  createOrganizer(input: {
    organizationId: $organizationId,
    name: $name,
    bankAccount: $bankAccount,
  }) {
    id
    name
    bankAccount
    organization {
      id
      name
    }
  }
}`;
