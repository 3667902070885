const dict = {
  nb: {
    "Sign in": "Logg inn",
    "Sign up": "Registrer deg",
    "Sign out": "Logg ut",
    "Signed in as {0}": "Logget inn som {0}",
    "New grants application": "Søknad om tilskudd",
    "Forgot password?": "Glemt passord?",
    "Forgotten your password?": "Glemt passordet ditt?",
    "Send e-mail": "Send e-post",
    "Change password": "Endre passord",
    "Username (e-mail)": "Brukernavn (e-post)",
    "No records": "Ingen oppføringer",
    Select: "Velg",
    Password: "Passord",
    "Your name": "Ditt navn",
    "Confirm your e-mail": "Bekreft e-postadressen din",
    "Complete sign up": "Fullfør registrering",
    "In order to confirm ownership of your e-mail address, please enter the confirmation code sent to ####.":
      "For å bekrefte at du har tilgang til e-postadressen din, vennligst oppgi bekreftelseskoden vi har sendt til ####.",
    "Confirmation code": "Bekreftelseskode",
    "My courses": "Mine kurs",
    Archive: "Arkiv",
    "Add organizer": "Legg til arrangør",
    Organizer: "Kursarrangør (lokallag)",
    "Bank account": "Bankkonto",
    Organization: "Medlemsorganisasjon",
    "Contact person": "Kontaktperson",
    "Course information": "Kursinformasjon",
    "Add new": "Legg til ny",
    "Select organizer": "Velg arrangør",
    Facilitation: "Tilrettelegging",
    "Planned measures": "Planlagte tiltak",
    CALLED_OFF: "Avlyst",
    RETURNED: "Søknad returnert",
    REGISTERED: "Sendt",
    REFUSED: "Avslått",
    WAITING_LIST: "På venteliste",
    GRANTED: "Godkjent",
    REPORT_RETURNED: "Rapport returnert",
    REPORTED: "Rapportert",
    AWARDED: "Til utbetaling",
    COMPLETED: "Fullført",
    "CALLED_OFF.full": "Kurset er avlyst",
    "RETURNED.full": "Søknaden må rettes og sendes på nytt",
    "REGISTERED.full": "Søknaden er sendt til studieforbundet",
    "REFUSED.full": "Søknaden er avslått",
    "WAITING_LIST.full": "Søknaden står på venteliste hos studieforbundet",
    "GRANTED.full": "Kurset er godkjent for tilskudd",
    "REPORT_RETURNED.full": "Rapporten må leveres på nytt",
    "REPORTED.full": "Rapporten er sendt til studieforbundet",
    "AWARDED.full": "Rapporten er godkjent",
    "COMPLETED.full": "Tilskudd er utbetalt",
    ALL_CALLED_OFF: "Avlyste kurs",
    ALL_COMPLETED: "Fullførte kurs",
    ALL_RETURNED: "Returnerte søknader",
    ALL_CURRENT: "Pågående kurs",
    ALL_PLANNED: "Planlagte kurs",
    ALL_PENDING: "Ventende kurs",
    "You have no courses": "Du har ingen registrerte kurs",
    "Course not found": "Får ikke tilgang til kurset",
    "Add participants": "Legg til deltaker",
    "Update participants": "Rediger deltaker",
    "Add sessions": "Legg til samling",
    "Update sessions": "Rediger samling",
    "Pick organizer": "Velg arrangør",
    "Learning association": "Studieforbund",
    Edit: "Rediger",
    Name: "Navn",
    Remove: "Fjern",
    'Create "{0}"': 'Opprett "{0}"',
    Address: "Adresse",
    "Zip code": "Postnummer",
    "Postal city": "Poststed",
    Email: "E-post",
    Phone: "Telefon",
    Teacher: "Kurslærer/instruktør",
    "Start on": "Kursstart",
    "End on": "Kursslutt",
    Location: "Kurssted",
    Curriculum: "Studieplan",
    "Grants application": "Søknad om tilskudd",
    "New course": "Nytt kurs",
    "My profile": "Min profil",
    Cancel: "Avbryt",
    "Birth year": "Fødselsår",
    Gender: "Kjønn",
    Male: "Mann",
    Female: "Kvinne",
    Duration: "Varighet",
    Time: "Tidspunkt",
    Save: "Lagre",
    Close: "Lukk",
    delete: "Slett",
    "confirm delete": "Vil du slette",
    callOff: "Avlys",
    "confirm callOff": "Vil du avlyse",
    cancel: "Avbryt",
    hours: "timer",
    edit: "Rediger",
    "My organizers": "Mine arrangører",
    "User information": "Brukeropplysninger",
    "We will use this information to verify your electronic signature, and provide you with important information regarding your user account.":
      "Vi bruker disse opplysningene til bekrefte din elektroniske signatur, og gi deg viktig informasjon om brukerkontoen din.",
    "Call participant": "Ring deltaker",
    "Text participant": "Send tekstmelding",
    "Email participant": "Send e-post",
    "Register attendance": "Registrer frammøte",
    Participants: "Deltakere",
    participants: "deltakere",
    Sessions: "Samlinger",
    sessions: "samlinger",
    Details: "Detaljer",
    Attendance: "Frammøte",
    attendants: "deltok",
    "course no.": "kursnr.",
    "Course number": "Kursnummer",
    "Change name": "Endre kursnavn",
    "The Workers' Education Association of Norway": "Studieforbundet AOF Norge",
    "The Adult Learning Association for Agriculture and Community":
      "Studieforbundet Næring og Samfunn",
    "Adult Education Association of Christian communities and organizations in Norway":
      "Kristelig studieforbund",
    "The Study Association Funkis": "Studieforbundet Funkis",
    "Association for studies of culture and traditions":
      "Studieforbundet kultur og tradisjon",
    "The Sports Education Foundation": "Idrettens studieforbund",
    "The Adult Education Association of Music": "Musikkens stuideforbund",
    "The Sami Adult Education Association": "Samisk studieforbund",
    "The Adult Education Association of the Centre Party":
      "Senterpartiets Studieforbund",
    "The Adult Education Association of Solidarity":
      "Studieforbundet Solidaritet",
    "Folkeuniversitetet - An Adult Education Association":
      "Studieforbundet Folkeuniversitetet",
    "The Adult Education Association of Nature and Environment":
      "Studieforbundet natur og miljø",
    "The Adult Education Association of the Liberal Party":
      "Venstres studieforbund",
    "The Academic Association for Adult Education": "Akademisk Studieforbund",
    "field.person": "Kontaktperson",
    "field.phone": "Telefonnummer",
    "helpertext.participants.phone":
      "Valgfritt. Du kan bruke denne til å kontakte deltakeren.",
    "field.email": "E-postadresse",
    "helpertext.participants.email":
      "Valgfritt. Du kan bruke denne til å sende beskjeder og kursbevis til deltakeren.",
    "field.curriculumId": "Studieplan",
    "helpertext.curriculumId": "Kurset følger denne godkjente studieplanen.",
    "field.startOn": "Første kursdag",
    "helpertext.startOn": "Dato for første samling.",
    "field.endOn": "Siste kursdag",
    "helpertext.endOn": "Dato for siste samling.",
    "field.startedAt": "Tidspunkt",
    "helpertext.startedAt": "Dato og klokkeslett for når samlingen starter.",
    "field.hours": "Varighet (kurstimer)",
    "helpertext.hours": "Antall kurstimer det søkes støtte for.",
    "field.municipalityId": "Kurssted (kommune)",
    "helpertext.municipalityId": "Kommunen hvor kurset skal gjennomføres.",
    "field.teacher": "Kurslærer",
    "helpertext.teacher": "Navn på ev. lærer eller instruktør.",
    "field.remarks": "Eventuelle merknader",
    "field.facilitation_1": "Ekstra personale",
    "helpertext.facilitation_1":
      "Ledsager eller kursassistent. Lærer med ekstra kompetanse, spesialutdannede likemenn, fysioterapeuter og triminstruktører. Lærer med kunnskap om sjeldne diagnoser.",
    "field.facilitation_2": "Ekstra utstyr/materiell, kosthold og medisin",
    "helpertext.facilitation_2":
      "Store kostnader til spesielt utstyr/materiell kan fordeles på flere kurs. F.eks. utgifter til teleslynge, hjertestarter. Følingsmat, mat for allergikere, diett. Særskilt medisinsk tilrettelegging.",
    "field.facilitation_3": "Tilrettelagt kurssted",
    "helpertext.facilitation_3":
      "Ekstra oppvarming i lokalet, store kurslokaler på grunn av plass til rullestolbrukere, ekstra personale, seng i lokalet.",
    "field.facilitation_4":
      "Tilrettelagt kursprogram og undervisningsmateriell",
    "helpertext.facilitation_4":
      "Redusert gruppestørrelse, korte økter og hyppige pauser, ekstra tid for repetisjon av lærestoff, ekstra kursdager eller ekstra overnatting. Utgifter for tilpasset innhold i kursmateriell blant annet kopiering av ekstra kursmateriell med kontrastfarger.",
    "field.facilitation_7": "Avlastning, barnepass",
    "field.facilitation_8": "Transport",
    "helpertext.facilitation_8": "Drosje, privatbil, offentlig transport.",
    "field.facilitation_9":
      "Ekstra planleggings-, utviklings- og oppfølgingsarbeid",
    "helpertext.facilitation_9":
      "Motivasjon- og oppmuntringsarbeid for å få deltakere på kurs, bruk av tid for å tilrettelegge lokaler, utvikle tilrettelagt kursprogram.",
    "field.facilitationExpected": "Merkostnader tilrettelegging",
    "helpertext.facilitationExpected":
      "Oppgi arrangørens merkostnader for å gjennomføre tiltakene over. Beløp i hele kroner.",
    "field.facilitation":
      "Kurset har deltakere med særlige behov for tilrettelagt opplæring",
    "helpertext.facilitation":
      "Dere kan søke tilretteleggingstilskudd for ekstra utgifter knyttet til tilrettelegging av opplæringen for deltakere som har behov for dette.",
    "field.name": "Navn",
    "field.address": "Adresse",
    "field.zip": "Postnr.",
    "field.postal": "Poststed",
    "field.gender": "Kjønn",
    "field.birthYear": "Fødselsår",
    "field.repertoire": "Repertoar",
    "helpertext.repertoire":
      "Liste over repertoar og annen kurslitteratur, med tittel og forfatter, som benyttes på kurset.",
    "is required": "må oppgis",
    "is not valid": "er ugyldig",
    "is not a valid bank account": "er ikke et gyldig kontonummer",
    "I have read and understand": "Jeg har lest og forstått",
    "the grants rules": "tilskuddsreglene",
    "Last course day must be the same or after the first":
      "Siste kursdag må være samme dag eller etter første dag",
    "Last course day cannot be more than two years from the first":
      "Siste kursdag kan ikke være mer enn to år fra første dag",
    "field.comment": "Eventuelle merknader",
    "helpertext.comment": "Valgfritt. Merknader til saksbehandler",
    "field.facilitationExpenses": "Merkostnader tilrettelegging",
    "helpertext.facilitationExpenses":
      "Oppgi arrangørens merkostnader for gjennomføring av tilretteleggingstiltak. Beløp i hele kroner",
    "field.participantsExplanation": "Begrunnelse for lavt deltakerantall",
    "helpertext.participantsExplanation":
      "Forklar hvorfor kurset har færre enn 3 fullførte deltakere.",
    Next: "Neste",
    Back: "Tilbake",
    "Time deviation": "Tidsavvik",
    "Voluntary work": "Frivilling arbied",
    Finish: "Fullfør",
    "All steps completed - you're finished": "Alle steg er utfylt",
    "Submit report": "Send inn rapport",
    "Send report": "Send rapport",
    "Start over": "Start på nytt",
    "Course summary": "Kurssammendrag",
    "Measures and facilitation": "Tiltak og tilrettelegging",
    Economy: "Økonomi",
    Certificates: "Sertifikater",
    Course: "Kursperiode",
    Incomes: "Inntekter",
    Expenses: "Ugifter",
    "Voluntary work is reported in hours":
      "Dugnadsarbeid er rapportert i timer",
    "field.hoursVoluntaryWork": "Dugnadsarbeid",
    "helpertext.hoursVoluntaryWork":
      "Valgfritt. Ulønnet tid brukt for å gjennomføre kurset. Hele timer",
    "field.incomes": "Inntekter",
    "field.expenses": "Utgifter",
    "helpertext.incomes": "Valgfritt. Hele kr",
    "helpertext.expenses": "Valgfritt. Hele kr",
    "field.facilitationMales": "Antall menn",
    "field.facilitationFemales": "Antall kvinner",
    "helpertext.facilitationMales": "Fullførte med tiltaksbehov",
    "helpertext.facilitationFemales": "Fullførte med tiltaksbehov",
    males: "menn",
    females: "kvinner",
    "Finished participants": "Fullførte deltagere",
    "sessions total": "samlinger totalt",
    "field.hoursExplanation": "Begrunnelse for for timeavvik",
    "helpertext.hoursExplanation":
      "Forklar hvorfor antall rapporterte kurstimer er høyere eller lavere enn studieplanen anviser.",
    "field.confirmDiploma": "Deltagerne har fått tilbud om kursbevis",
    "helpertext.confirmDiploma":
      "Kursdeltagere har krav på dokumentasjon på gjennomført opplæring",
    "field.confirmReport":
      "Kurset er gjennomført i henhold til opplysningene og tilfredsstiller krav for tilskudd etter voksenopplæringsloven",
    "The course has no sessions": "Kurset har ingen samlinger",
    "The course is not granted": "Kurset er ikke bekreftet",
    "Course report must be returned": "Kursrapport er ikke returnert",
    "Course must be finished": "Kurset er ikke fullført",
    "The course has no finished participants":
      "Kurset har ingen fullførte deltagere",
    Hours: "Timer",
    "Send Report": "Send rapport",
    "Cancel course": "Avlys kurset",
    and: "og",
    "Report to the learning association": "Rapporter til studieforbundet",
    "Confirmation and submission": "Bekreftelse og innsending",
    "Number with facilitation":
      "Kursdeltakere finansiert med tilretteleggingstilskudd",
    Total: "Totalt",
    "Current password": "Nåværende passord",
    "New password": "Nytt passord",
    "Confirm new password": "Bekreft nytt passord",
    "Password changed successfully!": "Passordendring vellykket!",
    "Passwords must be alike": "Passordene må være like",
    "Password must be eight characters or longer":
      "Passordet må være 8 tegn eller lengre",
    "Password must have at least one capital letter":
      "Passordet må ha minst en stor bokstav (A-Z)",
    "Password must have at least one number": "Passordet må ha minst et tall",
    "Your current password": "Ditt nåværende passord",
    "At least 8 characters. Capital letters, numbers and small letters":
      "Minst 8 tegn, store bokstaver, små bokstaver og tall.",
    "Password must have at least one small character":
      "Passordet må ha minst en liten bokstav (a-z)",
    "Incorrect username or password.": "Ugyldig brukernavn eller passord",
    "Add a new course owner": "Legg til bruker",
    "Course owners": "Brukertilganger",
    Add: "Legg til",
    Report: "Rapport",
    Application: "Søknad",
    "The course is cancelled": "Kurset er kansellert",
    "Name changed successfully": "Kursnavn endret",
    Repertoire: "Repertoar",
    "field.facilitation_EXTRA_STAFF": "Ekstra personale",
    "field.facilitation_EXTRA_EQUIPMENT":
      "Ekstra utstyr/materiell, kosthold og medisin",
    "field.facilitation_FACILITATED_LOCATION": "Tilrettelagt kurssted",
    "field.facilitation_ADAPTED_PROGRAM":
      "Tilrettelagt kursprogram og undervisningsmateriell",
    "field.facilitation_FACILITATED_MATERIAL": "MATERIAL",
    "field.facilitation_TEACHER_WITH_RARE_SKILLS": "RARE SKILLS",
    "field.facilitation_CHILDCARE": "Avlastning, barnepass",
    "field.facilitation_TRANSPORTATION": "Transport",
    "field.facilitation_EXTRA_WORK":
      "Ekstra planleggings-, utviklings- og oppfølgingsarbeid",
    "helpertext.facilitation_EXTRA_STAFF":
      "Ledsager eller kursassistent. Spesialutdannede likemenn, fysioterapeuter og triminstruktører. Lærer med kunnskap om sjeldne diagnoser.",
    "helpertext.facilitation_EXTRA_EQUIPMENT":
      "Store kostnader til spesielt utstyr/materiell kan fordeles på flere kurs. F.eks. utgifter til teleslynge, hjertestarter. Følingsmat, mat for allergikere, diett. Særskilt medisinsk tilrettelegging.",
    "helpertext.facilitation_FACILITATED_LOCATION":
      "Ekstra oppvarming i lokalet, store kurslokaler på grunn av plass til rullestolbrukere, ekstra personale, seng i lokalet.",
    "helpertext.facilitation_ADAPTED_PROGRAM":
      "Redusert gruppestørrelse, korte økter og hyppige pauser, ekstra tid for repetisjon av lærestoff, ekstra kursdager eller ekstra overnatting. Utgifter for tilpasset innhold i kursmateriell blant annet kopiering av ekstra kursmateriell med kontrastfarger.",
    "helpertext.facilitation_FACILITATED_MATERIAL": "material",
    "helpertext.facilitation_TEACHER_WITH_RARE_SKILLS":
      "Lærer med ekstra kompetanse",
    "helpertext.facilitation_TRANSPORTATION":
      "Drosje, privatbil, offentlig transport.",
    "helpertext.facilitation_EXTRA_WORK":
      "Motivasjon- og oppmuntringsarbeid for å få deltakere på kurs, bruk av tid for å tilrettelegge lokaler, utvikle tilrettelagt kursprogram.",
    "field.hoursWithoutTeacher": "Kurstimer uten lærer",
    "field.hoursWithTeacher": "Kurstimer med lærer",
    "with a teacher": "med lærer",
    "without a teacher": "uten lærer",
    "The report is sent": "Rapporten er sendt"
  }
};
export default dict;
