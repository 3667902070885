import React from "react";
import PropTypes from "prop-types";

import FetchError from "./FetchError";
import BlockUi from "../Utils/BlockUi";

const makeFetch = WrapComponent => {
  class Fetcher extends React.Component {
    componentDidMount() {
      const { fetchData } = this.props;
      fetchData();
    }

    componentDidUpdate(prevProps) {
      const { didInvalidate, fetchData, currentOrgId } = this.props;
      if (!!didInvalidate && (!prevProps.didInvalidate || prevProps.currentOrgId !== currentOrgId)) {
        fetchData();
      }
    }

    render() {
      const { errorMessage, fetchData, isFetching, ...props } = this.props;
      if (errorMessage) {
        return (
          <FetchError
            message={errorMessage || "Something went wrong"}
            onRetry={fetchData}
          />
        );
      }
      return (
        <BlockUi blocking={isFetching}>
          <WrapComponent {...props} isFetching={isFetching} />
        </BlockUi>
      );
    }
  }
  Fetcher.defaultProps = {
    errorMessage: null
  };

  Fetcher.propTypes = {
    errorMessage: PropTypes.string,
    fetchData: PropTypes.func.isRequired,
    didInvalidate: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired
  };
  return Fetcher;
};

export default makeFetch;
