import React from "react";
import PropTypes from "prop-types";

import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";

import AddIcon from "@material-ui/icons/Add";
import { Participant, Session } from "../../types";

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  },
  fabTop: {
    position: "fixed",
    top: theme.spacing.unit * 10,
    right: theme.spacing.unit * 2,
    zIndex: theme.zIndex.appBar + 1
  }
});

const transitionDuration = {
  enter: 225,
  exit: 195
};

const ShowFab = ({ tab, index, setCollection, width, classes }) => {
  const fabs = [
    {},
    {
      icon: AddIcon,
      action: () => setCollection("participants", new Participant({})),
      key: "fab2"
    },
    {
      icon: AddIcon,
      action: () => setCollection("sessions", new Session({})),
      key: "fab3"
    }
  ];
  const fab = fabs[index];

  if (!fab || fab.skip) {
    return null;
  }

  return (
    <Zoom
      in={tab === index}
      timeout={transitionDuration}
      className={width === "xs" ? classes.fab : classes.fabTop}
      style={{
        transitionDelay: tab === index ? transitionDuration.exit : 0
      }}
      unmountOnExit
    >
      <Fab color="secondary" onClick={fab.action}>
        <fab.icon />
      </Fab>
    </Zoom>
  );
};

ShowFab.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  tab: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  setCollection: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired
};

export default withStyles(styles)(withWidth()(ShowFab));
