import React from "react";
import XBlockUi from "react-block-ui";
import Loader from "react-loaders";
import "react-block-ui/style.css";
import "loaders.css";

const BlockUi = props => (
  <XBlockUi loader={<Loader type="line-scale" color="orange" />} {...props} />
);

export default BlockUi;
