import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import moment from "moment";

import { I18n } from "@aws-amplify/core";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import EventNoteIcon from "@material-ui/icons/EventNote";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import LockIcon from "@material-ui/icons/Lock";

import { Course, Municipality } from "../../types";
import { getMunicipality } from "../../reducers/municipalities";

import dateJoiner from "../../presenters/dateJoiner";

const CourseStatusIcon = ({ status }) => {
  switch (status) {
    case "REFUSED":
    case "CALLED_OFF":
      return <EventBusyIcon color="error" />;
    case "REGISTERED":
      return <AssignmentIcon />;
    case "GRANTED":
      return <AssignmentIcon style={{ color: "green" }} />;
    case "AWARDED":
    case "COMPLETED":
      return <EventAvailableIcon style={{ color: "green" }} />;
    case "RETURNED":
    case "REPORT_RETURNED":
      return <AssignmentReturnedIcon style={{ color: "orange" }} />;
    case "REPORTED":
      return <AssignmentTurnedInIcon />;
    case "WAITING_LIST":
      return <ScheduleIcon />;
    default:
      return <EventNoteIcon />;
  }
};

CourseStatusIcon.propTypes = {
  status: PropTypes.string.isRequired
};

const courseListItemProps = course =>
  course.get("isOwner")
    ? { button: true, component: Link, to: `kurs/${course.get("id")}` }
    : {};

const ListViewItem = ({ course, municipality }) => (
  <ListItem divider {...courseListItemProps(course)}>
    <ListItemIcon>
      {course.get("isOwner") ? (
        <CourseStatusIcon status={course.get("status")} />
      ) : (
        <LockIcon color="disabled" />
      )}
    </ListItemIcon>
    <ListItemText
      primary={course.get("name")}
      secondary={[
        municipality.name,
        dateJoiner(
          moment(course.get("startedOn")).format("LL"),
          moment(course.get("endedOn")).format("LL")
        )
      ].join(", ")}
    />
    <Typography color="textSecondary" align="right">
      {I18n.get(course.get("status"))}
      <br />
      {(course.get("number") || " ").match(/.{1,2}/g).join(" ")}
    </Typography>
  </ListItem>
);

ListViewItem.propTypes = {
  course: PropTypes.instanceOf(Course).isRequired,
  municipality: PropTypes.instanceOf(Municipality).isRequired
};

const mapStateToProps = (state, ownProps) => {
  const id = String(ownProps.course.municipality);
  return {
    municipality: getMunicipality(state, id)
  };
};

export default connect(mapStateToProps)(ListViewItem);
