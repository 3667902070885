import React from "react";
import moment from "moment";

import MuiTextField from "@material-ui/core/TextField";
import { asField } from "informed";

const castInputValue = (type, value) => {
  if (value) {
    switch (type) {
      case "number":
        return Number(value);
      case "datetime-local":
        return moment.parseZone(value).format(moment.HTML5_FMT.DATETIME_LOCAL);
      default:
        return value;
    }
  }
  return "";
};

const TextField = asField(({ fieldState, fieldApi, field, ...props }) => {
  const { value, error } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const {
    onChange,
    onBlur,
    initialValue,
    forwardedRef,
    helperText,
    type,
    variant,
    ...rest
  } = props;

  return (
    <MuiTextField
      {...rest}
      ref={forwardedRef}
      fullWidth
      variant={variant && variant.length > 0 ? variant : "outlined"}
      error={!!error}
      helperText={error || helperText}
      value={!value && value !== 0 ? "" : value}
      type={type}
      onChange={(e) => {
        setValue(castInputValue(type, e.target.value));
        onChange && onChange(e)
      }}
      onBlur={e => {
        setTouched();
        onBlur && onBlur(e);
      }}
    />
  );
});

export default TextField;
