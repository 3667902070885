import React, { useState } from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";

import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SendIcon from "@material-ui/icons/Send";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import TextFieldsIcon from "@material-ui/icons/TextFields";

const CourseActions = ({
  editable,
  toggleNameChange,
  toggleReport,
  toggleOwners,
  cancelCourse
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => setAnchorEl(null);
  const handleClick = e => setAnchorEl(e.currentTarget);

  const handler = {
    handleClose,
    toggleNameChange,
    toggleReport,
    toggleOwners,
    cancelCourse
  };

  return (
    <React.Fragment>
      <IconButton
        aria-haspopup="true"
        color="inherit"
        aria-label={I18n.get("actions")}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handler.handleClose();
            handler.toggleNameChange();
          }}
        >
          <ListItemIcon>
            <TextFieldsIcon />
          </ListItemIcon>
          <ListItemText inset>{I18n.get("Change name")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handler.handleClose();
            handler.toggleOwners();
          }}
        >
          <ListItemIcon>
            <GroupAddIcon />
          </ListItemIcon>
          <ListItemText inset>{I18n.get("Course owners")}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={editable}
          onClick={() => {
            handler.handleClose();
            handler.toggleReport();
          }}
        >
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText inset>{I18n.get("Send report")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handler.handleClose();
            handler.cancelCourse();
          }}
        >
          <ListItemIcon>
            <CancelPresentationIcon />
          </ListItemIcon>
          <ListItemText inset>{I18n.get("Cancel course")}</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

CourseActions.propTypes = {
  editable: PropTypes.bool.isRequired,
  toggleNameChange: PropTypes.func.isRequired,
  toggleReport: PropTypes.func.isRequired,
  toggleOwners: PropTypes.func.isRequired,
  cancelCourse: PropTypes.func.isRequired
};

export default CourseActions;
