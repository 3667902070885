import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import blueGrey from "@material-ui/core/colors/blueGrey";

import { getBrand } from "../reducers/app";

const augmentColor = (
  color,
  {
    mainShade = 500,
    lightShade = 300,
    darkShade = 700,
    contrastText = "#ffffff"
  } = {}
) => ({
  main: color[mainShade],
  light: color[lightShade],
  dark: color[darkShade],
  contrastText,
  ...color
});

const createTheme = (primary, secondary) =>
  createMuiTheme({
    typography: {
      useNextVariants: true
    },
    palette: {
      primary: { main: primary },
      secondary: { main: secondary },
      error: augmentColor(red),
      success: augmentColor(green, { lightShade: 200 }),
      pending: augmentColor(amber, { contrastText: "#000000" }),
      stale: augmentColor(blueGrey, { lightShade: 100 }),
      background: {
        default: "#e0e0e0"
      },
      contrastThreshold: 3,
      tonalOffset: 0.2
    }
  });

const Theme = ({ primary, secondary, children }) => (
  <MuiThemeProvider theme={createTheme(primary, secondary)}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);

Theme.defaultProps = {
  primary: "#673AB7",
  secondary: "#4CAF50"
};

Theme.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  children: PropTypes.node.isRequired
};

const mapStateToProps = state => ({
  primary: getBrand(state, 0),
  secondary: getBrand(state, 1)
});

export default connect(mapStateToProps)(Theme);
