import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  List as iList,
  Map as iMap,
  OrderedSet as iOrderedSet
} from "immutable";

import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import SendIcon from "@material-ui/icons/Send";

import FacilitationForm from "./FacilitationForm";
import CurriculumCompletionForm from "./CurriculumCompletionForm";
import HoursField from "./HoursField";
import Form from "../Form/Form";
import FormField from "../Form/FormField";

import OrganizerItem from "./OrganizerItem";
import Subheader from "../Utils/Subheader";
import BlockUi from "../Utils/BlockUi";
import AppBar from "../AppBar";
import ListItemCheckbox from "../Utils/ListItemCheckbox";

const grantRuleLink = {
  "2542": "https://www.venstre.no/organisasjon/vs/",
  "2510": "https://www.kulturogtradisjon.no/tilskudd",
  "2521":
    "https://www.idrettsforbundet.no/idrettskretser/buskerud-idrettskrets/idrettslag/stotteordninger/",
  "2545": "https://aks.as/tilskudd/statstilskudd-til-voksenopplaering/",
  "2501": "https://www.aof.no/",
  "2534": "https://nsr.no/sol-kurs-pa-1-2-3/",
  "2535": "https://www.senterpartiskolen.no/",
  "2503": "https://www.naeringogsamfunn.no/",
  "2525": "http://www.musikkensstudieforbund.no/kurstilskudd",
  "2536": "http://www.studieforbundetso.no/",
  "2504": "https://minekurs.studieplaner.no/k-stud/",
  "2538": "https://www.folkeuniversitetet.no/",
  "2539": "https://www.naturogmiljo.no/tilskudd",
  "2508": "https://funkis.no/node/175"
};

const styles = theme => ({
  leftSpace: {
    marginLeft: theme.spacing.unit * 2
  },
  iconRight: {
    marginLeft: theme.spacing.unit
  },
  errorPaper: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.dark,
    width: "100%",
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  formPart: {
    maxWidth: 500,
    marginBottom: 40
  },
  formPaper: {
    backgroundColor: theme.palette.background.paper
  },
  innerPaper: {
    padding: 20
  }
});

class CourseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: false,
      subCurr: "",
      subMun: ""
    };
  }

  setAccept = () => {
    this.setState(state => ({
      accept: !state.accept
    }));
  };

  onSetSubString = (stateName, value) => {
    stateName === "subCurr"
      ? this.setState({ subCurr: value })
      : this.setState({ subMun: value });
  };

  onChangeCurriculum = val => {
    const value = val.toLowerCase();
    const { fetchCurriculums, sfId } = this.props;
    const { subCurr } = this.state;

    if (
      value &&
      (subCurr.length === 0 || !value.startsWith(subCurr)) &&
      value.length > 2
    ) {
      this.onSetSubString("subCurr", value);
      fetchCurriculums(value, sfId, value);
      return;
    }
    if (value && value.length > 2 && value.startsWith(subCurr)) {
      fetchCurriculums(value, sfId, subCurr);

    }
  };

  onChangeMunicipality = val => {
    const value = val.toLowerCase();
    const { fetchMunicipalities } = this.props;
    const { subMun } = this.state;

    if (
      value &&
      (subMun.length === 0 || !value.startsWith(subMun)) &&
      value.length > 1
    ) {
      this.onSetSubString("subMun", value);
      fetchMunicipalities(value, value);
      return;
    }
    if (value && value.length > 1 && value.startsWith(subMun)) {
      fetchMunicipalities(value, subMun);
    }
  };

  render() {
    const {
      values,
      errors,
      remoteError,
      classes,
      loading,
      orgId,
      municipalities,
      curriculums,
      recentlyUsedCurriculums,
      recentlyUsedMunicipalities,
      getCurriculum,
      createCourse,
      sfId
    } = this.props;

    const { accept } = this.state;
    const { onChangeCurriculum, onChangeMunicipality } = this;

    return (
      <BlockUi blocking={loading}>
        <AppBar navBack="/kurs" title={I18n.get("New grants application")} />
        <Form
          id="new-course"
          className={classes.formPaper}
          initialValues={values.toJS()}
          onSubmit={createCourse}
          // onSubmit={values => console.log(values)}
          noValidate
        >
          {({ formState }) => (
            <React.Fragment>
              <OrganizerItem id={orgId} />
              <Divider variant="middle" />
              <List subheader={<Subheader title="Contact person" />}>
                <FormField field="person" />
                <FormField field="phone" />
                <FormField field="email" />
              </List>
              <Divider variant="middle" />
              <List subheader={<Subheader title="Course information" />}>
                <FormField
                  field="curriculumId"
                  items={curriculums.valueSeq()}
                  onSelectNew={onChangeCurriculum}
                  recentlyUsed={recentlyUsedCurriculums}
                />
                <CurriculumCompletionForm
                  curriculumId={formState.values.curriculumId}
                />
                <FormField field="startOn" />
                <FormField field="endOn" min={formState.values.startOn} />
                <HoursField curriculumId={formState.values.curriculumId} />
                <FormField
                  field="municipalityId"
                  items={municipalities.valueSeq()}
                  onSelectNew={onChangeMunicipality}
                  recentlyUsed={recentlyUsedMunicipalities}
                />
                <FormField field="teacher" />
                <FormField field="remarks" />
              </List>
              <Divider variant="middle" />
              <FacilitationForm
                curriculum={getCurriculum(formState.values.curriculumId)}
                field="facilitationExpected"
              />
              <Divider variant="middle" />
              <List>
                {Object.keys(formState.errors).length > 0 && (
                  <ListItem>
                    <Paper className={classes.errorPaper} elevation={0}>
                      <Typography color="inherit">
                        Søknaden inneholder feil som må rettes før den kan
                        sendes:
                      </Typography>
                      <Typography component="ul" color="inherit">
                        {Object.keys(formState.errors).map(field => (
                          <li key={field}>
                            {`${I18n.get(`field.${field}`)} ${
                              formState.errors[field]
                              }`}
                          </li>
                        ))}
                      </Typography>
                    </Paper>
                  </ListItem>
                )}

                {remoteError && (
                  <ListItem>
                    <Paper className={classes.errorPaper} elevation={0}>
                      {remoteError.map(err => (
                        <Typography
                          key={`remoteerr${Math.random().toString(36)}`}
                          color="inherit"
                        >
                          {Object.prototype.hasOwnProperty.call(err, "message")
                            ? err.get("message")
                            : err}
                        </Typography>
                      ))}
                    </Paper>
                  </ListItem>
                )}
                <ListItemCheckbox
                  label={
                    <span>
                      {I18n.get("I have read and understand")}{" "}
                      <a
                        href={grantRuleLink[sfId]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {I18n.get(`the grants rules`)}
                      </a>
                    </span>
                  }
                  checked={accept}
                  onChange={this.setAccept}
                />
                <ListItem>
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                    disabled={loading || !accept || errors.size > 0}
                  >
                    Send søknad <SendIcon className={classes.iconRight} />
                  </Button>
                  {loading && (
                    <div className={classes.leftSpace}>
                      <CircularProgress size={24} />
                    </div>
                  )}
                  <div className={classes.leftSpace}>
                    <Button color="default" component={Link} to="/">
                      Avbryt
                    </Button>
                  </div>
                </ListItem>
              </List>
            </React.Fragment>
          )}
        </Form>
      </BlockUi>
    );
  }
}

CourseForm.defaultProps = {
  remoteError: null,
  loading: false
};


CourseForm.propTypes = {
  values: PropTypes.instanceOf(iMap).isRequired,
  errors: PropTypes.instanceOf(iMap).isRequired,
  remoteError: PropTypes.instanceOf(iList),
  createCourse: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  sfId: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  municipalities: PropTypes.instanceOf(iMap).isRequired,
  curriculums: PropTypes.instanceOf(iMap).isRequired,
  getCurriculum: PropTypes.func.isRequired,
  fetchCurriculums: PropTypes.func.isRequired,
  fetchMunicipalities: PropTypes.func.isRequired,
  recentlyUsedCurriculums: PropTypes.instanceOf(iOrderedSet).isRequired,
  recentlyUsedMunicipalities: PropTypes.instanceOf(iOrderedSet).isRequired,
  loading: PropTypes.bool
};

export default withStyles(styles)(CourseForm);
