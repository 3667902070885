import React from 'react';
import PropTypes from 'prop-types';

import { I18n } from '@aws-amplify/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MobileStepper from '@material-ui/core/MobileStepper';


const styles = theme => ({
    root: {
        maxWidth: 600,
        width: '100%',
        margin: 0,
        flexGrow: 1
    }
});


const NextButton = ({
    formApi,
    handleNext,
    canFinish,
    theme,
    activeStep,
    steps
}) => {
    return activeStep !== steps.length - 1 ?
        <Button
            type="submit"
            size="small"
            onClick={() => handleNext(formApi)}
        >
            {I18n.get('Next')}
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
        :
        <Button
            type="submit"
            size="small"
            color="primary"
            disabled={canFinish}
        >
            {I18n.get('Finish')}
        </Button>

}

NextButton.propTypes = {
    formState: PropTypes.shape({}).isRequired,
    handleNext: PropTypes.func.isRequired,
    canFinish: PropTypes.bool.isRequired,
    theme: PropTypes.shape({}).isRequired,
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.shape({}).isRequired
}


export const BackButton = ({
    theme,
    handleBack,
    activeStep
}) => (
        <Button
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
        >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            {I18n.get('Back')}
        </Button>
    )

BackButton.propTypes = {
    theme: PropTypes.shape({}).isRequired,
    handleBack: PropTypes.func.isRequired,
    activeStep: PropTypes.number.isRequired
}


const MobileStepperComponent = ({
    classes,
    steps,
    activeStep,
    ...props
}) => (
        <MobileStepper
            steps={steps.length}
            position="bottom"
            activeStep={activeStep}
            className={classes.root}
            nextButton={<NextButton activeStep={activeStep} steps={steps} {...props} />}
            backButton={<BackButton activeStep={activeStep} {...props} />}
        />
    )

MobileStepper.propTypes = {
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
    canFinish: PropTypes.bool.isRequired,
    classes: PropTypes.shape({}).isRequired,
    steps: PropTypes.number.isRequired,
    activeStep: PropTypes.number.isRequired,
    formState: PropTypes.shape({}).isRequired,
    theme: PropTypes.shape({}).isRequired
}

export default withStyles(styles, { withTheme: true })(MobileStepperComponent);
