import { schema } from "normalizr";

const curriculum = new schema.Entity("curriculums");
export const curriculums = { data: { curriculums: [curriculum] } };

const municipality = new schema.Entity("municipalities", undefined, {
  idAttribute: "number"
});
export const municipalities = { data: { municipalities: [municipality] } };

const organization = new schema.Entity("organizations");
export const organizations = { data: { organizations: [organization] } };

const session = new schema.Entity("sessions");
const participant = new schema.Entity("participants");
const owner = new schema.Entity("owners");
const course = new schema.Entity("courses", {
  sessions: [session],
  participants: [participant],
  curriculum,
  municipality,
  owners: [owner],
  organizer: new schema.Entity("organizer", { organization })
});

const organizer = new schema.Entity("organizers", {
  organization,
  curriculum,
  municipality,
  courses: [course]
});
export const courseData = {
  data: { course }
};

export const organizers = { data: { organizers: [organizer] } };

export const orgCourses = { data: { organizers: [organizer] } };
