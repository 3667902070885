import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { handleDrawer as drawerHandler } from "../../actions/app";
import { getDrawer } from "../../reducers/app";

const styles = () => ({
  flex: {
    flexGrow: 1
  },
  menuIcon: {
    marginLeft: -12,
    marginRight: 20
  }
});

const MyAppBar = ({
  title,
  subtitle,
  navBack,
  actions,
  classes,
  children,
  handleDrawer
}) => (
  <React.Fragment>
    <AppBar position="sticky">
      <Toolbar>
        {navBack ? (
          <IconButton
            color="inherit"
            className={classes.menuIcon}
            component={Link}
            to={navBack}
            aria-label={I18n.get("Back")}
          >
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <Hidden mdUp>
            <IconButton
              color="inherit"
              className={classes.menuIcon}
              onClick={handleDrawer(true)}
              aria-label={I18n.get("Show menu")}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
        <div className={classes.flex}>
          <Typography variant="h6" noWrap color="inherit">
            {title}
          </Typography>
          {subtitle ? (
            <Typography variant="caption" noWrap color="inherit">
              {subtitle}
            </Typography>
          ) : (
            ""
          )}
        </div>
        {actions}
      </Toolbar>
      {children}
    </AppBar>
  </React.Fragment>
);

MyAppBar.defaultProps = {
  navBack: undefined,
  subtitle: undefined,
  actions: undefined,
  children: undefined
};

MyAppBar.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  handleDrawer: PropTypes.func.isRequired,
  navBack: PropTypes.string,
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  actions: PropTypes.node,
  children: PropTypes.node
};

const mapStateToProps = state => ({
  drawerOpen: getDrawer(state)
});

const mapDispatchToProps = dispatch => ({
  handleDrawer: open => () => dispatch(drawerHandler(open))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MyAppBar));
