import { Map } from "immutable";

import * as shared from "./shared";
import {
  CurriculumsTypes,
  Curriculum,
  OrganizersTypes,
  CoursesTypes
} from "../types";

const fetcherState = shared.createFetchState(CurriculumsTypes);

const curriculums = (state = Map(), action) => {
  switch (action.type) {
    case CurriculumsTypes.FETCH_REQUEST:
    case CurriculumsTypes.FETCH_FAILURE:
    case CurriculumsTypes.INVALIDATE:
      return state.set(action.id, fetcherState(state.get(action.id), action));
    case CurriculumsTypes.FETCH_SUCCESS:
      return shared
        .mergePayloadToState(
          state,
          Curriculum,
          action.payload.curriculums,
          action.fetchedAt
        )
        .set(action.id, fetcherState(state.get(action.id), action));
    case CoursesTypes.FETCH_SUCCESS:
    case OrganizersTypes.FETCH_SUCCESS:
      return shared.mergePayloadToState(
        state,
        Curriculum,
        action.payload.curriculums,
        action.fetchedAt
      );
    default:
      return state;
  }
};

export default curriculums;
export const selectors = shared.selectors("curriculums");
export const getCurriculum = (state, id) => selectors.find(state, id);
