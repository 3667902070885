import React from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";
import List from "@material-ui/core/List";

import { useFieldApi } from "informed";
import Dialog from "../../Dialog";
import { twoCols } from "../../Utils/GridHandlers";
import FormField from "../../Form/FormField";
import { bringAPI } from "../../../api/bring";
import { Participant } from "../../../types";

const ZipField = ({ ...props }) => {
  const fieldApi = useFieldApi("postal");
  return (
    <FormField
      {...props}
      onChange={e => {
        const postal = bringAPI(e.target.value);
        if (postal) {
          postal.then(data => fieldApi.setValue(String(data.result)));
        }
      }}
    />
  );
};

const EditParticipantDialog = ({ item, handleClose, handleSubmit }) => {
  const submit = values =>
    handleSubmit("participants", handleClose)({
      id: item.get("id"),
      ...values
    });

  return (
    <Dialog
      open={Boolean(item)}
      handleClose={handleClose}
      handleSubmit={submit}
      initialValues={item}
      title={I18n.get(`${item.get("id") ? "Update" : "Add"} participants`)}
      action={I18n.get("Save")}
    >
      <List>
        <FormField field="name" />
        <FormField field="address" />
        {twoCols(<ZipField field="zip" />, <FormField field="postal" />)}
        <FormField
          field="phone"
          required={false}
          helperTextKey="helpertext.participants.phone"
        />
        <FormField
          field="email"
          required={false}
          helperTextKey="helpertext.participants.email"
        />
        {twoCols(<FormField field="gender" />, <FormField field="birthYear" />)}
      </List>
    </Dialog>
  );
};

EditParticipantDialog.defaultProps = {
  item: new Participant({})
};

EditParticipantDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Participant)
};

export default EditParticipantDialog;
