import React from "react";

import { withFormState } from "informed";
import PeopleIcon from "@material-ui/icons/People";

import FormField from "../Form/FormField";
import { twoCols } from "../Utils/GridHandlers";
import ShowItem from "../Utils/ShowItem";

const FacilitationGender = withFormState(({ formState, ...props }) => {
  const { facilitationMales, facilitationFemales } = formState.values;
  const m = parseInt(facilitationMales, 10) || 0;
  const w = parseInt(facilitationFemales, 10) || 0;
  const total = m + w;

  return (
    <React.Fragment>
      {twoCols(
        <FormField field="facilitationMales" {...props} />,
        <FormField field="facilitationFemales" {...props} />
      )}
      <ShowItem
        Icon={PeopleIcon}
        label="Number with facilitation"
        value={String(total)}
      />
    </React.Fragment>
  );
});

export default FacilitationGender;
