import { Map } from "immutable";

import * as shared from "./shared";
import {
  MunicipalitiesTypes,
  Municipality,
  OrganizersTypes,
  CoursesTypes
} from "../types";

const fetcherState = shared.createFetchState(MunicipalitiesTypes);

const municipalities = (state = Map(), action) => {
  switch (action.type) {
    case MunicipalitiesTypes.FETCH_REQUEST:
    case MunicipalitiesTypes.FETCH_FAILURE:
    case MunicipalitiesTypes.INVALIDATE:
      return state.set(action.id, fetcherState(state.get(action.id), action));
    case MunicipalitiesTypes.FETCH_SUCCESS:
      return shared
        .mergePayloadToState(
          state,
          Municipality,
          action.payload.municipalities,
          action.fetchedAt
        )
        .set(action.id, fetcherState(state.get(action.id), action));
    case CoursesTypes.FETCH_SUCCESS:
    case OrganizersTypes.FETCH_SUCCESS:
      return shared.mergePayloadToState(
        state,
        Municipality,
        action.payload.municipalities,
        action.fetchedAt
      );
    default:
      return state;
  }
};

export default municipalities;
export const selectors = shared.selectors("municipalities");
export const getMunicipality = (state, id) => selectors.find(state, id);
