import { Record, Map, List } from "immutable";

// Record fields for fetchable states
const fetchable = {
  id: undefined,
  didInvalidate: true,
  errorMessage: undefined,
  isFetching: false,
  fetchedAt: 0
};

const FetchOnly = new Record(fetchable);

const Course = new Record({
  curriculum: undefined,
  curriculumCompletion: undefined,
  email: "",
  endedOn: "",
  editable: false,
  granted: 0,
  facilitationGranted: 0,
  hoursWithoutTeacher: 0,
  miscGranted: 0,
  facilitationMeasures: List(),
  incomes: 0,
  expenses: 0,
  facilitationExpenses: 0,
  municipality: undefined,
  name: "",
  number: undefined,
  organizer: undefined,
  participants: List(),
  owners: Map(),
  person: "",
  phone: "",
  sessions: List(),
  startedOn: "",
  status: "",
  teachers: Map(),
  audits: List(),
  isOwner: false,
  ...fetchable
});

const CourseReport = new Record({
  startDate: undefined,
  endDate: undefined,
  completedMales: 0,
  completedFemales: 0,
  curriculum: undefined,
  participantDeviation: false,
  hours: 0,
  hoursWithoutTeacher: 0,
  hourDeviation: false,
  sessions: 0,
  facilitationExpenses: 0,
  facilitationMeasures: List(),
  facilitationMales: 0,
  facilitationFemales: 0,
  facilitationToggled: false,
  curriculumCompletion: undefined,
  incompleteFields: List(),
  incomes: 0,
  expenses: 0,
  errorMessage: undefined
});

const Organization = new Record({
  name: "",
  sfId: "",
  ...fetchable
});

const Organizer = new Record({
  name: "",
  bankAccount: "",
  organization: "",
  organizationName: "",
  ...fetchable
});

const LearningAssociation = new Record({
  ...fetchable
});

class Curriculum extends Record({
  allowHoursWithoutTeacher: false,
  name: "",
  hoursMin: 0,
  hoursMax: 0,
  availableFacilitationMeasures: List(),
  incompleteFields: List(),
  sfId: "",
  ...fetchable
}) {
  get name() {
    return this.get("name");
  }

  get allowedHoursWithoutTeacher() {
    return this.get("allowHoursWithoutTeacher");
  }
}

class Municipality extends Record({
  name: "",
  county: Map(),
  number: "",
  ...fetchable
}) {
  get countyName() {
    return this.getIn(["county", "name"]);
  }

  get name() {
    return this.get("name");
  }
}

class Participant extends Record({
  address: "",
  birthYear: "",
  email: "",
  gender: "",
  id: "",
  name: "",
  phone: "",
  postal: "",
  zip: "",
  attending: List(),
  ...fetchable
}) {}

class Session extends Record({
  attendants: List(),
  hours: 0,
  hoursWithoutTeacher: 0,
  id: "",
  startedAt: undefined,
  ...fetchable
}) {}

// Create types for fetchable actions
const fetchableTypes = prefix => ({
  FETCH_REQUEST: `${prefix}_FETCH_REQUEST`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_FAILURE: `${prefix}_FETCH_FAILURE`,
  INVALIDATE: `${prefix}_INVALIDATE`
});

const updateTypes = prefix => ({
  UPDATE_REQUEST: `${prefix}_UPDATE_REQUEST`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_FAILURE: `${prefix}_UPDATE_FAILURE`
});

const addTypes = prefix => ({
  ADD_REQUEST: `${prefix}_ADD_REQUEST`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_FAILURE: `${prefix}_ADD_FAILURE`
});

const removeTypes = prefix => ({
  REMOVE_REQUEST: `${prefix}_REMOVE_REQUEST`,
  REMOVE_SUCCESS: `${prefix}_REMOVE_SUCCESS`,
  REMOVE_FAILURE: `${prefix}_REMOVE_FAILURE`
});

const CurriculumsTypes = {
  ...fetchableTypes("CURRICULUMS")
};

const MunicipalitiesTypes = {
  ...fetchableTypes("MUNICIPAILITES")
};

export const CourseTypes = {
  FETCH_REQUEST: "COURSE_FETCH_REQUEST",
  FETCH_SUCCESS: "COURSE_FETCH_SUCCESS",
  FETCH_FAILURE: "COURSE_FETCH_FAILURE",
  INVALIDATE: "COURSE_INVALIDATE",
  UPDATE_REQUEST: "COURSE_UPDATE_REQUEST",
  UPDATE_SUCCESS: "COURSE_UPDATE_SUCCESS",
  UPDATE_FAILURE: "COURSE_UPDATE_FAILURE",
  COLLECTION_UPDATE_SUCCESS: "COURSE_COLLECTION_UPDATE_SUCCESS",
  COLLECTION_REMOVE_SUCCESS: "COURSE_COLLECTION_REMOVE_SUCCESS",
  COLLECTION_REMOVE_ALL: "COURSE_COLLECTION_REMOVE_ALL",
  ADD_COURSE: "COURSE_ADD_COURSE",
  ACCESS_GRANTED: "COURSE_ACCESS_GRANTED",
  ACCESS_REVOKED: "COURSE_ACCESS_REVOKED"
};

const SessionTypes = {
  ...addTypes("SESSION"),
  ...updateTypes("SESSION"),
  ...removeTypes("SESSION"),
  ...fetchableTypes("SESSION"),
  record: Session
};

const ParticipantTypes = {
  ...addTypes("PARTICIPANT"),
  ...updateTypes("PARTICIPANT"),
  ...removeTypes("PARTICIPANT"),
  ...fetchableTypes("PARTICIPANT"),
  record: Participant
};

const CoursesTypes = {
  ...fetchableTypes("COURSES"),
  record: Course
};

const OrganizationsTypes = {
  ...fetchableTypes("ORGANIZATIONS"),
  record: Organization
};

const OrganizersTypes = {
  ...fetchableTypes("ORGANIZERS"),
  ADD_ORGANIZER: "ORGANIZER_ADD_ORGANIZER",
  record: Organizer
};

const LearningAssociationTypes = {
  ...fetchableTypes("LEARNINGASSOCIATIONS"),
  record: LearningAssociation
};

export const AppTypes = {
  MODAL_SHOW: "APP_MODAL_SHOW",
  MODAL_HIDE: "APP_MODAL_HIDE",
  HANDLE_DRAWER: "APP_HANDLE_DRAWER",
  HANDLE_DIALOG: "APP_HANDLE_DIALOG",
  HANDLE_AUTH_STATE: "APP_HANDLE_AUTH_STATE",
  CLEAN_AUTH_UI: "APP_CLEAN_AUTH_UI",
  AUTH_SUBMIT: "APP_AUTH_SUBMIT",
  AUTH_FAILED: "APP_AUTH_FAILED",
  SIGN_OUT: "APP_SIGN_OUT",
  SIGNED_OUT: "APP_SIGNED_OUT",
  SIGNED_IN: "APP_SIGNED_IN",
  USER_UPDATE_SUCCESS: "APP_USER_UPDATE_SUCCESS",
  REQUESTED_PASSWORD_REMINDER: "APP_REQUESTED_PASSWORD_REMINDER",
  PASSWORD_RESET_SUCCESS: "APP_PASSWORD_RESET_SUCCESS",
  PASSWORD_CHANGE_REQUEST: "APP_PASSWORD_CHANGE_REQUEST",
  PASSWORD_CHANGE_SUCCESS: "APP_PASSWORD_CHANGE_SUCCESS",
  SNACKBAR_SHOW: "APP_SNACKBAR_SHOW",
  SNACKBAR_HIDE: "APP_SNACKBAR_HIDE"
};

export {
  FetchOnly,
  Course,
  CoursesTypes,
  CourseReport,
  Curriculum,
  CurriculumsTypes,
  Organizer,
  OrganizersTypes,
  Organization,
  OrganizationsTypes,
  LearningAssociation,
  LearningAssociationTypes,
  Municipality,
  MunicipalitiesTypes,
  Session,
  SessionTypes,
  Participant,
  ParticipantTypes
};
