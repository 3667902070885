import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import passwordAdornments from '../Utils/PasswordAdornments';
import { I18n } from "@aws-amplify/core";
import Dialog from "./Dialog";

const SignUp = ({
  handleState,
  handleInput,
  signUp,
  email,
  password,
  name,
  ...props
}) => {

  const [passwordVisibility, setVisibility] = useState(false);

  return (
    <Dialog
      title="Sign up"
      onSubmit={signUp}
      secondaryButton={["Cancel", handleState("signIn")]}
      {...props}
    >
      <Grid item>
        <TextField
          label={I18n.get("Your name")}
          autoComplete="name"
          name="name"
          type="text"
          value={name}
          onChange={handleInput}
          variant="outlined"
          fullWidth
          autoFocus
        />
      </Grid>
      <Grid item>
        <TextField
          label={I18n.get("Username (e-mail)")}
          autoComplete="email"
          name="email"
          type="email"
          value={email}
          onChange={handleInput}
          variant="outlined"
          fullWidth
          helperText="Du vil få tilsendt en kode til denne e-postadressen."
        />
      </Grid>
      <Grid item>
        <TextField
          label={I18n.get("Password")}
          type={passwordVisibility ? "text" : "password"}
          autoComplete="password"
          name="password"
          value={password}
          onChange={handleInput}
          variant="outlined"
          fullWidth
          helperText="Minst 8 tegn. Må inneholde både store bokstaver, små bokstaver og tall."
          {...passwordAdornments(passwordVisibility, setVisibility)}
        />
      </Grid>
    </Dialog>
  )
};

SignUp.defaultProps = {
  name: "",
  username: "",
  password: "",
  email: ""
};

SignUp.propTypes = {
  handleState: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  username: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  password: PropTypes.string
};

export default SignUp;
