import * as schema from "./schema";
import { GetCurriculums } from "../api/curriculums";
import createFetchAction from "./shared";

import { selectors } from "../reducers/curriculums";
import { CurriculumsTypes } from "../types";

export const fetchCurriculums = (search, sf, subString) =>
  createFetchAction(
    selectors,
    CurriculumsTypes,
    schema.curriculums,
    `${'$' + subString}`,
    GetCurriculums,
    { search, sf }

  );


export default null;
