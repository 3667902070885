import moment from "moment";
import { List } from "immutable";
import { CourseReport } from "../../types";

// Calculate actual values from course sessions and participants.


const getCourseReport = (course, collection, curriculum) => {
  const sessions = collection.sessions.count();
  if (sessions) {
    const [startDate, endDate] = findDateRange(collection.sessions.valueSeq());
    const hours = sumHours(collection.sessions);
    const hoursWithoutTeacher = sumHoursWithoutTeacher(collection.sessions);
    const hourDeviation = hasHourDeviation(curriculum, hours);
    const [completedMales, completedFemales] = countCompletedParticipants(
      collection.participants,
      collection.sessions,
      hours,
      endDate.year()
    );

    const participantDeviation = completedFemales + completedMales < 3;
    const errorMessage = getErrorMessage(
      course.status,
      endDate,
      completedMales + completedFemales
    );
    return CourseReport({
      startDate: startDate.format("LL"),
      endDate: endDate.format("LL"),
      sessions: course.sessions.count(),
      hours,
      hoursWithoutTeacher,
      hourDeviation,
      completedMales,
      completedFemales,
      participantDeviation,
      curriculum,
      facilitationMeasures: course.facilitationMeasures,
      facilitationExpenses: course.facilitationExpenses,
      facilitationToggled: !!(
        List.isList(course.facilitationMeasures) &&
        course.facilitationMeasures.size > 0
      ),
      curriculumCompletion: course.curriculumCompletion.toJS(),
      incompleteFields: curriculum.get("incompleteFields"),
      errorMessage
    });
  }
  return CourseReport({
    sessions,
    errorMessage: "The course has no sessions"
  });
};

// Returns an appropriate errorMessage if the course should not be reportable.
const getErrorMessage = (status, endDate, participants) => {
  if (!["GRANTED", "REPORT_RETURNED"].includes(status)) {
    return "The course is not granted";
  }

  if (!(moment().diff(endDate, "days") >= 0)) {
    return "Course must be finished";
  }
  if (!(participants > 0)) {
    return "The course has no finished participants";
  }
  return undefined;
};

// Returns a tuple containing the first start and last end dates amont the registered course sessions.
const findDateRange = sessions => {
  const startDates = sessions.map(session =>
    moment.parseZone(session.get("startedAt"))
  );
  const endDates = sessions.map(session =>
    moment
      .parseZone(session.get("startedAt"))
      .add(session.get("hours"), "hours")
  );

  return [
    startDates.min((one, two) => one.diff(two)),
    endDates.max((one, two) => one.diff(two))
  ];
};

const hasHourDeviation = (curriculum, hours) =>
  !(curriculum.get("hoursMin") <= hours <= curriculum.get("hoursMax"));

// Return a tuple counting males and females.
const countCompletedParticipants = (participants, sessions, hours, endYear) => [
  countCompletedParticipantsWithGender(
    participants,
    sessions,
    hours,
    endYear,
    "male"
  ),
  countCompletedParticipantsWithGender(
    participants,
    sessions,
    hours,
    endYear,
    "female"
  )
];

// Count participants from 14 years old attending at least 75 % of the course's hours.
const countCompletedParticipantsWithGender = (
  participants,
  sessions,
  hours,
  endYear,
  gender
) =>
  participants
    .filter(
      participant =>
        participant.get("birthYear") <= endYear - 14 &&
        participant.get("gender") === gender
    )
    .keySeq()
    .filter(participantKey =>
      attendingHours(sessions, participantKey, hours * 0.75)
    )
    .count();

// Return true if participantKey is attending at least target hours.
export const attendingHours = (sessions, participantKey, target) =>
  sumHours(
    sessions.filter(session =>
      session.get("attendants").includes(participantKey)
    )
  ) >= target;

const getSum = (sessions, attr) =>
  sessions.reduce((sum, session) => sum + session.get(attr), 0);

export const sumHours = sessions => getSum(sessions, "hours");
const sumHoursWithoutTeacher = sessions =>
  getSum(sessions, "hoursWithoutTeacher");

export default getCourseReport;
