import { Map } from "immutable";
import * as shared from "./shared";
import {
  CoursesTypes,
  Participant,
  CourseTypes,
  ParticipantTypes
} from "../types";

const pp = "participants";

const participants = (state = Map(), action) => {
  switch (action.type) {
    case ParticipantTypes.ADD_SUCCESS:
    case ParticipantTypes.ADD_FAILURE:
    case ParticipantTypes.ADD_REQUEST:
    case ParticipantTypes.REMOVE_SUCCESS:
    case ParticipantTypes.REMOVE_FAILURE:
    case ParticipantTypes.REMOVE_REQUEST:
    case ParticipantTypes.UPDATE_SUCCESS:
    case ParticipantTypes.UPDATE_FAILURE:
    case ParticipantTypes.UPDATE_REQUEST:
      // Cases for future development, currently not in use
      return state.set(
        String(action.id),
        participant(state.get(String(action.id)), action)
      );
    case CourseTypes.COLLECTION_UPDATE_SUCCESS:
      if (action.collection === pp) {
        return state.setIn(
          [action.item.get("id")],
          new Participant(action.item)
        );
      }
      return state;
    case CourseTypes.COLLECTION_REMOVE_SUCCESS:
      if (action.collection === pp) {
        return state.deleteIn([action.itemId]);
      }
      return state;
    case CoursesTypes.FETCH_SUCCESS:
      return shared.mergePayloadToState(
        state,
        Participant,
        action.payload.participants,
        action.fetchedAt
      );
    default:
      return state;
  }
};

const participant = (state = Participant(), action) => {
  switch (action.type) {
    case ParticipantTypes.ADD_SUCCESS:
    case ParticipantTypes.ADD_FAILURE:
    case ParticipantTypes.ADD_REQUEST:
    case ParticipantTypes.REMOVE_SUCCESS:
    case ParticipantTypes.REMOVE_FAILURE:
    case ParticipantTypes.REMOVE_REQUEST:
    case ParticipantTypes.UPDATE_SUCCESS:
    case ParticipantTypes.UPDATE_FAILURE:
    case ParticipantTypes.UPDATE_REQUEST:
      return state;
    default:
      return state;
  }
};

export default participants;
export const selectors = shared.selectors(pp);
export const getParticipants = (state, ids) =>
  selectors.all(state).filter(p => ids.find(id => p.get("id") === id));
