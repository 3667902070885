import API, { graphqlOperation } from "@aws-amplify/api";
import * as schema from "./schema";

import {
  GetOrganizations,
  GetOrganizers,
  GetOwnOrganizers,
  CreateOrganizer
} from "../api/organizations";

import { toggleUserOrganizer } from "../operations/app";

import createFetchAction from "./shared";

import { selectors as organizations } from "../reducers/organizations";
import { selectors as learningAssociations } from "../reducers/learningAssociations";

import {
  OrganizationsTypes,
  LearningAssociationTypes,
  OrganizersTypes
} from "../types";

export const fetchOrganizations = id =>
  createFetchAction(
    learningAssociations,
    LearningAssociationTypes,
    schema.organizations,
    id,
    GetOrganizations,
    { sf: id }
  );

export const fetchOrganizers = id =>
  createFetchAction(
    organizations,
    OrganizationsTypes,
    schema.organizers,
    id,
    id === "OWN" ? GetOwnOrganizers : GetOrganizers,
    {
      organizationId: id
    }
  );

const organizerCreated = organizer => ({
  type: OrganizersTypes.ADD_ORGANIZER,
  id: organizer.id,
  organizer
});

export const createNewOrganizer = (values, closer, setError) => dispatch => {
  API.graphql(graphqlOperation(CreateOrganizer, { ...values })).then(
    response => {
      const { errors } = response;

      if (errors && errors.length) {
        setError(errors[0]);
        return;
      }

      const organizer = response.data.createOrganizer;
      const { id } = organizer;
      dispatch(toggleUserOrganizer(id, true));
      dispatch(organizerCreated(organizer));
      closer();
    }
  );
};
