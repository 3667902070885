import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as app from "../../operations/app";
import {
  getError,
  getLoading,
  getSent,
  getAuthState
} from "../../reducers/app";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ConfirmSignUp from "./ConfirmSignUp";
import ForgotPassword from "./ForgotPassword";
import BlockUi from "../Utils/BlockUi";

const mapStateToProps = state => ({
  error: getError(state),
  loading: getLoading(state),
  sent: getSent(state),
  authState: getAuthState(state)
});

const mapDispatchToProps = dispatch => ({
  handleSignIn: (...args) => dispatch(app.authenticate(...args)),
  handleConfirmSignUp: (...args) => dispatch(app.confirmSignUp(...args)),
  handleSignUp: (...args) => dispatch(app.signUp(...args)),
  handleResetPassword: (...args) => dispatch(app.resetPassword(...args)),
  cleanUI: () => dispatch(app.cleanAuthUI),
  myFetchUserState: () => dispatch(app.fetchUserState)
});

class Authenticate extends React.Component {
  state = {};

  componentDidMount() {
    const { myFetchUserState } = this.props;
    myFetchUserState();
  }

  handleInput = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleState = newState => () => {
    const { cleanUI } = this.props;
    cleanUI();
    this.setState({
      uiState: newState,
      password: "",
      code: ""
    });
  };

  signIn = event => {
    event.preventDefault();
    const { email, password } = this.state;
    const { handleSignIn } = this.props;
    handleSignIn(email, password);
  };

  signUp = event => {
    event.preventDefault();
    const { name, email, password, phoneNumber } = this.state;
    const { handleSignUp } = this.props;
    handleSignUp(name, email, password, phoneNumber);
  };

  confirmSignUp = event => {
    event.preventDefault();
    const { email, password, code } = this.state;
    const { handleConfirmSignUp } = this.props;
    handleConfirmSignUp(email, password, code);
  };

  resetPassword = event => {
    event.preventDefault();
    const { email, password, code } = this.state;
    const { handleResetPassword, sent } = this.props;
    handleResetPassword(email, password, code, sent);
  };

  render() {
    const { authState, children, error, loading, sent } = this.props;
    const { name, email, password, code, uiState } = this.state;
    const {
      handleInput,
      handleState,
      signIn,
      signUp,
      confirmSignUp,
      resetPassword
    } = this;

    switch (authState) {
      case "signedIn":
        return children;
      case "initial":
        return <BlockUi blocking style={{ height: "100vh" }} />;
      case "confirmSignUp":
        return (
          <ConfirmSignUp
            {...{
              email,
              code,
              handleInput,
              handleState,
              error,
              loading,
              confirmSignUp
            }}
          />
        );
      default:
        switch (uiState) {
          case "signUp":
            return (
              <SignUp
                {...{
                  name,
                  email,
                  password,
                  handleInput,
                  handleState,
                  signUp,
                  error,
                  loading
                }}
              />
            );
          case "forgotPassword":
            return (
              <ForgotPassword
                {...{
                  email,
                  code,
                  password,
                  handleInput,
                  handleState,
                  error,
                  loading,
                  resetPassword,
                  sent
                }}
              />
            );
          default:
            return (
              <SignIn
                {...{
                  email,
                  password,
                  handleInput,
                  handleState,
                  signIn,
                  error,
                  loading
                }}
              />
            );
        }
    }
  }
}

Authenticate.defaultProps = {
  authState: "initial",
  error: undefined,
  loading: false,
  sent: undefined
};

Authenticate.propTypes = {
  children: PropTypes.node.isRequired,
  authState: PropTypes.string,
  myFetchUserState: PropTypes.func.isRequired,
  error: PropTypes.node,
  loading: PropTypes.bool,
  handleSignIn: PropTypes.func.isRequired,
  handleSignUp: PropTypes.func.isRequired,
  handleConfirmSignUp: PropTypes.func.isRequired,
  handleResetPassword: PropTypes.func.isRequired,
  cleanUI: PropTypes.func.isRequired,
  sent: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authenticate);
