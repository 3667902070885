import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import PersonIcon from "@material-ui/icons/Person";

import { getOwners } from "../../reducers/courses";
import { getUserAttribute } from "../../reducers/app";
import { removeCourseOwner } from "../../actions/course";

const CourseOwners = ({ owners, ownEmail, removeOwner }) => {
  return owners.valueSeq().map(owner => (
    <ListItem divider key={owner.get("id")}>
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <Typography style={{ marginRight: 11 }}>
        {owner.get("display")}
      </Typography>
      {owner.get("display").includes(ownEmail) ? null : (
        <ListItemSecondaryAction>
          <Tooltip
            title={I18n.get("Remove")}
            aria-label={I18n.get("Remove")}
            placement="left"
          >
            <IconButton onClick={() => removeOwner(owner.get("id"))}>
              <RemoveCircleIcon color="error" />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  ));
};

CourseOwners.propTypes = {
  owners: PropTypes.shape({}).isRequired,
  removeOwner: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  owners: getOwners(state, ownProps.courseId),
  ownEmail: getUserAttribute(state, "email")
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  removeOwner: itemId => dispatch(removeCourseOwner(ownProps.courseId, itemId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseOwners);
