import React from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";

import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";

import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

import ShowItem from "../Utils/ShowItem";

const ContactItem = ({ person, phone, email }) => (
  <List
    disablePadding
    subheader={<ListSubheader>{I18n.get("Contact person")}</ListSubheader>}
  >
    <ShowItem Icon={PersonIcon} label="Name" value={person} />
    <ShowItem Icon={PhoneIcon} label="Phone" value={phone} />
    <ShowItem Icon={EmailIcon} label="Email" value={email} />
  </List>
);

ContactItem.defaultProps = {
  person: "",
  phone: "",
  email: ""
};

ContactItem.propTypes = {
  person: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string
};

export default ContactItem;
