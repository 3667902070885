import React, { useState } from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import withWidth from "@material-ui/core/withWidth";

import { Transition, DialogHeader } from "../Dialog/Dialog";
import VerticalStepper from "../Report/VerticalStepper";

import sections from "../Report/StepperSections";

const styles = {
  flex: { flex: 1 },
  menuButton: { marginLeft: -12, marginRight: 20 },
  menuAction: { marginLeft: 20, marginRight: -12 },
  dialogContent: { padding: 0 }
};

const isFullScreen = width => width === "xs";

const ReportDialog = ({
  sendReport,
  classes,
  width,
  open,
  handleClose,
  courseReport
}) => {
  const [checks, setChecks] = useState({ correct: false, diploma: false });

  const toggleCheckState = attr =>
    setChecks({ ...checks, [attr]: !checks[attr] });

  const { correct, diploma } = checks;
  const canFinish = !correct || !diploma;
  const fullScreen = isFullScreen(width);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{ square: true }}
      aria-labelledby="dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <React.Fragment>
        <DialogHeader
          id="dialog-title"
          fullScreen={fullScreen}
          handleClose={handleClose}
          classes={classes}
          disableButton={fullScreen ? true : undefined}
        >
          {I18n.get("Report to the learning association")}
        </DialogHeader>
        <DialogContent style={fullScreen ? { paddingTop: 64 } : {}}>
          <VerticalStepper
            width={width}
            fullScreen={fullScreen}
            handleSubmit={sendReport}
            canFinish={canFinish}
            handleClose={handleClose}
            courseReport={courseReport}
            sections={sections({
              courseReport,
              toggleCheckState,
              correct,
              diploma
            })}
          />
        </DialogContent>
      </React.Fragment>
    </Dialog>
  );
};

ReportDialog.propTypes = {
  sendReport: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  courseReport: PropTypes.shape({}).isRequired
};

export default withStyles(styles)(withWidth()(ReportDialog));
