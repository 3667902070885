import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { I18n } from "@aws-amplify/core";
import Dialog from "./Dialog";

const SignUp = ({
  handleState,
  handleInput,
  confirmSignUp,
  email,
  code,
  ...props
}) => (
    <Dialog
      title="Confirm your e-mail"
      submitLabel="Complete sign up"
      secondaryButton={["Cancel", handleState("signIn")]}
      onSubmit={confirmSignUp}
      {...props}
    >
      <Grid item>
        <Typography>
          {I18n.get(
            "In order to confirm ownership of your e-mail address, please enter the confirmation code sent to ####."
          ).replace("####", email)}
        </Typography>
        <TextField
          autoFocus
          margin="dense"
          name="code"
          autoComplete="off"
          label={I18n.get("Confirmation code")}
          type="number"
          value={code}
          onChange={handleInput}
          variant="outlined"
          helperText={I18n.get(
            "The confirmation code is sent to your e-mail address."
          )}
        />
      </Grid>
    </Dialog>
  );

SignUp.defaultProps = {
  email: "",
  code: ""
};

SignUp.propTypes = {
  handleState: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  confirmSignUp: PropTypes.func.isRequired,
  email: PropTypes.string,
  code: PropTypes.string
};

export default SignUp;
