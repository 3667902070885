import { Map } from "immutable";
import { CourseTypes, CoursesTypes, OrganizersTypes, Course } from "../types";
import { currentOrganizerId } from "./app";
import { selectors as municipality } from "./municipalities";
import { selectors as curriculum } from "./curriculums";
import * as shared from "./shared";

const courses = (state = Map(), action) => {
  switch (action.type) {
    case CoursesTypes.FETCH_REQUEST:
    case CoursesTypes.FETCH_SUCCESS:
    case CoursesTypes.FETCH_FAILURE:
    case CoursesTypes.INVALIDATE:
    case CourseTypes.ADD_COURSE:
    case CourseTypes.FETCH_REQUEST:
    case CourseTypes.FETCH_FAILURE:
    case CourseTypes.FETCH_SUCCESS:
    case CourseTypes.INVALIDATE:
    case CourseTypes.UPDATE_REQUEST:
    case CourseTypes.UPDATE_SUCCESS:
    case CourseTypes.UPDATE_FAILURE:
    case CourseTypes.COLLECTION_UPDATE_SUCCESS:
    case CourseTypes.COLLECTION_REMOVE_ALL:
    case CourseTypes.COLLECTION_REMOVE_SUCCESS:
    case CourseTypes.ACCESS_GRANTED:
    case CourseTypes.ACCESS_REVOKED:
      return state.set(
        String(action.id),
        course(state.get(String(action.id)), action)
      );
    case OrganizersTypes.FETCH_SUCCESS:
      return shared.mergePayloadToState(
        state,
        Course,
        action.payload.courses,
        action.fetchedAt,
        { organizer: action.id }
      );
    default:
      return state;
  }
};

const course = (state = Course(), action) => {
  switch (action.type) {
    case CoursesTypes.FETCH_REQUEST:
      return shared.fetchRequestState(state);
    case CoursesTypes.FETCH_SUCCESS:
      return Course(
        shared.fetchSuccessState(state).merge({
          ...action.payload.courses[action.id],
          owners: action.payload.owners || {}
        })
      );
    case CoursesTypes.FETCH_FAILURE:
      return shared.fetchErrorState(state, action.message);
    case CoursesTypes.INVALIDATE:
      return shared.invalidateState(state);
    case CourseTypes.ADD_COURSE:
      return new Course(action.course);
    case CourseTypes.UPDATE_REQUEST:
      return state.set("isFetching", true);
    case CourseTypes.UPDATE_SUCCESS:
      return state.merge(action.updated).set("isFetching", false);
    case CourseTypes.UPDATE_FAILURE:
      return state.set("isFetching", false);
    case CourseTypes.COLLECTION_UPDATE_SUCCESS:
      return state.update(action.collection, collection =>
        collection.push(action.item.get("id"))
      );
    case CourseTypes.COLLECTION_REMOVE_SUCCESS:
      return state.update(action.collection, collection =>
        collection.filter(item => item !== action.itemId)
      );
    case CourseTypes.ACCESS_GRANTED:
      return state.setIn(["owners", action.item.get("id")], action.item);
    case CourseTypes.ACCESS_REVOKED:
      return state.removeIn(["owners", action.itemId]);
    default:
      return state;
  }
};

export default courses;

export const selectors = shared.selectors("courses");

const itemSelectors = { curriculum, municipality };

export const getOwners = (state, courseId) =>
  selectors.find(state, courseId).owners;

export const getRecentlyUsedField = (state, fieldName, limit = 3) => {
  const currentOrg = currentOrganizerId(state);
  return selectors
    .all(state) // select every course
    .sortBy(item => item.endedOn) // sort by endedOn
    .filter(item => item.organizer === currentOrg) // select those that match currentOrg
    .map(item =>
      itemSelectors[fieldName].find(state, String(item.getIn([fieldName])))
    ) // get the corrseponding curriculum/municipality
    .toOrderedSet() // no duplicates
    .takeLast(limit); // take the recently used
};
