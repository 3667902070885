import { Map, fromJS } from "immutable";

import * as shared from "./shared";
import { LearningAssociation, LearningAssociationTypes } from "../types";
import seed from "../learningAssociations";

const learningAssociation = (state = LearningAssociation(), action) => {
  switch (action.type) {
    case LearningAssociationTypes.FETCH_REQUEST:
      return shared.fetchRequestState(state);
    case LearningAssociationTypes.FETCH_SUCCESS:
      return shared.fetchSuccessState(state);
    case LearningAssociationTypes.FETCH_FAILURE:
      return shared.fetchErrorState(state, action.message);
    case LearningAssociationTypes.INVALIDATE:
      return shared.invalidateState(state);
    default:
      return state;
  }
};

const learningAssociations = (state = Map(fromJS(seed)), action) => {
  switch (action.type) {
    case LearningAssociationTypes.FETCH_REQUEST:
    case LearningAssociationTypes.FETCH_SUCCESS:
    case LearningAssociationTypes.FETCH_FAILURE:
    case LearningAssociationTypes.INVALIDATE:
      return state.set(
        String(action.id),
        learningAssociation(state.get(String(action.id)), action)
      );
    default:
      return state;
  }
};

export default learningAssociations;
export const selectors = shared.selectors("learningAssociations");
