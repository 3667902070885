import * as schema from "./schema";
import { GetMunicipalities } from "../api/municipalities";
import createFetchAction from "./shared";

import { selectors } from "../reducers/municipalities";
import { MunicipalitiesTypes } from "../types";





export const fetchMunicipalities = (name, subString) =>
  createFetchAction(
    selectors,
    MunicipalitiesTypes,
    schema.municipalities,
    `${'$' + subString}`,
    GetMunicipalities,
    { name }
  );



export default null;
