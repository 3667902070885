import React, { useState } from "react";
import PropTypes from "prop-types";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import MobileStepper from "./MobileStepper";
import validateFields from "./ValidateFields";
import Form from "../Form/Form";
import { VerticalStepperButtons } from "./VerticalStepperButtons";
import { CourseReport } from "../../types";

const VerticalLinearStepper = ({
  handleClose,
  canFinish,
  fullScreen,
  handleSubmit,
  courseReport,
  sections
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleNext = formApi => {
    setTimeout(() => {
      if (Object.keys(formApi.getState().errors).length > 0) {
        return;
      }
      setActiveStep(activeStep + 1);
    }, 20);
  };

  const handleCorrectSubmit = values => {
    if (!(activeStep === sections.length - 1)) {
      return;
    }
    const { facilitationToggled, ...rest } = values;
    if (!facilitationToggled) {
      handleSubmit(
        {
          ...rest,
          facilitationMeasures: [],
          facilitationExpenses: 0,
          facilitationMales: 0,
          facilitationFemales: 0,
          action: "report"
        },
        handleClose
      );
      return;
    }
    handleSubmit(
      {
        ...rest,
        action: "report"
      },
      handleClose
    );
  };

  return (
    <Form
      id="report-form"
      validateFields={validateFields(courseReport)}
      initialValues={courseReport}
      noValidate
      onSubmit={handleCorrectSubmit}
    >
      {({ formApi }) => (
        <React.Fragment>
          {!fullScreen ? (
            <React.Fragment>
              <Stepper activeStep={activeStep} orientation="vertical">
                {sections.map((_, index) => (
                  <Step key={_.title}>
                    <StepLabel style={{ fontSize: 100 }}>
                      {sections[index].title}
                    </StepLabel>
                    <StepContent>{sections[index].component}</StepContent>
                  </Step>
                ))}
              </Stepper>
              <DialogActions>
                <VerticalStepperButtons
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  sections={sections}
                  handleClose={handleClose}
                  canFinish={canFinish}
                  formApi={formApi}
                />
              </DialogActions>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography
                variant="h5"
                style={{ padding: 10, textAlign: "center" }}
              >
                {sections[activeStep].title}
              </Typography>
              {sections[activeStep].component}
              <MobileStepper
                handleNext={handleNext}
                handleBack={handleBack}
                activeStep={activeStep}
                steps={sections}
                canFinish={canFinish}
                formApi={formApi}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Form>
  );
};

VerticalLinearStepper.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  canFinish: PropTypes.bool.isRequired,
  courseReport: PropTypes.instanceOf(CourseReport).isRequired,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClose: PropTypes.func.isRequired
};

export default VerticalLinearStepper;
