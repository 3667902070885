import { connect } from "react-redux";

import { currentOrganizerId } from "../../reducers/app";
import { selectors as courses } from "../../reducers/courses";
import { selectors as organizers } from "../../reducers/organizers";

import { fetchCourses, invalidateCourses } from "../../actions/courses";

import makeFetch from "./makeFetch";

const archiveStatuses = ["CALLED_OFF", "REFUSED", "COMPLETED"];

const mapStateToProps = (state, ownProps) => {
  const currentOrgId = currentOrganizerId(state);
  const { isArchive } = ownProps;
  return {
    isFetching: organizers.isFetching(state, currentOrgId),
    errorMessage: organizers.errorMessage(state, currentOrgId),
    didInvalidate: organizers.didInvalidate(state, currentOrgId),
    courses: courses
      .all(state)
      .filter(
        c =>
          c.get("organizer") === currentOrgId &&
          (archiveStatuses.indexOf(c.get("status")) !== -1) ===
            Boolean(isArchive)
      ),
    currentOrgId
  };
};

const mapDispatchToProps = dispatch => ({
  fetchData: () => dispatch(fetchCourses),
  invalidateCourses: () => dispatch(invalidateCourses)
});

const withCourses = WrapComponent => {
  const Wrapped = makeFetch(WrapComponent);
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Wrapped);
};

export default withCourses;
