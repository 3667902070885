import React, { useState } from "react";
import { connect } from 'react-redux';

import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";

import ConfirmEmail from "./ConfirmEmail";
import { getUserAttribute } from "../../reducers/app";


const styles = theme => ({
    errorCard: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.dark,
        marginBottom: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2,
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    button: {
        border: '1px solid white',
        color: 'white'
    }
});




const EmailVerificationBar = ({ classes, emailVerified }) => {

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    return (
        <React.Fragment>
            {!emailVerified ?
                <Card className={classes.errorCard}>
                    {I18n.get('You are using an unverified email address, please verify')}
                    <Button className={classes.button} onClick={() => setOpen(true)}>
                        {I18n.get('Verify Email')}
                    </Button>
                    <ConfirmEmail handleClose={handleClose} open={open} />
                </Card>
                :
                null}
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    emailVerified: getUserAttribute(state, "email_verified")
})

export default connect(mapStateToProps)(withStyles(styles)(EmailVerificationBar));