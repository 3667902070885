import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyUserAttr, verifyUserAttrSubmit } from "../../operations/app";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import EmailVerifDialog from "./EmailVerifDialog";
import { getUserAttribute } from "../../reducers/app";


const mapStateToProps = state => ({
    email: getUserAttribute(state, "email")
});

const mapDispatchToProps = dispatch => ({
    handleConfirmEmail: (...args) => dispatch(verifyUserAttrSubmit(...args)),
    newCodeRequest: (attr, setCodeStatus) => dispatch(verifyUserAttr(attr, setCodeStatus))
});

class ConfirmEmail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            code: "",
            disabled: false,
            codeStatus: null,
            error: null
        }
    }

    setError = error => {
        this.setState({ error })
    }

    setCodeStatus = codeStatus => {
        this.setState({ codeStatus })
    }

    handleCodeChange = (e) => {
        e.preventDefault()
        const { value } = e.target
        this.setState({ code: value })
        if (this.state.error) {
            this.setState({ error: null })
        }
    }

    handleCodeReq = () => {
        const { props, setCodeStatus } = this;
        const { newCodeRequest, email } = props;
        newCodeRequest("email", setCodeStatus, email)
        this.setState({ disabled: true })
        setTimeout(() => this.setState({ disabled: false }), 5000)

    }

    handleConfirm = (e) => {
        e.preventDefault();
        const { code } = this.state
        const { setError } = this;
        const { handleConfirmEmail } = this.props;
        handleConfirmEmail(code, "email", setError)

    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            const { codeStatus } = this.props;
            if (codeStatus) {
                this.setCodeStatus(codeStatus)
            }
        }
    }

    render() {

        const { props, handleCodeChange, handleCodeReq, handleConfirm } = this;
        const { handleClose, open, } = props;
        const { code, disabled, error, codeStatus } = this.state;

        return (
            <EmailVerifDialog
                onSubmit={handleConfirm}
                title={"Confirm your email"}
                firstButton={["", handleCodeReq]}
                extraButton={["Confirm", handleConfirm]}
                secondaryButton={["cancel", handleClose]}
                disabled={disabled}
                error={error}
                codeStatus={codeStatus}
                open={open}
            >
                <Grid container direction='column' alignItems='center'>
                    <TextField
                        autoFocus
                        fullWidth
                        name="code"
                        autoComplete="off"
                        label="Bekreftelseskode"
                        type="number"
                        value={code}
                        onChange={handleCodeChange}
                        variant="outlined"
                    />
                </Grid>
            </EmailVerifDialog>
        );

    }
}


ConfirmEmail.propTypes = {
    email: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirmEmail: PropTypes.func.isRequired,
    newCodeRequest: PropTypes.func.isRequired
}



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmEmail);