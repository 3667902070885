import React from "react";
import PropTypes from 'prop-types';

import { I18n } from "@aws-amplify/core";

import { withStyles } from '@material-ui/core/styles';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';

const styles = theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing.unit,
        margin: theme.spacing.unit * 1.5
    }
});

const PopoverComponent = ({
    message,
    classes,
    disabled,
    children
}) => (
        <PopupState variant="popover" popupId="demoPopover">
            {popupState => (
                <React.Fragment>
                    {disabled ? <span {...bindHover(popupState)}>{children}</span> : <span>{children}</span>}
                    <Popover
                        {...bindPopover(popupState)}
                        className={classes.popover}
                        classes={{
                            paper: classes.paper,
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        disableRestoreFocus
                    >
                        <Typography>{I18n.get(message)}</Typography>
                    </Popover>
                </React.Fragment>
            )}
        </PopupState>
    );

PopoverComponent.defaulProps = {
    message: ""
}

PopoverComponent.propTypes = {
    message: PropTypes.string,
    classes: PropTypes.shape({}).isRequired,
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.node
}

export default withStyles(styles)(PopoverComponent);


