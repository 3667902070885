import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router";
import { Link } from "react-router-dom";

import { I18n } from "@aws-amplify/core";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const AwareListItem = ({ to, Icon, primary, ...other }) => (
  <Route path={to} {...other}>
    {({ match }) => (
      <ListItem button component={Link} to={to} selected={Boolean(match)}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={I18n.get(primary)} />
      </ListItem>
    )}
  </Route>
);

AwareListItem.propTypes = {
  to: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  primary: PropTypes.string.isRequired
};

export default AwareListItem;
