import React from "react";
import PropTypes from "prop-types";
import { Record } from "immutable";

import { I18n } from "@aws-amplify/core";

import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";

import CloseIcon from "@material-ui/icons/Close";

import Form from "../Form/Form";

export const Transition = props => <Slide direction="up" {...props} />;

export const isFullScreen = width => width === "xs";

export const styles = {
  flex: { flex: 1 },
  menuButton: { marginLeft: -12, marginRight: 20 },
  menuAction: { marginLeft: 20, marginRight: -12 },
  dialogContent: { padding: 0 }
};

export const DialogHeader = ({
  children,
  fullScreen,
  classes,
  handleClose,
  disableButton,
  ...props
}) => {
  const DialogHeaderType = fullScreen ? AppBar : DialogTitle;
  const headerProps = fullScreen ? { color: "default" } : {};

  return (
    <DialogHeaderType {...props} {...headerProps}>
      {fullScreen ? (
        <Toolbar color="primary">
          <IconButton
            onClick={handleClose}
            aria-label={I18n.get("Close")}
            className={classes.menuButton}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.flex}
            noWrap
          >
            {children}
          </Typography>
          {disableButton !== undefined ? null : (
            <Button
              color="inherit"
              type="submit"
              className={classes.menuAction}
            >
              {I18n.get("Save")}
            </Button>
          )}
        </Toolbar>
      ) : (
        children
      )}
    </DialogHeaderType>
  );
};

DialogHeader.defaultProps = {
  disableButton: undefined
};

DialogHeader.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({}).isRequired,
  handleClose: PropTypes.func.isRequired,
  disableButton: PropTypes.bool
};

const MyDialog = ({
  width,
  open,
  classes,
  title,
  maxWidth,
  children,
  setFormApi,
  action,
  handleClose,
  initialValues,
  handleSubmit
}) => {
  const fullScreen = isFullScreen(width);
  const id = initialValues.get("id");

  const onSubmit = values =>
    id ? handleSubmit({ ...values, id }) : handleSubmit(values);

  return (
    <Dialog
      open={open}
      PaperProps={{ square: true }}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      maxWidth={maxWidth}
      aria-labelledby="dialog-title"
      onClose={handleClose}
    >
      {open && (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          getApi={setFormApi}
          noValidate
        >
          <React.Fragment>
            <DialogHeader
              id="dialog-title"
              fullScreen={fullScreen}
              classes={classes}
              handleClose={handleClose}
            >
              {title}
            </DialogHeader>
            <DialogContent
              style={fullScreen ? { paddingTop: 64 } : {}}
              className={classes.dialogContent}
            >
              {children}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {I18n.get("Cancel")}
              </Button>
              <Button type="submit" color="primary">
                {action || I18n.get("Save")}
              </Button>
            </DialogActions>
          </React.Fragment>
        </Form>
      )}
    </Dialog>
  );
};

MyDialog.defaultProps = {
  action: undefined,
  maxWidth: undefined,
  setFormApi: undefined,
  initialValues: Record
};

MyDialog.propTypes = {
  width: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  setFormApi: PropTypes.func,
  action: PropTypes.string,
  maxWidth: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  initialValues: PropTypes.instanceOf(Record)
};

export default withStyles(styles)(withWidth()(MyDialog));
