import React from "react";
import PropTypes from "prop-types";
import { I18n } from "@aws-amplify/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import BlockUi from "../Utils/BlockUi";

const Dialog = ({
  children,
  title,
  submitLabel,
  onSubmit,
  secondaryButton,
  extraButton,
  loading,
  error
}) => (
  <React.Fragment>
    <Paper style={{ maxWidth: 400, margin: "10% auto" }}>
      <BlockUi blocking={Boolean(loading)}>
        <form onSubmit={onSubmit} style={{ padding: 24 }}>
          <Grid spacing={24} container direction="column">
            <Grid item>
              <Typography variant="h4" gutterBottom>
                {I18n.get(title)}
              </Typography>
            </Grid>
            {children}
            {error && (
              <Grid item>
                <Typography color="error">
                  {I18n.get(error.message || error)}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                {I18n.get(submitLabel || title)}
              </Button>
            </Grid>
            <Grid item container spacing={16}>
              {secondaryButton && (
                <Grid item xs>
                  <Button
                    type="button"
                    color="primary"
                    onClick={secondaryButton[1]}
                  >
                    {I18n.get(secondaryButton[0])}
                  </Button>
                </Grid>
              )}
              {extraButton && (
                <Grid item>
                  <Button
                    type="button"
                    color="primary"
                    onClick={extraButton[1]}
                  >
                    {I18n.get(extraButton[0])}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </form>
      </BlockUi>
    </Paper>
  </React.Fragment>
);

Dialog.defaultProps = {
  submitLabel: undefined,
  loading: false,
  error: undefined,
  secondaryButton: undefined,
  extraButton: undefined
};

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  submitLabel: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  secondaryButton: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  ),
  extraButton: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  ),
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default Dialog;
