import React from "react";
import PropTypes from "prop-types";
import checkdigit from "checkdigit";
import moment from "moment";

import { I18n } from "@aws-amplify/core";

import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import SchoolIcon from "@material-ui/icons/School";
import TimerIcon from "@material-ui/icons/Timer";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import TodayIcon from "@material-ui/icons/Today";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ChatIcon from "@material-ui/icons/Chat";
import CommentIcon from "@material-ui/icons/Comment";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import HomeIcon from "@material-ui/icons/Home";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import ChildCareIcon from "@material-ui/icons/ChildCare";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import WcIcon from "@material-ui/icons/Wc";

import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import TextField from "./TextField";
import AutocompleteField from "./AutocompleteField";
import GenderField from "./GenderField";
import DateField from "./DateField";
import HourField from "./HourField";

const types = {
  curriculumId: AutocompleteField,
  municipalityId: AutocompleteField,
  gender: GenderField,
  startOn: DateField,
  endOn: DateField,
  startedAt: DateField,
  hours: HourField
};

const requiredFields = [
  "person",
  "phone",
  "email",
  "curriculumId",
  "startOn",
  "endOn",
  "hours",
  "municipalityId",
  "name",
  "address",
  "zip",
  "postal",
  "gender",
  "birthYear",
  "startedAt",
  "hoursExplanation",
  "participantsExplanation",
  "facilitationExpenses",
  "confirmNewPassword",
  "oldPassword",
  "newPassword"
];

const icons = {
  person: <PersonIcon />,
  phone: <PhoneIcon />,
  email: <EmailIcon />,
  curriculumId: <SchoolIcon />,
  repertoire: <CommentIcon />,
  startOn: <TodayIcon />,
  endOn: <TodayIcon />,
  hours: <TimerIcon />,
  municipalityId: <LocationOnIcon />,
  teacher: <PersonIcon />,
  remarks: <ChatIcon />,
  facilitationExpected: <AccessibilityIcon />,
  name: <PersonIcon />,
  address: <HomeIcon />,
  zip: <LocationCityIcon />,
  gender: <WcIcon />,
  birthYear: <ChildCareIcon />,
  startedAt: <TodayIcon />,
  incomes: <AttachMoneyIcon />,
  expenses: <AttachMoneyIcon />,
  comment: <ChatIcon />,
  hoursVoluntaryWork: <HourglassFullIcon />,
  facilitationMales: <AccessibilityNewIcon />,
  facilitationFemales: <AccessibilityNewIcon />,
  facilitationExpenses: <AttachMoneyIcon />,
  hoursExplanation: <ChatIcon />,
  hoursWithoutTeacher: <AvTimerIcon />,
  participantsExplanation: <ChatIcon />
};

export const getValidator = (field, { required }) => (value, values) => {
  if (required && !value) {
    return I18n.get("is required");
  }
  if (
    field === "bankAccount" &&
    value &&
    !checkdigit.mod11.isValid(value.replace(/\D/g, "").padEnd(11))
  ) {
    return I18n.get("is not a valid bank account");
  }
  if (field === "zip" && !value) {
    return I18n.get("is not valid");
  }
  if (
    field === "email" &&
    value &&
    !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      value
    )
  ) {
    return I18n.get("is not valid");
  }
  if (
    field === "birthYear" &&
    value &&
    !(
      parseInt(value, 10) <= new Date().getFullYear() &&
      parseInt(value, 10) >= new Date().getFullYear() - 150
    )
  ) {
    return I18n.get("is not valid");
  }

  if (field === "startOn" && value) {
    if (!value.isSameOrAfter(moment().subtract(3, "years"))) {
      return I18n.get("is not valid");
    }
    if (!value.isSameOrBefore(moment().add(2, "years"))) {
      return I18n.get("is not valid");
    }
  }

  if (field === "endOn" && value) {
    const { startOn } = values;

    if (!value.isSameOrAfter(moment(startOn))) {
      return I18n.get("is not valid");
    }
    if (!value.isSameOrBefore(moment(startOn).add(2, "years"))) {
      return I18n.get("is not valid");
    }
    if (
      !value.isSameOrAfter(
        moment().month() < 3
          ? moment()
              .subtract(1, "years")
              .startOf("year")
          : moment().startOf("year")
      )
    ) {
      return I18n.get("is not valid");
    }
  }

  if (field === "confirmNewPassword" && value !== values.newPassword) {
    return I18n.get("Passwords must be alike");
  }
  if (field === "newPassword") {
    if (!value) {
      return I18n.get("is required");
    }
    if (value.length < 8) {
      return I18n.get("Password must be eight characters or longer");
    }
    if (!/[A-Z]/.test(value)) {
      return I18n.get("Password must have at least one capital letter");
    }
    if (!/[a-z]/.test(value)) {
      return I18n.get("Password must have at least one small character");
    }
    if (!/[0-9]/.test(value)) {
      return I18n.get("Password must have at least one number");
    }
  }

  return undefined;
};

const getFieldProps = (field, { min, max, helperTextKey, ...props }) => {
  const numberProps = {
    type: "number",
    InputProps: {
      inputProps: { min: "0" }
    }
  };

  const multiLine = { multiline: true };

  const inputProps = {
    startedAt: {
      type: "datetime-local",
      InputLabelProps: { shrink: true }
    },
    startOn: {
      type: "date",
      InputLabelProps: { shrink: true },
      InputProps: { inputProps: { min, max } }
    },
    endOn: {
      type: "date",
      InputLabelProps: { shrink: true },
      InputProps: { inputProps: { min, max } }
    },
    hours: numberProps,
    repertoire: multiLine,
    facilitationExpected: numberProps,
    incomes: numberProps,
    expenses: numberProps,
    facilitationExpenses: {
      validateOnBlur: false,
      ...numberProps
    },
    facilitationMales: numberProps,
    facilitationFemales: numberProps,
    hoursVoluntaryWork: numberProps,
    email: { type: "email" },
    phone: { type: "tel" },
    remarks: multiLine,
    comment: multiLine,
    hoursWithoutTeacher: numberProps,
    hoursExplanation: {
      ...multiLine,
      validateOnBlur: false
    },
    participantsExplanation: {
      ...multiLine,
      validateOnBlur: false
    },
    birthYear: {
      InputProps: { inputProps: { pattern: "[0-9]{4}", maxLength: "4" } }
    }
  };

  const fieldProps = {
    field,
    label: I18n.get(`field.${field}`),
    helperText: I18n.get(helperTextKey || `helpertext.${field}`, null),
    validateOnChange: false,
    validateOnBlur: true,
    ...inputProps[field],
    required: requiredFields.indexOf(field) !== -1,
    ...props
  };

  return {
    ...fieldProps,
    validate: getValidator(field, fieldProps)
  };
};

const styles = {
  field: {
    flex: "1 1 auto",
    padding: "0 2px",
    maxWidth: 500
  },
  icon: {
    paddingTop: "16px"
  }
};

const FormField = ({ classes, field, children, ...props }) => {
  const Field = types[field] || TextField;
  return (
    <ListItem alignItems="flex-start">
      {icons[field] && (
        <ListItemIcon className={classes.icon}>{icons[field]}</ListItemIcon>
      )}
      <div className={classes.field}>
        <Field icon={icons[field]} {...getFieldProps(field, props)} />
      </div>
      {children}
    </ListItem>
  );
};

FormField.defaultProps = {
  children: null
};

FormField.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.element,
  field: PropTypes.string.isRequired
};

export default withStyles(styles)(FormField);
