import React from "react";

import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { asField } from "informed";

const styles = {
  fieldPad: { marginTop: 12, marginLeft: 12 }
};

const TextField = asField(({ fieldState, formState, fieldApi, ...props }) => {
  const { value, error } = fieldState;
  const { setValue, setTouched } = fieldApi;

  const {
    onChange,
    onBlur,
    initialValue,
    forwardedRef,
    helperText,
    label,
    classes,
    ...rest
  } = props;
  return (
    <FormControl {...rest} ref={forwardedRef} fullWidth error={!!error}>
      <InputLabel shrink>{label}</InputLabel>
      <RadioGroup
        className={classes.fieldPad}
        row
        value={!value && value !== 0 ? "" : value}
        onChange={e => {
          setValue(e.target.value);
          onChange && onChange(e);
        }}
        onBlur={e => {
          setTouched();
          onBlur && onBlur(e);
        }}
      >
        <FormControlLabel
          value="female"
          label={I18n.get("Female")}
          control={<Radio />}
        />
        <FormControlLabel
          value="male"
          label={I18n.get("Male")}
          control={<Radio />}
        />
      </RadioGroup>

      {(error || helperText) && (
        <FormHelperText>{error || helperText}</FormHelperText>
      )}
    </FormControl>
  );
});

export default withStyles(styles)(TextField);
