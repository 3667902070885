import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { asField, useFieldState } from "informed";

import { I18n } from "@aws-amplify/core";

import MuiTextField from "@material-ui/core/TextField";

const HourField = asField(({ fieldState, fieldApi, field, ...props }) => {
  const { value, error } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const {
    onChange,
    onBlur,
    initialValue,
    forwardedRef,
    helperText,
    type,
    variant,
    allowHoursWithoutTeacher,
    ...rest
  } = props;

  const hoursWithoutTeacherFieldState = useFieldState("hoursWithoutTeacher");
  const { value: withoutValue } = hoursWithoutTeacherFieldState;

  const [hoursValue, setHoursValue] = useState(undefined);
  // if withoutValue updates, setValue should equal hoursWithoutTeacher + hoursValue

  useEffect(() => {
    if (withoutValue !== undefined) {
      setValue(Number(hoursValue) + Number(withoutValue));
    }
  }, [hoursValue, setValue, withoutValue]);

  // Set hoursValue initialValues || null onMount();
  useEffect(() => {
    if (hoursValue === undefined && value) {
      setHoursValue(
        withoutValue ? Number(value) - Number(withoutValue) : Number(value)
      );
    }
  }, [hoursValue, value, withoutValue]);

  return (
    <MuiTextField
      {...rest}
      ref={forwardedRef}
      fullWidth
      variant={variant && variant.length > 0 ? variant : "outlined"}
      error={!!error}
      label={I18n.get(
        allowHoursWithoutTeacher ? "field.hoursWithTeacher" : "field.hours"
      )}
      helperText={
        error ||
        I18n.get(
          allowHoursWithoutTeacher
            ? "helpertext.hoursWithTeacher"
            : "helpertext.hours",
          null
        )
      }
      value={!hoursValue && hoursValue !== 0 ? "" : hoursValue}
      type={type}
      onChange={e => {
        const targetValue = Number(e.target.value);
        setValue(Number(targetValue) + Number(withoutValue || 0));
        setHoursValue(Number(targetValue));
        onChange && onChange(e);
      }}
      onBlur={e => {
        setTouched();
        onBlur && onBlur(e);
      }}
    />
  );
});

HourField.defaultProps = {
  allowHoursWithoutTeacher: false
};

HourField.propTypes = {
  allowHoursWithoutTeacher: PropTypes.bool
};

export default HourField;
