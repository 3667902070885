import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { Course, Municipality, Curriculum } from "../../types";

import AppBar from "../AppBar";
import CollectionList from "./Collection/List";
import CollectionAttendDialog from "./Collection/AttendDialog";
import CourseDetails from "./Details";
import CourseActions from "./CourseActions";
import CourseNameChangeDialog from "./CourseNameChangeDialog";
import ReportButton from "./ReportButton";
import OwnersDialog from "./OwnersDialog";
import ShowFab from "./ShowFab";
import EditParticipantDialog from "./Collection/EditParticipantDialog";
import EditSessionDialog from "./Collection/EditSessionDialog";

const styles = theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  },
  fabTop: {
    position: "fixed",
    top: theme.spacing.unit * 10,
    right: theme.spacing.unit * 2,
    zIndex: theme.zIndex.appBar + 1
  },
  whiteList: {
    backgroundColor: theme.palette.background.paper,
    padding: 0
  },
  appBarInput: {
    color: "inherit",
    fontSize: "inherit",
    width: "100%"
  },
  root: {
    padding: theme.spacing.unit * 2
  }
});

const collectionUtils = [
  { name: "participants", sortKey: "name" },
  { name: "sessions", sortKey: "startedAt" }
];

const ShowItem = ({
  course,
  municipality,
  curriculum,
  updateCollection,
  sendReport,
  updateCourseName,
  cancelCourse,
  removeFromCollection,

  collection,
  editable,
  allowHoursWithoutTeacher,
  classes
}) => {
  const [tab, setTabIndex] = useState(0);
  const setTab = index => setTabIndex(index);

  const [collectionSet, setCollections] = useState({
    collectionType: undefined,
    collectionItem: undefined
  });

  const setCollection = (
    collectionType = undefined,
    collectionItem = undefined
  ) => setCollections({ collectionType, collectionItem });

  const unsetCollection = () => setCollection();

  const { collectionItem, collectionType } = collectionSet;

  const [ownersOpen, setOwnersOpen] = useState(false);
  const toggleOwners = () => setOwnersOpen(!ownersOpen);

  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = () => setReportOpen(!reportOpen);

  const [nameChangeOpen, setNameChangeOpen] = useState(false);
  const toggleNameChange = () => setNameChangeOpen(!nameChangeOpen);

  const editableDialog = () => {
    if (!editable) {
      return null;
    }
    switch (collectionType) {
      case "participants":
        return (
          <EditParticipantDialog
            item={collectionItem}
            handleSubmit={updateCollection}
            handleClose={unsetCollection}
          />
        );
      case "sessions":
        return (
          <EditSessionDialog
            item={collectionItem}
            handleSubmit={updateCollection}
            handleClose={unsetCollection}
            participants={collection.participants}
            allowHoursWithoutTeacher={allowHoursWithoutTeacher}
          />
        );
      case "attendance":
        return (
          <CollectionAttendDialog
            item={collectionItem}
            participants={collection.participants}
            handleSubmit={updateCollection}
            handleClose={unsetCollection}
          />
        );
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <AppBar
        navBack="/kurs"
        title={course.get("name")}
        actions={
          course.id && (
            <React.Fragment>
              <ReportButton
                course={course}
                curriculum={curriculum}
                sendReport={sendReport}
                collection={collection}
                editable={editable}
                open={reportOpen}
                toggleReport={toggleReport}
              />
              <OwnersDialog
                open={ownersOpen}
                handleClose={toggleOwners}
                courseId={course.id}
              />
              <CourseNameChangeDialog
                item={course}
                open={nameChangeOpen}
                handleClose={toggleNameChange}
                handleSubmit={updateCourseName}
              />
              <CourseActions
                editable={editable}
                cancelCourse={cancelCourse}
                toggleOwners={toggleOwners}
                toggleReport={toggleReport}
                toggleNameChange={toggleNameChange}
              />
            </React.Fragment>
          )
        }
      >
        <Tabs
          value={tab}
          onChange={(_, index) => setTab(index)}
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={I18n.get("Application")} />
          <Tab label={I18n.get("Participants")} />
          <Tab label={I18n.get("Sessions")} />
        </Tabs>
      </AppBar>
      <SwipeableViews index={tab} onChangeIndex={setTab}>
        <div className={classes.whiteList}>
          {tab === 0 && (
            <CourseDetails
              course={course}
              curriculum={curriculum}
              municipality={municipality}
            />
          )}
        </div>
        {collectionUtils.map(util => (
          <CollectionList
            key={util.name}
            collection={util.name}
            editable={editable}
            items={collection[util.name].sortBy(item => item.get(util.sortKey))}
            sessions={collection.sessions}
            setCollection={setCollection}
            removeFromCollection={removeFromCollection}
          />
        ))}
      </SwipeableViews>
      {editable ? (
        <React.Fragment>
          <ShowFab index={1} tab={tab} setCollection={setCollection} />
          <ShowFab index={2} tab={tab} setCollection={setCollection} />
        </React.Fragment>
      ) : null}
      {editableDialog()}
    </React.Fragment>
  );
};

ShowItem.defaultProps = {
  course: undefined,
  municipality: undefined,
  curriculum: undefined,
  allowHoursWithoutTeacher: false,
  collection: undefined
};

ShowItem.propTypes = {
  course: PropTypes.instanceOf(Course),
  municipality: PropTypes.instanceOf(Municipality),
  curriculum: PropTypes.instanceOf(Curriculum),
  classes: PropTypes.shape({}).isRequired,
  removeFromCollection: PropTypes.func.isRequired,
  cancelCourse: PropTypes.func.isRequired,
  updateCourseName: PropTypes.func.isRequired,
  updateCollection: PropTypes.func.isRequired,
  sendReport: PropTypes.func.isRequired,
  collection: PropTypes.shape({}),
  allowHoursWithoutTeacher: PropTypes.bool,
  editable: PropTypes.bool.isRequired
};

export default withStyles(styles)(ShowItem);
