export default `query {
  municipalities {
    name
    number
    county { name }
  }
}`;



export const GetMunicipalities = `query ($name: String!){
    municipalities(name: $name){
        name
        number
        county{name}
    }
}`;
