import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { I18n } from "@aws-amplify/core";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import HomeIcon from "@material-ui/icons/Home";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import ShowItem from "../Utils/ShowItem";
import { selectors as organizers } from "../../reducers/organizers";
import { selectors as organizations } from "../../reducers/organizations";
import { Organizer, Organization } from "../../types";

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const organizer = Organizer(organizers.find(state, id));

  return {
    organizer,
    organization: Organization(
      organizations.find(state, organizer.get("organization"))
    )
  };
};

const OrganizerItem = ({ organizer, organization }) => (
  <List
    disablePadding
    subheader={<ListSubheader>{I18n.get("Organizer")}</ListSubheader>}
  >
    <ListItem>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText
        primary={organizer.get("name", "")}
        secondary={organization.get("name", "")}
      />
    </ListItem>

    <ShowItem
      Icon={CreditCardIcon}
      label="Bank account"
      value={String(organizer.get("bankAccount")).replace(
        /(\d{4})(\d{2})(.*)/,
        "$1 $2 $3"
      )}
    />
  </List>
);

OrganizerItem.propTypes = {
  organization: PropTypes.instanceOf(Organization).isRequired,
  organizer: PropTypes.instanceOf(Organizer).isRequired
};

export default connect(mapStateToProps)(OrganizerItem);
