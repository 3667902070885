import React from "react";
import PropTypes from "prop-types";
import { Map as iMap } from 'immutable';

import { I18n } from "@aws-amplify/core";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

import AppBar from "../AppBar";
import ProfileFieldsList from "./ProfileFieldsList";
import OrganizersList from "./OrganizersList";
import EmailVerificationBar from '../Auth/EmailVerificationBar';
import ChangePassword from './ChangePassword';
import BlockUi from "../Utils/BlockUi";

const styles = theme => ({
  content: {
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 1
  },
  cardSpace: {
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2
  },
  passwordField: {
    width: '60%',
    marginLeft: 0
  },
  eye: {
    cursor: 'pointer'
  }
});

const Profile = ({
  classes,
  user,
  emailVerified,
  refreshUser,
  updateUser,
  changePassword
}) => (
    <BlockUi blocking={false}>
      <AppBar navBack="/kurs" title={I18n.get("My profile")} />
      <div className={classes.content}>
        <EmailVerificationBar />
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Card className={classes.cardSpace}>
              <CardContent component="form" noValidate>
                <Typography variant="h5" component="h2">
                  {I18n.get("User information")}
                </Typography>
              </CardContent>
              <ProfileFieldsList
                user={user}
                emailVerified={emailVerified}
                refreshUser={refreshUser}
                updateUser={updateUser}
              />
              <CardContent>
                <Typography color="textSecondary">
                  {I18n.get(
                    "We will use this information to verify your electronic signature, and provide you with important information regarding your user account."
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ChangePassword
              emailVerified={emailVerified}
              changePassword={changePassword}
              classes={classes}
            />
          </Grid>
        </Grid>
        <Card className={classes.cardSpace}>
          <CardContent>
            <Typography variant="h5" component="h2">
              {I18n.get("My organizers")}
            </Typography>
          </CardContent>
          <OrganizersList editor />
        </Card>
      </div>
    </BlockUi>
  );

Profile.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  user: PropTypes.instanceOf(iMap).isRequired,
  emailVerified: PropTypes.bool.isRequired,
  refreshUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired
};

export default withStyles(styles)(Profile);
