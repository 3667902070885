import React from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import EditIcon from "@material-ui/icons/Edit";

import Form from "../Form/Form";
import FormField from "../Form/FormField";
import { cleanAttrValue } from "./valueHandlers";

const ListItemValue = ({ label, value }) => (
  <ListItemText
    disableTypography
    primary={
      <Typography component="h3" color="textSecondary">
        {I18n.get(label)}
      </Typography>
    }
    secondary={
      <Typography style={{ whiteSpace: 'pre-line' }} variant="subtitle1" component="span">
        {value}
      </Typography>
    }
  />
);

ListItemValue.defaultProps = {
  value: ""
};

ListItemValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string
};

const ShowItem = ({
  Icon,
  label,
  value,
  children,
  onEdit,
  name,
  editing,
  redirect,
  ...inputProps
}) => (

    < ListItem >
      {
        Icon ? (
          <ListItemIcon>
            <Icon />
          </ListItemIcon >
        ) : null}
      {
        editing ? (
          <Dialog
            open
            fullWidth
            maxWidth="xs"
            onClose={onEdit(false)}
            aria-labelledby="inline-edit-dialog-title"
          >
            <Form
              initialValues={{ [name]: cleanAttrValue(name, value) }}
              onSubmit={e => {
                onEdit(e);
              }}
            >
              <DialogTitle id="inline-edit-dialog-title">
                {I18n.get("Edit")} {I18n.get(label).toLowerCase()}
              </DialogTitle>
              <DialogContent>
                <FormField
                  field={name}
                  label={I18n.get(label)}
                  autoFocus
                  fullWidth
                  margin="none"
                  {...inputProps}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onEdit(false)} color="primary">
                  {I18n.get("Cancel")}
                </Button>
                <Button color="primary" type="submit">
                  {I18n.get("Save")}
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        ) : null
      }
      <ListItemValue label={label} value={value} />
      {children}
      {onEdit ? (
        <ListItemSecondaryAction>
          <IconButton aria-label={I18n.get("Edit")} onClick={onEdit}>
            <EditIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      ) : null
      }
    </ListItem>
  );

ShowItem.defaultProps = {
  Icon: undefined,
  children: undefined,
  onEdit: undefined,
  name: undefined,
  editing: false,
  value: ""
};

ShowItem.propTypes = {
  Icon: PropTypes.func,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.node,
  onEdit: PropTypes.func,
  name: PropTypes.string,
  editing: PropTypes.bool
};

export default ShowItem;
