import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router/immutable";
import app from "./app";
import courses from "./courses";
import courseForm from "./courseForm";
import curriculums from "./curriculums";
import municipalities from "./municipalities";
import organizations from "./organizations";
import organizers from "./organizers";
import learningAssociations from "./learningAssociations";
import sessions from "./sessions";
import participants from "./participants";
import { AppTypes } from "../types";

const appReducer = history =>
  combineReducers({
    app,
    courses,
    courseForm,
    curriculums,
    municipalities,
    organizers,
    organizations,
    learningAssociations,
    sessions,
    participants,
    router: connectRouter(history)
  });

const rootReducer = history => (state, action) =>
  appReducer(history)(
    action.type === AppTypes.SIGNED_OUT ? undefined : state,
    action
  );

export default rootReducer;
