import React from 'react'
import PropTypes from 'prop-types'

import { I18n } from '@aws-amplify/core'

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const styles = theme => ({
    'delete': {
      color: theme.palette.getContrastText(theme.palette.error[500]),
      backgroundColor: theme.palette.error[500],
      '&:hover': {
        backgroundColor: theme.palette.error[700],
      },
    },
    'callOff': {
        color: theme.palette.getContrastText(theme.palette.error[500]),
        backgroundColor: theme.palette.error[500],
        '&:hover': {
          backgroundColor: theme.palette.error[700],
        },
      },
  })

const Modal = ({ mood, item, payload, dismiss, commit, classes }) => (
    <Dialog open={Boolean(payload)} onClose={dismiss} aria-labelledby="my-dialog-text">
        <DialogContent>
            <DialogContentText id="my-dialog-text">{I18n.get(`confirm ${mood}`)} <strong>{item}</strong>?</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={dismiss} color="primary">{I18n.get('cancel')}</Button>
            <Button onClick={() => commit(payload)} className={classes[mood]} color="primary" autoFocus>{I18n.get(mood)}</Button>
        </DialogActions>
    </Dialog>
)

Modal.propTypes = {
    mood: PropTypes.string,
    item: PropTypes.string,
    dismiss: PropTypes.func.isRequired,
    commit: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Modal)