import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Map as iMap, Record } from "immutable";

import { I18n } from "@aws-amplify/core";
import { withStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import TodayIcon from "@material-ui/icons/Today";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import MessageIcon from "@material-ui/icons/Message";

import { attendingHours } from "../../Report/courseReport";
import { Session, Participant } from "../../../types";

const mobileFormat = /^\s*(\+47|00\s*47)?\s*[49](\s*\d){7}\s*$/g;

const styles = theme => ({
  success: {
    color: theme.palette.success.dark
  },
  pending: {
    color: theme.palette.pending.dark
  }
});

const collectionIcon = (attending = false) => ({
  participants: attending ? <HowToRegIcon /> : <PersonIcon />,
  sessions: <TodayIcon />
});

const CollectionListIcon = ({ classes, collection, item, sessions, hours }) => {
  if (collection === "participants" && sessions.size > 0) {
    const attending = attendingHours(sessions, item.get("id"), hours * 0.75);
    const { success, pending } = classes;
    return (
      <ListItemIcon className={attending ? success : pending}>
        {collectionIcon(attending)[collection]}
      </ListItemIcon>
    );
  }
  return <ListItemIcon>{collectionIcon()[collection]}</ListItemIcon>;
};

CollectionListIcon.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  collection: PropTypes.string.isRequired,
  item: PropTypes.instanceOf(Record).isRequired,
  sessions: PropTypes.instanceOf(iMap).isRequired,
  hours: PropTypes.number.isRequired
};

const primaryText = (collection, item) => {
  switch (collection) {
    case "participants":
      return item.get("name");
    case "sessions":
      return moment.parseZone(item.get("startedAt")).format("LLLL");
    default:
      return "???";
  }
};

const AttendantsIndicator = ({ count, isFuture }) =>
  isFuture ? null : (
    <span style={{ color: count > 0 ? "green" : "#e67300" }}>
      <HowToRegIcon
        fontSize="inherit"
        style={{
          verticalAlign: "text-bottom",
          fontSize: "1.25em",
          marginLeft: "0.5em"
        }}
      />{" "}
      {count} {I18n.get("attendants")}
    </span>
  );

AttendantsIndicator.propTypes = {
  count: PropTypes.number.isRequired,
  isFuture: PropTypes.bool.isRequired
};

const secondaryText = (collection, item, isFuture) => {
  switch (collection) {
    case "participants":
      return `${item.get("address")}, ${item.get("zip")} ${item.get("postal")}`;
    case "sessions":
      return (
        <React.Fragment>
          {item.get("hours").toLocaleString()} {I18n.get("hours")}{" "}
          <AttendantsIndicator
            count={item.get("attendants").size}
            isFuture={isFuture}
          />
        </React.Fragment>
      );
    default:
      return "???";
  }
};

const ActionMenuItem = ({ handleClick, disabled, label, icon }) => (
  <MenuItem onClick={handleClick} disabled={disabled}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText inset>{I18n.get(label)}</ListItemText>
  </MenuItem>
);

ActionMenuItem.defaultProps = {
  disabled: false
};

ActionMenuItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired
};

const collectionActions = (handler, collection, item, editable, isFuture) => {
  if (collection === "participants") {
    const phone = item.get("phone");
    return [
      {
        handleClick: handler.handleClose,
        disabled: !phone,
        label: "Call participant",
        icon: <PhoneIcon />,
        key: "call"
      },
      {
        handleClick: handler.handleClose,
        disabled: !phone || !phone.match(mobileFormat),
        label: "Text participant",
        icon: <MessageIcon />,
        key: "text"
      },
      {
        handleClick: handler.handleClose,
        disabled: !item.get("email"),
        label: "Email participant",
        icon: <EmailIcon />,
        key: "mail"
      }
    ];
  }
  if (collection === "sessions") {
    return [
      {
        handleClick: () => {
          handler.handleClose();
          handler.handleEdit("attendance", item);
        },
        disabled: !editable || isFuture,
        label: "Register attendance",
        icon: <HowToRegIcon />,
        key: "att"
      }
    ];
  }
  if (collection === "shared") {
    return [
      {
        handleClick: () => {
          handler.handleClose();
          handler.handleEdit(handler.props.collection, item);
        },
        disabled: !editable,
        label: "edit",
        icon: <EditIcon />,
        key: "edit"
      },
      {
        handleClick: () => {
          handler.handleClose();
          handler.handleRemove(
            handler.props.collection,
            item.get("id"),
            primaryText(handler.props.collection, item)
          );
        },
        disabled: !editable,
        label: "delete",
        icon: <DeleteIcon />,
        key: "delete"
      }
    ];
  }
  return [];
};

class CollectionListItem extends React.Component {
  state = { anchorEl: null };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  render() {
    const { anchorEl } = this.state;
    const {
      collection,
      item,
      sessions,
      hours,
      editable,
      classes,
      handleEdit,
      handleRemove
    } = this.props;

    const isFuture =
      collection === "sessions" &&
      moment.parseZone(item.get("startedAt")).isAfter(moment(), "day");

    this.handleEdit = handleEdit;
    this.handleRemove = handleRemove;

    return (
      <React.Fragment>
        <ListItem divider selected={Boolean(anchorEl)}>
          <CollectionListIcon
            collection={collection}
            sessions={sessions}
            hours={hours}
            item={item}
            classes={classes}
          />
          <ListItemText
            primary={primaryText(collection, item)}
            secondary={secondaryText(collection, item, isFuture)}
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-haspopup="true"
              aria-label={I18n.get("actions")}
              onClick={this.handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              {collectionActions(
                this,
                collection,
                item,
                editable,
                isFuture
              ).map(itemProps => (
                <ActionMenuItem {...itemProps} />
              ))}
              {collectionActions(this, "shared", item, editable).map(
                itemProps => (
                  <ActionMenuItem {...itemProps} />
                )
              )}
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
      </React.Fragment>
    );
  }
}

CollectionListItem.propTypes = {
  collection: PropTypes.string.isRequired,
  item: PropTypes.oneOfType([
    PropTypes.instanceOf(Session),
    PropTypes.instanceOf(Participant)
  ]).isRequired,
  sessions: PropTypes.instanceOf(iMap).isRequired,
  hours: PropTypes.number.isRequired,
  classes: PropTypes.shape({}).isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired
};

export default withStyles(styles)(CollectionListItem);
