import React from "react";
import PropTypes from "prop-types";
import { Map as iMap } from "immutable";

import { I18n } from "@aws-amplify/core";
import List from "@material-ui/core/List";

import Dialog from "../../Dialog";
import FormField from "../../Form/FormField";
import AttendBody from "./AttendBody";
import { HoursField } from "../HoursField";
import { Session } from "../../../types";

const EditSessionDialog = ({
  item,
  handleClose,
  handleSubmit,
  participants,
  allowHoursWithoutTeacher
}) => {
  const submit = values =>
    handleSubmit("sessions", handleClose)({
      id: item.get("id"),
      ...values
    });

  return (
    <Dialog
      open={Boolean(item)}
      handleClose={handleClose}
      handleSubmit={submit}
      initialValues={item}
      title={I18n.get(`${item.get("id") ? "Update" : "Add"} sessions`)}
      action={I18n.get("Save")}
    >
      <List>
        <FormField field="startedAt" showTimeSelect />
        <HoursField
          twoColumns
          allowHoursWithoutTeacher={allowHoursWithoutTeacher}
        />
        <AttendBody participants={participants} />
      </List>
    </Dialog>
  );
};

EditSessionDialog.defaultProps = {
  item: new Session(),
  participants: iMap()
};

EditSessionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  participants: PropTypes.instanceOf(iMap),
  allowHoursWithoutTeacher: PropTypes.bool.isRequired,
  item: PropTypes.instanceOf(Session)
};

export default EditSessionDialog;
