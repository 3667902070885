import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { I18n } from '@aws-amplify/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/styles';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: red[600],
    },
    info: {
        backgroundColor: blue[600]
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: 11
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

const MySnackbarContent = ({
    className,
    classes,
    message,
    onClose,
    variant,
    ...other
}) => {

    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {I18n.get(message)}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);



const MySnackbar = ({
    open,
    autoHideDuration,
    anchorOrigin,
    variant,
    message,
    handleClose,
    ...other
}) => (
        <Snackbar
            anchorOrigin={anchorOrigin}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
        >
            <MySnackbarContentWrapper
                variant={variant}
                message={message}
                onClose={handleClose}
                {...other}
            />
        </Snackbar>

    )

MySnackbar.defaultProps = {
    message: "",
    autoHideDuration: 6000,
    anchorOrigin: { horizontal: 'right', vertical: 'top' }
}

MySnackbar.propTypes = {
    message: PropTypes.string,
    open: PropTypes.bool.isRequired,
    autoHideDuration: PropTypes.number,
    anchorOrigin: PropTypes.shape({}),
    variant: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
}


export default MySnackbar;