import React, { useState } from "react";
import PropTypes from "prop-types";
import { I18n } from "@aws-amplify/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import passwordAdornments from '../Utils/PasswordAdornments';
import Dialog from "./Dialog";

const SignIn = ({
  signIn,
  handleInput,
  handleState,
  email,
  password,
  ...props
}) => {

  const [passwordVisibility, setVisibility] = useState(false);

  return (
    <Dialog
      title="Sign in"
      onSubmit={signIn}
      secondaryButton={["Sign up", handleState("signUp")]}
      extraButton={["Forgot password?", handleState("forgotPassword")]}
      {...props}
    >
      <Grid item>
        <TextField
          label={I18n.get("Username (e-mail)")}
          autoComplete="email"
          name="email"
          type="email"
          value={email}
          onChange={handleInput}
          variant="outlined"
          fullWidth
          autoFocus
        />
      </Grid>
      <Grid item>
        <TextField
          label={I18n.get("Password")}
          type={passwordVisibility ? "text" : "password"}
          autoComplete="password"
          name="password"
          value={password}
          onChange={handleInput}
          variant="outlined"
          fullWidth
          {...passwordAdornments(passwordVisibility, setVisibility)}
        />
      </Grid>
    </Dialog>

  )
};

SignIn.defaultProps = {
  email: "",
  password: ""
};

SignIn.propTypes = {
  handleState: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  email: PropTypes.string,
  password: PropTypes.string
};

export default SignIn;
