import React from "react";
import { withFormState } from "informed";

import MonetizationOnIcon from "@material-ui/icons/MonetizationOnOutlined";

import FormField from "../Form/FormField";
import { twoCols } from "../Utils/GridHandlers";
import ShowItem from "../Utils/ShowItem";

const IncomeForm = withFormState(({ formState, ...props }) => {
  const {
    incomes,
    expenses,
    facilitationExpenses,
    facilitationToggled
  } = formState.values;

  const i = parseInt(incomes, 10) || 0;
  const c = parseInt(expenses, 10) || 0;
  const f = facilitationToggled ? parseInt(facilitationExpenses, 10) : 0;
  const sum = i - c - f;

  return (
    <React.Fragment>
      {twoCols(
        <FormField field="incomes" {...props} />,
        <FormField field="expenses" {...props} />
      )}
      {twoCols(
        f ? (
          <ShowItem
            Icon={MonetizationOnIcon}
            label="field.facilitationExpenses"
            value={`${(f * -1).toLocaleString()} kr`}
          />
        ) : null,
        <ShowItem
          Icon={MonetizationOnIcon}
          label="Total"
          value={`${sum.toLocaleString()} kr`}
        />
      )}
    </React.Fragment>
  );
});

export default IncomeForm;
