import { combineReducers } from "redux-immutable";
import { Map, List, fromJS } from "immutable";
import { AppTypes } from "../types";
import { sfFromId } from "../learningAssociations";

const authState = (state = "initial", action) => {
  switch (action.type) {
    case AppTypes.HANDLE_AUTH_STATE:
      return action.authState;
    case AppTypes.SIGNED_IN:
      return "signedIn";
    case AppTypes.SIGNED_OUT:
      return "signedOut";
    case AppTypes.CLEAN_AUTH_UI:
      return "cleaned";
    default:
      return state;
  }
};

const sent = (state = null, action) => {
  switch (action.type) {
    case AppTypes.REQUESTED_PASSWORD_REMINDER:
      return action.destination;
    case AppTypes.CLEAN_AUTH_UI:
      return null;
    default:
      return state;
  }
};

const drawer = (state = false, action) => {
  switch (action.type) {
    case AppTypes.HANDLE_DRAWER:
      return action.open;
    case "@@router/LOCATION_CHANGE":
      return false;
    default:
      return state;
  }
};

const loading = (state = true, action) => {
  switch (action.type) {
    case AppTypes.HANDLE_AUTH_STATE:
    case AppTypes.AUTH_FAILED:
    case AppTypes.SIGNED_IN:
    case AppTypes.SIGNED_OUT:
    case AppTypes.REQUESTED_PASSWORD_REMINDER:
    case AppTypes.PASSWORD_RESET_SUCCESS:
      return false;
    case AppTypes.AUTH_SUBMIT:
      return true;
    default:
      return state;
  }
};

const modal = (state = Map(), action) => {
  switch (action.type) {
    case AppTypes.MODAL_SHOW:
      return Map({
        mood: action.mood,
        payload: action.payload,
        item: action.item
      });
    case AppTypes.MODAL_HIDE:
      return state.delete("payload");
    default:
      return state;
  }
};


const snackbar = (state = Map(), action) => {
  switch (action.type) {
    case AppTypes.SNACKBAR_SHOW:
      return Map({
        variant: action.variant,
        payload: action.payload,
        message: action.message,
        open: true
      })
    case AppTypes.SNACKBAR_HIDE:
      return state.set("open", false);
    default:
      return state;
  }
}

const error = (state = null, action) => {
  switch (action.type) {
    case AppTypes.HANDLE_AUTH_STATE:
    case AppTypes.AUTH_SUBMIT:
    case AppTypes.SIGNED_IN:
    case AppTypes.CLEAN_AUTH_UI:
      return null;
    case AppTypes.AUTH_FAILED:
      return action.error;
    default:
      return state;
  }
};

const defaultUser = Map();
const user = (state = defaultUser, action) => {
  switch (action.type) {
    case AppTypes.SIGNED_IN:
      return fromJS(action.user);
    case AppTypes.USER_UPDATE_SUCCESS:
      return state.mergeIn(["attributes"], action.attributes);
    case AppTypes.SIGNED_OUT:
      return defaultUser;
    default:
      return state;
  }
};

const app = combineReducers({
  authState,
  drawer,
  modal,
  snackbar,
  loading,
  sent,
  error,
  user
});

export default app;

export const getSent = state => state.getIn(["app", "sent"]);
export const getAuthState = state => state.getIn(["app", "authState"]);
export const getLoading = state => state.getIn(["app", "loading"]);
export const getError = state => state.getIn(["app", "error"]);

export const getDrawer = state =>
  Boolean(state.getIn(["app", "drawer"], false));
export const getBrand = (state, index) =>
  ((sfFromId(currentOrganizerId(state)) || {}).brand || [undefined, undefined])[
  index
  ];

export const getOwnOrganizers = state =>
  List(
    state
      .getIn(["app", "user", "attributes", "custom:organizers"], "")
      .split(";")
      .filter(Boolean)
  );
export const currentOrganizerId = state =>
  state.getIn(["app", "user", "attributes", "custom:organizer"]);

export const currentSfId = state =>
  (sfFromId(currentOrganizerId(state)) || {}).id;

export const getUserAttribute = (state, attribute_name) =>
  state.getIn(["app", "user", "attributes", attribute_name])

export const getUserLocation = state =>
  state.getIn(['router', 'location', 'pathname']);
