import React from "react";
import Downshift from "downshift";
import matchSorter from "match-sorter";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

const appendIfCreate = (items, inputValue, createNew) =>
  inputValue && inputValue.trim() && createNew && items.length < 5
    ? [...items, createNew(inputValue.trim())]
    : items;

const AutocompleteInput = ({
  value,
  onChange,
  onSelectNew,
  onBlur,
  id,
  classes,
  itemToString,
  itemToSecondary,
  itemToValue,
  items = [],
  creator,
  ...props
}) => (
  <Downshift
    onChange={selected =>
      onChange({ target: { id, value: itemToValue(selected) } })
    }
    itemToString={itemToString}
    defaultHighlightedIndex={0}
    itemCount={6}
    onInputValueChange={(inputValue, ds) => {
      const exactMatch = matchSorter(items, inputValue, {
        keys: [itemToString],
        threshold: matchSorter.rankings.EQUAL
      })[0];
      if (exactMatch && ds.selectedItem !== exactMatch) {
        ds.selectItem(exactMatch);
      }
      onSelectNew && onSelectNew(inputValue);
    }}
  >
    {({
      getInputProps,
      getItemProps,
      isOpen,
      inputValue,
      highlightedIndex,
      selectedItem
    }) => (
      <div style={{ width: "100%" }}>
        <TextField
          fullWidth
          {...getInputProps({
            value: inputValue,
            autoComplete: "nope",
            onBlur: () =>
              onChange({
                target: { id, value: selectedItem && itemToValue(selectedItem) }
              }),
            id,
            ...props
          })}
        />
        {isOpen ? (
          <Paper square>
            <List>
              {appendIfCreate(
                matchSorter(items, inputValue, { keys: [itemToString] }).slice(
                  0,
                  6
                ),
                inputValue,
                creator
              ).map((item, index) => (
                <ListItem
                  button
                  {...getItemProps({ key: itemToValue(item), index, item })}
                  style={{
                    backgroundColor:
                      highlightedIndex === index ? "rgba(0,0,0,0.12)" : null
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        component="span"
                        style={{
                          fontWeight:
                            selectedItem &&
                            itemToString(selectedItem) === itemToString(item)
                              ? "bold"
                              : null
                        }}
                      >
                        {itemToString(item, true)}
                      </Typography>
                    }
                    secondary={
                      itemToSecondary ? (
                        <Typography color="textSecondary">
                          {itemToSecondary(item)}
                        </Typography>
                      ) : null
                    }
                    disableTypography
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        ) : null}
      </div>
    )}
  </Downshift>
);

export default AutocompleteInput;
