import React from "react";
import { Route, Redirect, Switch } from "react-router";
import moment from "moment";
import "moment/locale/nb";

import { I18n } from "@aws-amplify/core";

import Theme from "./Theme";
import CourseCreate from "./Course/Create";
import CourseList from "./Course/List";
import CourseShow from "./Course/Show";
import Profile from "./User/Profile";
import Authenticate from "./Auth/Authenticate";
import NavWrapper from "./NavWrapper";
import Modal from "./Modal";
import dict from "./AppI18n";

const App = () => (
  <Theme>
    <Authenticate>
      <NavWrapper>
        <Switch>
          <Redirect exact from="/" to="/kurs" />
          <Route path="/kurs/:id" component={CourseShow} />
          <Route path="/kurs" component={CourseList} />
          <Route
            path="/arkiv"
            render={props => <CourseList {...props} isArchive />}
          />
          <Route path="/soknad" component={CourseCreate} />
          <Route path="/profil" component={Profile} />
        </Switch>
      </NavWrapper>
      <Modal />
    </Authenticate>
  </Theme>
);

// Localization
I18n.putVocabularies(dict);
I18n.setLanguage("nb");
moment.locale('nb');

export default App;
