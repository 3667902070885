import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import passwordAdornments from '../Utils/PasswordAdornments';
import Dialog from "./Dialog";

const ForgotPassword = ({
  resetPassword,
  email,
  code,
  password,
  sent,
  handleInput,
  handleState,
  ...props
}) => {

  const [passwordVisibility, setVisibility] = useState(false);

  return (
    <Dialog
      onSubmit={resetPassword}
      title="Forgotten your password?"
      submitLabel={sent ? "Change password" : "Send e-mail"}
      secondaryButton={["Cancel", handleState("signIn")]}
      {...props}
    >
      {sent ? (
        <React.Fragment>
          <Grid item>
            <TextField
              autoFocus
              name="code"
              autoComplete="off"
              label="Bekreftelseskode"
              type="number"
              value={code}
              onChange={handleInput}
              variant="outlined"
              helperText={`Bekreftelseskoden er sendt til ${sent}.`}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              name="password"
              autoComplete="new-password"
              label="Nytt passord"
              type={passwordVisibility ? "text" : "password"}
              value={password}
              onChange={handleInput}
              variant="outlined"
              helperText="Minst 8 tegn. Må inneholde både store bokstaver, små bokstaver og tall."
              {...passwordAdornments(passwordVisibility, setVisibility)}
            />
          </Grid>
        </React.Fragment>
      ) : (
          <React.Fragment>
            <Grid item>
              <TextField
                autoFocus
                fullWidth
                name="email"
                autoComplete="email"
                label="E-post"
                type="email"
                value={email}
                onChange={handleInput}
                variant="outlined"
                helperText="Instruksjoner om tilbakestilling av passordet sendes til denne e-postadressen."
              />
            </Grid>
          </React.Fragment>
        )}
    </Dialog>

  )
}

ForgotPassword.defaultProps = {
  code: "",
  email: "",
  password: "",
  sent: undefined
};

ForgotPassword.propTypes = {
  handleState: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  sent: PropTypes.string,
  code: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string
};

export default ForgotPassword;
