import React from "react";
import PropTypes from "prop-types";

import { I18n } from "@aws-amplify/core";

import Button from "@material-ui/core/Button";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const FetchError = ({ message, onRetry }) => (
  <SnackbarContent
    message={<span id="message-id">{message}</span>}
    action={
      <Button key="retry" color="secondary" size="small" onClick={onRetry}>
        {I18n.get("Retry")}
      </Button>
    }
  />
);

FetchError.propTypes = {
  message: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired
};

export default FetchError;
