const learningAssociations = {
  2501: {
    id: "2501",
    name: "The Workers' Education Association of Norway",
    visible: true,
    brand: ["#2196f3", "#f50057"]
  },
  2503: {
    id: "2503",
    name: "The Adult Learning Association for Agriculture and Community",
    visible: true,
    brand: ["#86bb2a", "#333439"]
  },
  2504: {
    id: "2504",
    name:
      "Adult Education Association of Christian communities and organizations in Norway",
    visible: true,
    brand: ["#8cab3e", "#697778"]
  },
  2508: {
    id: "2508",
    name: "The Study Association Funkis",
    visible: true,
    brand: ["#1a7cbb", "#f2f851"]
  },
  2510: {
    id: "2510",
    name: "Association for studies of culture and traditions",
    visible: true,
    brand: ["#C53283", "#DFAC1F"]
  },
  2521: {
    id: "2521",
    name: "The Sports Education Foundation",
    visible: true,
    brand: ["#2196f3", "#f50057"]
  },
  2525: {
    id: "2525",
    name: "The Adult Education Association of Music",
    visible: true,
    brand: ["#2a2a2a", "#feb20f"]
  },
  2534: {
    id: "2534",
    name: "The Sami Adult Education Association",
    visible: true,
    brand: ["#2c0700", "#ee2e24"]
  },
  2535: {
    id: "2535",
    name: "The Adult Education Association of the Centre Party",
    visible: true,
    brand: ["#008542", "#ffb612"]
  },
  2536: {
    id: "2536",
    name: "The Adult Education Association of Solidarity",
    visible: true,
    brand: ["#230208", "#d2112c"]
  },
  2538: {
    id: "2538",
    name: "Folkeuniversitetet - An Adult Education Association",
    visible: true,
    brand: ["#2196f3", "#f50057"]
  },
  2539: {
    id: "2539",
    name: "The Adult Education Association of Nature and Environment",
    visible: true,
    brand: ["#00aa55", "#ff8f00"]
  },
  2542: {
    id: "2542",
    name: "The Adult Education Association of the Liberal Party",
    visible: true,
    brand: ["#033", "#f63"]
  },
  2545: {
    id: "2545",
    name: "The Academic Association for Adult Education",
    visible: true,
    brand: ["#2196f3", "#f50057"]
  }
};

export const sfFromId = (id = "") =>
  learningAssociations[2500 + Number(id.split(":", 2)[0])];

export default learningAssociations;
