import React from "react";
import PropTypes from "prop-types";
import { asField, useFormApi, useFieldApi } from "informed";

import { I18n } from "@aws-amplify/core";

import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";

import Subheader from "../Utils/Subheader";
import ListItemCheckbox from "../Utils/ListItemCheckbox";
import { Curriculum } from "../../types";

const FacilitationMeasures = asField(({ field, measures }) => {
  const formApi = useFormApi(field);
  const values = formApi.getValue(field) || [];

  const toggleArray = (array, value) => {
    const index = array.indexOf(value);
    return index === -1
      ? [...array, value]
      : [...array.slice(0, index), ...array.slice(index + 1)];
  };

  return (
    <React.Fragment>
      {measures.map(f => (
        <ListItemCheckbox
          checked={values.indexOf(f) !== -1}
          onChange={() => formApi.setValue(field, toggleArray(values, f))}
          label={I18n.get(`field.facilitation_${f}`)}
          helperText={I18n.get(`helpertext.facilitation_${f}`, null)}
          key={`facilitation_${f}`}
        />
      ))}
    </React.Fragment>
  );
});

const FacilitationCheckbox = asField(({ fieldApi, fieldState, ...props }) => {
  const { value } = fieldState;
  return (
    <ListItemCheckbox
      {...props}
      checked={Boolean(value)}
      onChange={() => fieldApi.setValue(!value)}
      label={I18n.get(
        "Kurset har deltakere med særlige behov for tilrettelagt opplæring"
      )}
      helperText={I18n.get(
        "Dersom kurset har ekstra utgifter knyttet til tilrettelegging av opplæringen for deltakere med behov for dette, kan arrangøren søke tilretteleggingstilskudd fra studieforbundet."
      )}
    />
  );
});

const FacilitationForm = ({
  children,
  disableSticky,
  curriculum,
  ...props
}) => {
  const fieldApi = useFieldApi("facilitationToggled");
  const open = fieldApi.getValue();

  if (!curriculum || !curriculum.get("availableFacilitationMeasures")) {
    return null;
  }
  return (
    <List>
      <FacilitationCheckbox {...props} field="facilitationToggled" />
      <Collapse in={open}>
        {open && (
          <List
            subheader={
              <Subheader
                disableSticky={disableSticky}
                title="Planned measures"
              />
            }
          >
            <FacilitationMeasures
              {...props}
              measures={curriculum.get("availableFacilitationMeasures")}
              field="facilitationMeasures"
            />
            {children}
          </List>
        )}
      </Collapse>
    </List>
  );
};

FacilitationForm.defaultProps = {
  children: null,
  curriculum: null,
  disableSticky: false
};

FacilitationForm.propTypes = {
  disableSticky: PropTypes.bool,
  curriculum: PropTypes.instanceOf(Curriculum),
  children: PropTypes.node
};

export default FacilitationForm;
