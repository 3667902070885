const participantBody = `id
name
address
zip
postal
phone
email
gender
birthYear
attending`;

const sessionBody = `id
startedAt
hoursWithoutTeacher
hours
attendants`;

// detailedCourseBody is a shared definition for course details
const detailedCourseBody = `
id
name
number
startedOn
endedOn
hoursWithoutTeacher
municipality {
  name
  number
  county { name }
}
organizer {
  id
  name
  bankAccount
  organization {
    id
    name
  }
}
curriculum {
  name
  hoursMin
  hoursMax
  incompleteFields
  availableFacilitationMeasures
  allowHoursWithoutTeacher
  id
}
status
isOwner
... on OwnCourse {
  person
  phone
  email
  editable
  teachers{name}
  granted
  facilitationGranted
  miscGranted
  incomes
  expenses
  facilitationMeasures
  curriculumCompletion {
    repertoire
  }
  owners {
    id
    display
  }
  facilitationExpenses
  participants {
    ${participantBody}
  }
  sessions {
    ${sessionBody}
  }
  audits {
    action
    auditableId
    auditableType
    version
    auditedChanges
    createdAt
    user
    comment
  }
} 
`;

const courseUpdateFields = {
  teacher: "String",
  comment: "String",
  name: "String",
  incomes: "Int",
  expenses: "Int",
  facilitationExpenses: "Int",
  facilitationMales: "Int",
  facilitationFemales: "Int",
  facilitationMeasures: "[FacilitationMeasure!]",
  hoursVoluntaryWork: "Int",
  curriculumCompletion: "IncompleteFieldsInput",
  hoursExplanation: "String",
  participantsExplanation: "String",
  hoursWithoutTeacher: "Int",
  action: "CourseAction",
  person: "String",
  phone: "String",
  email: "String",
  curriculumId: "ID",
  startedOn: "String",
  endedOn: "String",
  hours: "Int"
};

const courseFieldsResponse = {
  action: "status",
  name: "name"
};

export const UpdateCourse = fields => `mutation UpdateCourse(
  $id: ID!
  ${fields.map(
  field => `$${field}: ${courseUpdateFields[field] || "String"}\n  `
)}
) {
  updateCourse(
    courseId: $id,
    input: { ${fields.map(field => `${field}: $${field}, `)} }
  ){
    ${fields.map(field =>
  courseFieldsResponse[field] ? `${courseFieldsResponse[field]} ` : ""
)}
    editable
    curriculumCompletion {
      repertoire
    }
    }
}`;

export const CreateCourse = `mutation CreateCourse(
  $organizerId: ID!
  $person: String!
  $phone: String!
  $email: String!
  $curriculumId: ID!
  $curriculumCompletion: IncompleteFieldsInput
  $startOn: String!
  $endOn: String!
  $hours: Int!
  $hoursWithoutTeacher: Int
  $municipalityId: Int!
  $teacher: String
  $remarks: String
  $facilitationMeasures: [FacilitationMeasure!]
  $facilitationExpected: Int
) {
  createCourse(input: {
    organizerId: $organizerId,
    curriculumId: $curriculumId,
    curriculumCompletion: $curriculumCompletion,
    person: $person,
    phone: $phone,
    email: $email,
    startedOn: $startOn,
    endedOn: $endOn,
    hours: $hours,
    hoursWithoutTeacher: $hoursWithoutTeacher,
    municipalityId: $municipalityId,
    teacher: $teacher,
    facilitationMeasures: $facilitationMeasures,
    facilitationExpenses: $facilitationExpected,
    comment: $remarks,
  }) { ${detailedCourseBody} }
}`;

export const GetOrganizerOwnCourses = `query ($id: ID!) {
  organizers(id: $id) {
    courses {
      id
      name
      number
      startedOn
      hoursWithoutTeacher
      curriculum {
        allowHoursWithoutTeacher
        name
        hoursMin
        hoursMax
        availableFacilitationMeasures
        incompleteFields
        id
      }
      endedOn
      municipality {
        name
        number
        county {
          name
        }
      }
      status
      isOwner
    }
  }
}`;

export const GetCourse = `query getCourse($id: ID!) {
  course(id: $id) { ${detailedCourseBody} }
}`;

const updateCourseParticipant = `mutation UpdateCourseParticipant(
  $courseId: ID!
  $id: ID
  $name: String
  $address: String
  $zip: String
  $postal: String
  $phone: String
  $email: String
  $gender: GenderCategory
  $birthYear: String
) {
  updateCourseParticipant(
    courseId: $courseId,
    courseParticipantId: $id,
    input: {
      name: $name,
      address: $address,
      zip: $zip,
      postal: $postal,
      phone: $phone,
      email: $email,
      gender: $gender,
      birthYear: $birthYear,
    }
  ) { ${participantBody} }
}`;

const deleteCourseParticipant = `mutation DeleteCourseParticipant(
  $courseId: ID!
  $id: ID!
) { deleteCourseParticipant(courseId: $courseId, courseParticipantId: $id) }`;

const updateCourseAttendance = `mutation UpdateCourseSession(
  $courseId: ID!
  $id: ID
  $attendants: [ID!]
) {
  updateCourseSession(
    courseId: $courseId,
    courseSessionId: $id,
    input: { attendants: $attendants }
  ) { ${sessionBody} }
}`;

const updateCourseSession = `mutation UpdateCourseSession(
  $courseId: ID!
  $id: ID
  $startedAt: ISO8601DateTime
  $hoursWithoutTeacher: Float
  $attendants: [ID!]
  $hours: Float
) {
  updateCourseSession(
    courseId: $courseId,
    courseSessionId: $id,
    input: { startedAt: $startedAt, hours: $hours, hoursWithoutTeacher: $hoursWithoutTeacher, attendants: $attendants}
  ) { ${sessionBody} }
}`;

const deleteCourseSession = `mutation DeleteCourseSession(
  $courseId: ID!
  $id: ID!
) { deleteCourseSession(courseId: $courseId, courseSessionId: $id) }`;

export const GrantUserAccessToCourse = `mutation GrantUserAccessToCourse(
  $courseId: ID!
  $email: String!
){
  grantUserAccessToCourse(courseId: $courseId, input: {email: $email})
  {
    id
    display
  }
  }
`;

export const RevokeUserAccessToCourse = `mutation RevokeUserAccessToCourse(
  $courseId: ID!
  $ownerId: ID!
){ revokeUserAccessToCourse(courseId: $courseId, ownerId: $ownerId) }`;

export const Query = {
  participants: {
    update: updateCourseParticipant,
    delete: deleteCourseParticipant,
    namespace: "updateCourseParticipant",
    itemName: "participant"
  },
  sessions: {
    update: updateCourseSession,
    delete: deleteCourseSession,
    namespace: "updateCourseSession",
    itemName: "courseSession"
  },
  attendance: {
    update: updateCourseAttendance,
    namespace: "updateCourseSession",
    itemName: "courseSession",
    collection: "sessions"
  }
};
